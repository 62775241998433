import React from 'react';
import PropTypes from 'prop-types';
import {default as RCPagination} from 'rc-pagination';
import styled from 'styled-components';

import theme from '../../theme';

import {ArrowLeft} from '../Icons';

const PaginationStyled = styled(RCPagination)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 3rem;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;

    &:first-child a,
    &:last-child a,
    span {
      background-color: transparent;
      border: none;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }

  a,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.blueyGrey};
    width: 45px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid;
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${theme.colors.blueyGrey};
      color: #fff;
      outline: none;
    }
  }

  span {
    cursor: inherit;
  }

  .rc-pagination-item-active a {
    cursor: inherit;
    color: #000;
  }

  .rc-pagination-jump-next {
    background-color: transparent;
    border: none;
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    cursor: pointer;
    margin: 0 20px;
  }

  .rc-pagination-next {
    transform: rotate(180deg);
  }

  .rc-pagination-disabled {
    cursor: inherit;
    opacity: 0.2;
  }
`;

const DEFAULT_PAGE_SIZE = 10;

const JumpIcon = () => <span>...</span>;

const StyledLeft = styled(ArrowLeft)`
  display: block;
  pointer-events: none;
  width: 8px;
`;

const Arrow = () => <StyledLeft />;

const Pagination = ({current, total, onChange, pageSize}) => (
  <PaginationStyled
    showLessItems
    defaultPageSize={pageSize ? pageSize : DEFAULT_PAGE_SIZE}
    current={current}
    total={total}
    prevIcon={Arrow}
    nextIcon={Arrow}
    jumpPrevIcon={JumpIcon}
    jumpNextIcon={JumpIcon}
    onChange={onChange}
    hideOnSinglePage={true}
  />
);

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
