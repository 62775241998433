import React from 'react';
import styled from 'styled-components';
import {Box} from '../Box';
import {Button} from '../Button';
import {Close} from '../Icons';
import theme from '../../theme';

export const Modal = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 1.5rem 1rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  width: 50%;
  max-height: ${props =>
    props.top ? `calc(100vh - ${props.top})` : 'calc(100vh - 200px)'};
  position: fixed;
  overflow-x: ${props => (props.overflow ? props.overflow : 'hidden')};
  overflow-y: ${props => (props.overflow ? props.overflow : 'auto')};
  z-index: 2001;
  top: ${props => (props.top ? props.top : '200px')};
  left: 50%;
  transform: ${props =>
    props.top ? 'translate(-50%,-50%)' : 'translateX(-50%)'};

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    width: 80%;
    transform: translateX(-50%);
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  background: rgba(3, 23, 61, 0.3);
`;

export const TopBox = styled(Box)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    position: ${props => (props.position ? props.position : 'static')};
  }
`;

export const ModelWithOverlay = props => {
  const onOverlayClick = e => {
    const insideModal = e.target.closest('#modal');

    if (!insideModal) {
      props.onClose();
    }
  };

  return (
    <Overlay onClick={e => onOverlayClick(e)}>
      <Modal top="50px" id="modal" {...props}>
        {!props.hideClose && (
          <TopBox {...props} position="relative">
            <TopBox position="absolute" top="-.75rem" right="0">
              <Button onClick={props.onClose} width="24px" height="20px">
                <Close color={theme.colors.brand} />
              </Button>
            </TopBox>
          </TopBox>
        )}
        <Box {...props}></Box>
      </Modal>
    </Overlay>
  );
};
