import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {setting} from '../../graphql/queries';
import {SendTrainingForm as SendTrainingFormMutation} from '../../graphql/mutations';

import {
  Box,
  BgImage,
  Flex,
  Wrapper,
  Text,
  Button,
  FormInput,
  Loader,
  NotificationContainer,
  useNotification,
  theme,
  locale,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
  email: Yup.string().email().required(locale.required),
  phone: Yup.string().required(locale.required),
});

export const BrokersCampaign = () => {
  const [notifications, notify] = useNotification();

  const [sendTrainingForm] = useMutation(SendTrainingFormMutation, {
    onCompleted: data => {
      if (!data.sendTrainingForm.error) {
        notify('O seu pedido foi enviado', 'success');
      } else {
        notify('Ocorreu um erro', 'error');
      }
    },
    onError: () => notify('Ocorreu um erro', 'error'),
  });

  const {data, loading} = useQuery(setting, {
    fetchPolicy: 'cache-and-network',
    onError: () => {
      notify();
    },
  });

  const formik = useFormik({
    initialValues: {email: '', name: '', phone: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      sendTrainingForm({
        variables: {
          content: JSON.stringify(values),
        },
      });
    },
  });

  if (loading || !data) {
    return <Loader />;
  }

  const image = JSON.parse(
    data.setting.find(item => item.name === 'loginImage').value
  );

  return (
    <BgImage
      image={image.url}
      aspectRatio={false}
      width="100%"
      height="100%"
      absolute
    >
      <Wrapper>
        <NotificationContainer {...notifications} />
        <Flex align="flex-start" justify="space-between">
          <Box columns="4" margin="15% 0 0 0" rounded>
            <h1
              style={{
                fontfamily: 'Montserrat',
                fontWeight: 500,
                fontSize: '30px',
                color: theme.colors.yellow,
                textShadow: '0 2px 4px rgba(0,0,0,0.50)',
              }}
            >
              Não está registado e pretende assistir a uma das nossas formações?
              <br />
              <br />
              Por favor preencha o formulário ao lado e entraremos em contacto
              consigo.
            </h1>
            <Text color="#fff" padding="3rem 0 1rem 0" font="Lato" weight="500">
              Se a sua empresa não tem nomeação?
            </Text>
          </Box>
          <Box
            columns="4"
            margin="15% 0 0 0"
            backgroundColor="#fff"
            roundedTop
            padding="1.5rem 1rem"
          >
            <form onSubmit={formik.handleSubmit}>
              <FormInput
                type="text"
                name="name"
                label={locale.name}
                formik={formik}
                width="100%"
              />
              <FormInput
                type="email"
                name="email"
                label={locale.email}
                formik={formik}
                width="100%"
              />
              <FormInput
                type="text"
                name="phone"
                label={locale.phone}
                formik={formik}
                width="100%"
              />
              <Button type="submit" display="block" width="100%" filled>
                {locale.send}
              </Button>
            </form>
          </Box>
        </Flex>
      </Wrapper>
    </BgImage>
  );
};

export default BrokersCampaign;
