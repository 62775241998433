/* global gtag */

import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/react-hooks';

import {SignUpClient as SignUpClientMutation} from '../../graphql/mutations';

import {
  Box,
  Flex,
  WrapperSmall,
  Heading,
  Text,
  Button,
  theme,
  FormInput,
  NotificationContainer,
  useNotification,
} from '@innovago/ui';

const validationSchema = Yup.object().shape(
  {
    vat: Yup.number().required('Obrigatório'),
    email: Yup.string()
      .email()
      .when('phone', {
        is: phone => !phone || phone.length === 0,
        then: Yup.string().email().required('Obrigatório'),
        otherwise: Yup.string(),
      }),
    phone: Yup.string().when('email', {
      is: email => !email || email.length === 0,
      then: Yup.string().required('Obrigatório'),
      otherwise: Yup.string(),
    }),
  },
  [['phone', 'email']],
);

export const ClientRegister = () => {
  const [notifications, notify] = useNotification();

  const [signUpClient, {loading: loadingSignUp}] = useMutation(
    SignUpClientMutation,
    {
      onCompleted: data => {
        if (data.signUpClient.error) {
          notify('Ocorreu um erro', 'error');
        } else {
          if (data.signUpClient.via) {
            notify(
              `Registo efectuado com sucesso. Consulte o seu ${
                data.signUpClient.via === 'email' ? 'email' : 'telefone'
              }`,
              'success',
            );
          } else if (data.signUpClient.warning) {
            notify(
              'Por favor contacte o seu broker antes de terminar o registo',
              'warning',
            );
          } else {
            notify(
              'Já se encontra registado na plataforma. Faça login para aceder à área de clientes',
              'warning',
            );
          }
        }
      },
      onError: error => {
        if (error.message.indexOf('UQ_6436cc6b79593760b9ef921ef12') > -1) {
          notify('Já existe uma conta com esse email');
        } else if (
          error.message.indexOf('UQ_70a47b1f18ed4f61632abcc4ac4') > -1
        ) {
          notify('Já existe uma conta com esse nif');
        } else {
          notify('Ocorreu um erro', 'error');
        }
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      vat: '',
      phone: '',
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const {vat, email, phone} = values;
      let data = {};

      data.nif = vat.replace(/\s/g, '');

      if (email) {
        data.email = email.replace(/\s/g, '');
      }

      if (phone) {
        data.phone = phone.replace(/^[+|00]*351/, '').replace(/\s/g, '');
      }

      gtag('event', 'action', {
        event_category: 'click',
        event_label: 'Cliente fez registo',
      });

      signUpClient({variables: {data: data}});
    },
  });

  return (
    <WrapperSmall>
      <NotificationContainer {...notifications} />
      <Box padding="3rem 0 0">
        <Heading
          level={2}
          color={theme.colors.brand}
          weight={500}
          padding="0 0 1rem"
        >
          Registo
        </Heading>
        <Text padding="0 0 1rem">
          Por favor insira o seu NIF e o seu email ou número de telemóvel
        </Text>
        <form onSubmit={formik.handleSubmit}>
          <FormInput
            type="text"
            name="vat"
            label="NIF"
            placeholder="NIF"
            border={true}
            width="100%"
            formik={formik}
          />
          <Flex nowrap>
            <FormInput
              type="text"
              name="phone"
              label="Telemóvel"
              placeholder="+351 20 000 00 00"
              border={true}
              width="100%"
              formik={formik}
            />
            <Text padding="0 0.5rem">ou</Text>
            <FormInput
              type="email"
              name="email"
              label="Email"
              placeholder="Email"
              border={true}
              formik={formik}
              width="100%"
            />
          </Flex>
          <Flex justify="flex-end">
            <Button
              caps
              background={theme.colors.brand}
              width="33.33%"
              type="submit"
              disabled={loadingSignUp}
            >
              submeter
            </Button>
          </Flex>
        </form>
      </Box>
    </WrapperSmall>
  );
};
