import styled from 'styled-components';

import theme from '../../theme';

export const Box = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  border-radius: ${props =>
    props.roundedTop ? '4px 4px 0 0' : props.rounded ? '4px' : '0'};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: ${props => (props.margin ? props.margin : '0')};
  width: ${props =>
    props.columns
      ? 'calc(' + props.columns + ' / 12 * 100%)'
      : props.width
      ? props.width
      : 'auto'};
  height: ${props => (props.height ? props.height : 'auto')};
  min-height: ${props => (props.minHeight ? props.minHeight : 0)};
  box-shadow: ${props =>
    props.elevated
      ? '0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);'
      : 'none'};
  position: ${props => (props.position ? props.position : 'static')};
  top: ${props => (props.top ? props.top : 'initial')};
  left: ${props => (props.left ? props.left : 'initial')};
  right: ${props => (props.right ? props.right : 'initial')};
  bottom: ${props => (props.bottom ? props.bottom : 'initial')};
  z-index: ${props => (props.zIndex ? props.zIndex : 'initial')};
  border-right: ${props => (props.borderRight ? props.borderRight : 'none')};
  border-left: ${props => (props.borderLeft ? props.borderLeft : 'none')};
  border-bottom: ${props => (props.borderBottom ? props.borderBottom : 'none')};
  border-top: ${props => (props.borderTop ? props.borderTop : 'none')};
  display: ${props => (props.display ? props.display : 'block')};
  overflow: ${props => (props.overflow ? props.overflow : 'auto')};

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    width: ${props =>
      props.columns ? '40%' : props.width ? props.width : 'auto'};
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    width: ${props =>
      props.columns ? '100%' : props.width ? props.width : 'auto'};
  }
`;

export const Flex = styled(Box)`
  display: flex;
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  flex-wrap: ${props => (props.nowrap ? 'nowrap' : 'wrap')};
`;

export const Grid2 = styled(Box)`
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    ${props => !props.inline && 'grid-template-columns: 1fr;'}
    row-gap: 2rem;
  }
`;

export const Columns = styled(Box)`
  column-gap: 3rem;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  grid-template-rows: auto 1fr;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
  }

  hr {
    width: 1px;
    background-color: ${theme.colors.grey};
  }
`;

export const Grid3 = styled(Box)`
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
  }
`;

export const Wrapper = styled(Box)`
  max-width: 1192px;
  padding: 0 35px;
  margin: auto;
  width: 100%;
`;

export const WrapperSmall = styled(Box)`
  max-width: 580px;
  padding: 0 35px;
  margin: auto;
  width: 100%;
`;

export const BgGradient = styled(Box)`
  background: ${props => (props.gradient ? props.gradient : '#fff')};
  padding: ${props => (props.padding ? props.padding : '0')};
  width: '100%';
`;

export const BgImage = styled(Box)`
  background-size: ${props => (props.size ? props.size : 'cover')};
  background-repeat: no-repeat;
  background-position: ${props =>
    props.bgPosition ? props.bgPosition : 'center'};
  background-image: url(${props => (props.image ? props.image : '#fff')});
  background-color: ${props => (props.bgColor ? props.bgColor : 'transparent')};
  height: ${props => (props.aspectRatio ? '0' : props.height)};
  padding-bottom: ${props =>
    props.aspectRatio ? props.aspectRatio + '%' : '0'};
  position: ${props => (props.absolute ? 'absolute' : 'static')};
  top: 0;
  bottom: 0;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    background-size: ${props => (props.size ? props.size : 'cover')};
    display: ${props =>
      props.hideOnMobile ? 'none' : props.showOnMobile ? 'block' : 'none'};
  }
`;

export const StickyGrid = styled.div`
  align-items: center;
  background-color: ${props => (props.bgColor ? props.bgColor : '#fff')};
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  position: sticky;
  top: 0;
  z-index: 10;
`;
