import React, {useContext, useState, useEffect} from 'react';
import HeaderBroker from './HeaderBroker';
import HeaderClient from './HeaderClient';

import {AuthContext} from '../../contexts/AuthContext';

export const Header = () => {
  const [role, setRole] = useState(false);
  const {user} = useContext(AuthContext);
  const signInUserSession = user?.signInUserSession;

  useEffect(() => {
    if (user) {
      if (!user.signInUserSession?.accessToken?.payload['cognito:groups']) {
        setRole('broker');
      } else {
        if (
          user.signInUserSession?.accessToken?.payload['cognito:groups'][0] ===
          'clients'
        ) {
          setRole('client');
        } else {
          setRole('broker');
        }
      }
    }
  }, [user, signInUserSession]);

  if (!role) {
    return <></>;
  }

  if (role === 'broker') {
    return <HeaderBroker />;
  }

  if (role === 'client') {
    return <HeaderClient />;
  }
};
