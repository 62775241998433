import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {
  Box,
  Grid2,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Button,
  Pagination,
  FormInput,
  FormSelect,
  Search,
  theme,
  locale,
  Loader,
} from '@innovago/ui';

import {getCommunications} from '../../graphql/queries';
import {Others} from './others';
import {Campaigns} from './campaigns';

const validationSchema = Yup.object().shape({
  search: Yup.string(),
  type: Yup.string(),
});

export const Communications = ({contentType}) => {
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [notifications, notify] = useNotification();
  const [type, setType] = useState('campaign');
  const [search, setSearch] = useState(null);
  const [order, setOrder] = useState({created_at: 'desc'});

  const today = moment().add(1, 'days').format('YYYY-MM-DD');

  let where = {
    type: {_in: type},
    status: {_eq: 'published'},
    publish_at: {_lte: today},
  };

  if (search) {
    where.title = {_ilike: search};
  }

  const {data, loading} = useQuery(getCommunications, {
    variables: {
      limit: limit,
      offset: offset,
      where,
    },
    onError: () => notify(),
  });

  const formik = useFormik({
    initialValues: {
      search: '',
      type: '0',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      setType(values.type);
      values.search.trim().length === 0
        ? setSearch(null)
        : setSearch('%' + values.search + '%');
    },
  });

  useEffect(() => {
    if (contentType) {
      setType(contentType.type);
      formik.setFieldValue('type', contentType.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      <Wrapper>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          weight="500"
          caps
          padding="0 0 .25rem"
        >
          {locale.ourCommunications}
        </Heading>
        <Heading
          level={1}
          color={theme.colors.brand}
          size="2rem"
          font={theme.fonts.mont}
          padding="0 0 2rem"
        >
          {locale[formik.values.type].plural}
        </Heading>
        <Box
          backgroundColor="rgba(134,177,184,.1)"
          margin="0 0 1rem"
          padding="1.125rem 1rem"
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid2>
              <Box position="relative">
                <FormInput
                  type="text"
                  name="search"
                  label={locale.search}
                  placeholder="Ref./Client/Produto"
                  border={true}
                  formik={formik}
                />
                <Box position="absolute" bottom="28px" right="10px">
                  <Button type="submit" color={theme.colors.darkBlue}>
                    <Search />
                  </Button>
                </Box>
              </Box>
              <Box>
                <FormSelect
                  formik={formik}
                  label={locale.documentType}
                  name="type"
                  options={[
                    {label: 'Campanha', value: 'campaign'},
                    {label: 'Newsletter', value: 'newsletter'},
                    {label: 'Flash', value: 'information'},
                  ]}
                  allowEmpty={false}
                  onChange={e => {
                    formik.setFieldValue('type', e.target.value);
                    formik.handleSubmit();
                  }}
                />
              </Box>
            </Grid2>
          </form>
        </Box>
        {type !== 'campaign' && (
          <Others
            data={data}
            loading={loading}
            order={order}
            setOrder={setOrder}
          />
        )}
        {type === 'campaign' && <Campaigns data={data} loading={loading} />}

        <Box margin="0 0 2rem 0"></Box>

        {data && data.content_aggregate.aggregate && (
          <Pagination
            current={offset / limit + 1}
            total={data.content_aggregate.aggregate.count}
            onChange={page => setOffset(limit * (page - 1))}
          />
        )}
      </Wrapper>
    </Box>
  );
};
