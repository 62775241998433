import React, {useState, useEffect, useContext} from 'react';

import {AuthContext} from '../../contexts/AuthContext';
import ProductBroker from './ProductBroker';
import ProductClient from './ProductClient';

export const Product = props => {
  const [role, setRole] = useState(false);
  const {user} = useContext(AuthContext);
  const signInUserSession = user?.signInUserSession;

  useEffect(() => {
    if (user) {
      if (!user.signInUserSession?.accessToken?.payload['cognito:groups']) {
        setRole('broker');
      } else {
        if (
          user.signInUserSession?.accessToken?.payload['cognito:groups'][0] ===
          'clients'
        ) {
          setRole('client');
        } else {
          setRole('broker');
        }
      }
    }
  }, [user, signInUserSession]);

  if (!role) {
    return <></>;
  }

  if (role === 'broker') {
    return <ProductBroker slug={props.slug.slug} />;
  }

  if (role === 'client') {
    return <ProductClient slug={props.slug.slug} />;
  }
};
