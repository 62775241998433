import React from 'react';
import Private from './components/Private';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import ClientChangePassword from './pages/ChangePassword/client';
import {Register} from './pages/Register';
import {ReviewBroker} from './pages/ReviewBroker';
import {RegisterTeamAdmin} from './pages/RegisterTeamAdmin';
import {RegisterTeamUser} from './pages/RegisterTeamUser';
import {Dashboard} from './pages/Dashboard';
import {Communications} from './pages/Communications';
import {Process} from './pages/Process';
import {Product} from './pages/Product';
import {ProfileBroker} from './pages/ProfileBroker';
import Profile from './pages/Profile';
import {Trainings} from './pages/Trainings';
import {PastTrainings} from './pages/Trainings/training-past';
import {Tutorials} from './pages/Trainings/tutorials';
import {Certificates} from './pages/Trainings/certificates';
import {Simulators} from './pages/Simulators';
import {Cotations} from './pages/Cotations';
import {Cotation} from './pages/Cotation';
import {BrokersCampaign} from './pages/BrokersCampaign';
import {Policies} from './pages/Policies';
import {Policy} from './pages/Policy';
import {Invoices} from './pages/Invoices';
import {Invoice} from './pages/Invoice';
import {ClientRegister} from './pages/ClientRegister';
import {ClientLoginAuto} from './pages/ClientLoginAuto';

const routes = {
  '/': () => <Login public={true} />,
  '/register': () => <Register public={true} />,
  '/client-login/:temporaryPasswordToken': props => (
    <ClientLoginAuto public={true} props={props} />
  ),
  '/campanha-brokers': () => <BrokersCampaign public={true} />,
  '/reset-password/:token': token => (
    <ChangePassword token={token} public={true} />
  ),
  '/client-reset-password/:token': token => (
    <ClientChangePassword token={token} public={true} />
  ),
  '/review-broker/:reviewToken': props => (
    <ReviewBroker {...props} routeName="ReviewBroker" public={true} />
  ),
  '/register-team-admin/:id': id => (
    <RegisterTeamAdmin routeName="registerTeamAdmin" id={id} public={true} />
  ),
  '/register-team-user/:id': id => (
    <RegisterTeamUser routeName="registerTeamUser" id={id} public={true} />
  ),
  '/change-password': () => <ChangePassword public={true} />,
  '/dashboard': () => <Private component={Dashboard} />,
  '/communications': () => <Private component={Communications} />,
  '/communications/:type': type => (
    <Private component={Communications} contentType={type} />
  ),
  '/produto/:slug': slug => <Private component={Product} slug={slug} />,
  '/processo/:slug': slug => <Private component={Process} slug={slug} />,
  '/informations/:slug': slug => <Private component={Process} slug={slug} />,
  '/sinistros/:slug': slug => <Private component={Process} slug={slug} />,
  '/profile-broker': () => <Private component={ProfileBroker} />,
  '/profile': () => <Private component={Profile} />,
  '/trainings': () => <Private component={Trainings} />,
  '/past-trainings': () => <Private component={PastTrainings} />,
  '/tutorials': () => <Private component={Tutorials} />,
  '/certificates': () => <Private component={Certificates} />,
  '/quotations': () => <Private component={Cotations} />,
  '/policies': () => <Private component={Policies} />,
  '/policies/:id': id => <Private component={Policy} id={id} />,
  '/invoices': () => <Private component={Invoices} />,
  '/invoices/:policyNo/:receiptNo': props => <Private component={Invoice} props={props} />,
  '/quotations/:id': id => <Private component={Cotation} id={id} />,
  '/simuladores/:slug': ({slug}) => (
    <Private component={Simulators} slug={slug} />
  ),
  '/simuladores/:slug/:id': ({slug, id}) => (
    <Private component={Simulators} slug={slug} id={id} />
  ),
  '/client-register': () => <ClientRegister public={true} />,
};

export default routes;
