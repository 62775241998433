import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import moment from 'moment';
import styled from 'styled-components';

import {getTrainingsPast} from '../../graphql/queries';

import {
  Box,
  Flex,
  Table,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Text,
  Button,
  Image,
  Modal,
  theme,
  locale,
  Loader,
  formatDate,
  Video,
} from '@innovago/ui';

const GroupChecked = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 1rem 0 0;

  input {
    opacity: 0;
    position: absolute;
    display: none;
  }

  label {
    display: flex;
    margin: 0
    font-family: Lato;
    font-size: 0.875rem;
    position: relative;
    color: rgba(0,0,0,.87);
    padding: 0;

    &:before {
      display: inline-block;
      content: '';
      width: 18px;
      height: 18px;
      margin: 0 4px 0 0;
      border: 1px solid #03173d;
    }
  }

  input:checked + label {
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      background-color: #03173d;
    }
  }
`;

const FormCheck = ({name, label, checked, onChange}) => {
  return (
    <GroupChecked>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked ? 'checked' : false}
        onChange={onChange}
      />
      <label htmlFor={name}>{label}</label>
    </GroupChecked>
  );
};

export const PastTrainings = () => {
  const [notifications, notify] = useNotification();
  const [modal, setModal] = useState(false);
  const [cats, setCats] = useState([]);

  const [initialCats, setInitialCats] = useState([]);
  const [videoId, setVideoId] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);

  const today = moment().format('YYYY-MM-DD');
  const currentHour = moment().subtract(2, 'hour').format('HH:mm');

  const [whereClause, setWhereClause] = useState({
    _and: [
      {
        status: {_eq: 'published'},
        tutorial: {_eq: false},
      },
      {
        _or: [
          {
            date: {_lte: today},
          },
          {
            _and: [{date: {_eq: today}}, {hour: {_gte: currentHour}}],
          },
        ],
      },
    ],
  });

  const queryVars = {
    where: whereClause,
  };

  const {data, loading} = useQuery(getTrainingsPast, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: () => notify(),
  });

  const handleModalClose = () => {
    setModal(false);
    setVideoId(null);
  };

  const handleCat = id => {
    const exists = cats.indexOf(id);
    let newCats;

    if (exists >= 0) {
      newCats = cats.filter(cat => cat !== id);
    } else {
      newCats = [...cats, id];
    }
    setCats(newCats);
  };

  useEffect(() => {
    if (data && initialCats.length === 0) {
      setInitialCats(data.category.map(cat => cat.id));
    }

    if (data && cats.length === 0) {
      setCats(data.category.map(cat => cat.id));
    }
  }, [data, initialCats, cats]);

  useEffect(() => {
    const where = cats.length > 0 ? cats : initialCats;
    setWhereClause({
      _and: [
        {
          status: {_eq: 'published'},
          tutorial: {_eq: false},
          training_categories: {
            category: {
              id: {
                _in: where,
              },
            },
          },
        },
        {
          _or: [
            {
              date: {_lte: today},
            },
            {
              _and: [{date: {_eq: today}}, {hour: {_gte: currentHour}}],
            },
          ],
        },
      ],
    });
  }, [cats, initialCats, today, currentHour]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size="1.5rem"
          color={theme.colors.darkBlue}
          weight="500"
          padding="2.5rem 0 1.5rem"
        >
          Formações passadas
        </Heading>
        <Box backgroundColor="#eeeeee" padding="1rem" margin="2rem 0">
          <Heading
            level="4"
            font={theme.fonts.mont}
            size="1.2rem"
            color={theme.colors.darkBlue}
            weight="500"
            padding="0 0 1.5rem"
          >
            Filtrar por categoria
          </Heading>
          <Flex justify="flex-start">
            {data.category.map(cat => {
              const checked =
                cats.filter(item => cat.id === item).length > 0 ? true : false;
              return (
                <FormCheck
                  key={cat.id}
                  name={cat.name}
                  checked={checked}
                  onChange={e => handleCat(cat.id)}
                  label={cat.name}
                />
              );
            })}
          </Flex>
        </Box>
        <Table
          loading={loading}
          columns={[
            {
              label: locale.title,
              property: 'name',
              sortable: false,
              format: item => item.name,
            },
            {
              label: locale.teacher,
              property: 'teacher',
              sortable: false,
              format: item => item.teacher,
            },
            {
              label: locale.date,
              property: 'date',
              sortable: false,
              format: item => formatDate(item.date),
            },
            {
              label: 'Vídeo',
              property: 'conference_video',
              sortable: false,
              format: item =>
                item.conference_video ? (
                  <Button
                    size={'.875rem'}
                    color={theme.colors.brand}
                    underline={true}
                    onClick={() => {
                      setModal(true);
                      setVideoId(item.conference_video);
                      setVideoTitle(item.name);
                    }}
                    transparent
                    padding="0"
                    caps
                  >
                    <Image
                      src={`http://img.youtube.com/vi/${item.conference_video}/sddefault.jpg`}
                      width="100px"
                    />
                  </Button>
                ) : (
                  <Text>---</Text>
                ),
            },
            {
              label: 'Apresentação',
              property: 'conference_pdf',
              sortable: false,
              format: item =>
                item.attachments[0] && item.attachments[0].url ? (
                  <Button
                    href={item.attachments[0].url}
                    size={'.875rem'}
                    color={theme.colors.brand}
                    filled
                    target="_blank"
                    padding="0 .5rem"
                    caps
                  >
                    Descarregar (PDF)
                  </Button>
                ) : (
                  <Text>---</Text>
                ),
            },
            {
              label: 'Categorias',
              property: 'training_categories',
              sortable: false,
              format: item => {
                if (
                  item.training_categories &&
                  item.training_categories.length > 0
                ) {
                  return item.training_categories
                    .map(category => {
                      return category.category.name;
                    })
                    .toString();
                } else {
                  return '---';
                }
              },
            },
          ]}
          data={data && data.pasttrainings}
        />
      </Wrapper>

      {modal && videoId && (
        <Modal onClose={() => handleModalClose()}>
          <Heading
            level={2}
            color={theme.colors.brand}
            weight={500}
            padding="0 0 2rem"
          >
            Tutorial - {videoTitle}
          </Heading>
          <Video id={videoId} margin="0 0 2rem" />
          <Flex justify="flex-end">
            <Button filled onClick={() => handleModalClose()}>
              {locale.close}
            </Button>
          </Flex>
        </Modal>
      )}
    </Box>
  );
};
