import gql from 'graphql-tag';

export const InsertBroker = gql`
  mutation insertBroker($data: InsertBrokerInput!) {
    insertBroker(data: $data) {
      id
      email
      reviewToken
    }
  }
`;

export const UpdateBroker = gql`
  mutation update_broker($set: broker_set_input, $review_token: String!) {
    update_broker(where: {review_token: {_eq: $review_token}}, _set: $set) {
      affected_rows
    }
  }
`;

export const UpdateBrokerById = gql`
  mutation update_broker($set: broker_set_input, $id: Int!) {
    update_broker(where: {id: {_eq: $id}}, _set: $set) {
      affected_rows
    }
  }
`;

export const UpdateBrokerAttachment = gql`
  mutation update_attachment(
    $brokerID: Int
    $fieldname: String
    $filename: String
    $filesize: Int
    $url: String
  ) {
    update_attachment(
      where: {broker: {id: {_eq: $brokerID}}, field_name: {_eq: $fieldname}}
      _set: {file_name: $filename, file_size: $filesize, url: $url}
    ) {
      affected_rows
    }
  }
`;

export const InsertUser = gql`
  mutation insert_user($objects: [user_insert_input!]!) {
    insert_user(
      objects: $objects
      on_conflict: {
        constraint: UQ_8f430eeb8a1de2d581abf423984
        update_columns: [
          email
          sub
          forename
          status
          invite_id
          broker_id
          role
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CreateInvite = gql`
  mutation createInvite($data: CreateInviteInput!) {
    createInvite(data: $data) {
      id
    }
  }
`;

export const UpdateInvite = gql`
  mutation update_invite($id: uuid!, $user_id: Int) {
    update_invite(_set: {user_id: $user_id}, where: {id: {_eq: $id}}) {
      returning {
        id
        email
      }
    }
  }
`;

export const PromoteUser = gql`
  mutation promoteUserToTeamAdmin($email: String!) {
    promoteUserToTeamAdmin(data: {email: $email}) {
      id
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($id: Int, $data: user_set_input) {
    update_user(_set: $data, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const updateClient = gql`
  mutation updateClient($id: Int, $data: client_set_input) {
    update_client(_set: $data, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const DeactivateUser = gql`
  mutation deactivateUser($id: ID!) {
    deactivateUser(data: {id: $id}) {
      id
    }
  }
`;

export const ActivateUser = gql`
  mutation activateUser($id: ID!) {
    activateUser(data: {id: $id}) {
      id
    }
  }
`;

export const requestPasswordReset = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(data: {email: $email})
  }
`;

export const resetPassword = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      id
    }
  }
`;

export const clientRequestPasswordReset = gql`
  mutation clientRequestPasswordReset($nif: String!) {
    clientRequestPasswordReset(data: {nif: $nif})
  }
`;

export const clientResetPassword = gql`
  mutation clientResetPassword($data: ResetPasswordInput!) {
    clientResetPassword(data: $data) {
      id
    }
  }
`;

export const InsertClaim = gql`
  mutation insertClaim($data: InsertClaimInput!) {
    insertClaim(data: $data) {
      id
    }
  }
`;

export const CheckNIF = gql`
  mutation checkNif($args: CheckNifInput!) {
    checkNif(arg: $args) {
      error
      policyholder
      success
      valid
      warning
    }
  }
`;

export const createPolicy = gql`
  mutation createPolicy($args: CreatePolicyInput!) {
    createPolicy(data: $args) {
      id
      policyNo
      revisions {
        rating
      }
    }
  }
`;

export const updatePolicy = gql`
  mutation updatePolicy($args: UpdatePolicyInput!) {
    updatePolicy(data: $args) {
      id
      policyNo
      revisions {
        status
        rating
        attachment {
          fileName
          url
        }
      }
    }
  }
`;

export const sendDocument = gql`
  mutation sendDocument(
    $document: String!
    $policyNo: String!
    $filename: String!
  ) {
    sendDocument(
      arg: {document: $document, policyNo: $policyNo, filename: $filename}
    ) {
      success
      message
      error
    }
  }
`;

export const sendDocuments = gql`
  mutation sendDocuments($documents: [DocumentInput!]!, $policyNo: String!) {
    sendDocuments(arg: {documents: $documents, policyNo: $policyNo}) {
      success
      message
      error
    }
  }
`;

export const sendPolicyEmail = gql`
  mutation sendPolicyEmail($policyId: ID!) {
    sendPolicyEmail(policyId: $policyId)
  }
`;

export const InsertPresence = gql`
  mutation InsertPresence($training_id: Int!, $user_id: Int!) {
    insert_presence(objects: {training_id: $training_id, user_id: $user_id}) {
      affected_rows
    }
  }
`;

export const InsertBrokerAttachment = gql`
  mutation insertBrokerAttachment(
    $attachments: [InsertBrokerAttachment!]!
    $brokerId: ID!
  ) {
    insertBrokerAttachment(
      data: {attachments: $attachments, brokerId: $brokerId}
    )
  }
`;

export const SendProductForm = gql`
  mutation SendProductForm($content: String!, $productId: ID!) {
    sendProductForm(data: {content: $content, productId: $productId}) {
      sentAt
      error
    }
  }
`;

export const SendTrainingForm = gql`
  mutation sendTrainingForm($content: String!) {
    sendTrainingForm(data: {content: $content}) {
      error
    }
  }
`;

export const UpdatePolicyAddress = gql`
  mutation updatePolicyAddress(
    $address: [PolicyAddress!]!
    $brokerNo: String
    $policyNo: ID!
    $nif: String
  ) {
    updatePolicyAddress(
      data: {address: $address, brokerNo: $brokerNo, policyNo: $policyNo, nif: $nif}
    ) {
      error
      policies
      warning
    }
  }
`;

export const SignUpUser = gql`
  mutation SignUpUser($data: SignUpUserInput!) {
    signUpUser(data: $data) {
      id
    }
  }
`;

export const SignUpClient = gql`
  mutation SignUpClient($data: SignUpClientInput!) {
    signUpClient(data: $data) {
      error
      success
      warning
      via
    }
  }
`;
