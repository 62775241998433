import React, {useContext, useState} from 'react';
import Auth from '@aws-amplify/auth';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {AuthContext} from '../../contexts/AuthContext';

import {
  Box,
  Button,
  FormPassword as FieldPassword,
  Grid2,
  Heading,
  Text,
  Loader,
  Wrapper,
  NotificationContainer,
  useNotification,
  PasswordHelper,
  locale,
  theme,
  passwordValidator,
  FormInput,
} from '@innovago/ui';

import {getClient} from '../../graphql/queries';
import {updateClient as updateClientMutation} from '../../graphql/mutations';

const FormPersonal = ({initialValues, notify}) => {
  const [updateClient] = useMutation(updateClientMutation, {
    onCompleted: ({update_client}) => {
      if (update_client.affected_rows > 0) {
        notify(locale.profileUpdated, 'success');
      }
      else {
        notify();
      }
    },
    onError: () => notify(),
  });

  const {email, phone, name} = initialValues;
  const isString = Yup.string().nullable();
  const validationPersonal = Yup.object().shape({
    name: name ? isString.required(locale.required) : isString,
    email: (email ? isString.required(locale.required) : isString).email(),
    phone: phone ? isString.required(locale.required) : isString,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationPersonal,
    onSubmit: ({name, email, phone, id}) => {
      updateClient({
        variables: {data: {name, email, phone}, id},
      });
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Heading
          padding="2rem 0"
          level="2"
          color={theme.colors.darkBlue}
          size="1.5rem"
        >
          {locale.personalData}
        </Heading>
        <Text padding="0 0 3rem">
          <Text display="inline" weight="700">
            NIF:
          </Text>{' '}
          {initialValues.nif}
        </Text>
        <FormInput
          type="text"
          name="name"
          label={locale.forename}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="email"
          label={locale.email}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="phone"
          label={locale.phone}
          formik={formik}
          width="100%"
        />
        <Button type="submit" filled>
          {locale.submit}
        </Button>
      </form>
    </Box>
  );
};

const validationPassword = Yup.object().shape({
  oldPassword: Yup.string().required(locale.required),
  newPassword: Yup.string()
    .min(6, locale.passwordMin)
    .required(locale.required),
});

const FormPassword = ({onSubmit = () => {}}) => {
  const [newPassword, setNewPassword] = useState('');

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: validationPassword,
    validateOnBlur: false,
    validateOnChange: true,
    validate: (values, props) => {
      setNewPassword(values.newPassword);
    },
    onSubmit: values => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading
        padding="2rem 0"
        level="2"
        color={theme.colors.darkBlue}
        size="1.5rem"
      >
        {locale.passwordChange}
      </Heading>
      <FieldPassword
        name="oldPassword"
        label={locale.oldPassword}
        formik={formik}
        width="100%"
      />
      <FieldPassword
        width="100%"
        name="newPassword"
        label={locale.newPassword}
        formik={formik}
      />
      <Button type="submit" filled>
        {locale.submit}
      </Button>
      <PasswordHelper password={newPassword}></PasswordHelper>
    </form>
  );
};

const ProfileClient = () => {
  const {logout, user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const {data, loading} = useQuery(getClient, {
    skip: !(user && user.username),
    variables: {
      sub: user.username,
    },
    onError: () => notify(),
  });

  async function handleChangePassword({oldPassword, newPassword}) {
    const rules = passwordValidator(newPassword);

    try {
      if (Object.values(rules).reduce((prev, curr) => prev && curr, true)) {
        await Auth.changePassword(user, oldPassword, newPassword);

        return notify(locale.passwordUpdated, 'success');
      }
      else {
        return notify('A nova password é inválida.', 'error');
      }
    } catch (err) {
      return notify('Não conseguimos alterar a sua password. Pode usar a recuperação de password na página de login.', 'error');
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (!data || (data && !data.client[0])) {
    logout();
    return null;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <Wrapper>
        <NotificationContainer {...notifications} />
        <Heading
          level="2"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          padding="0 0 .5rem"
          caps
        >
          perfil pessoal
        </Heading>
        <Heading
          level="1"
          font={theme.fonts.mont}
          weight="500"
          size="2rem"
          color={theme.colors.brand}
          letter=".24px"
          padding="0 0 2.5rem"
        >
          {locale.profile}
        </Heading>
        <Grid2 padding="0 0 3rem" align="flex-start">
          <FormPersonal initialValues={data.client[0]} notify={notify} />
          <FormPassword onSubmit={handleChangePassword} />
        </Grid2>
      </Wrapper>
    </Box>
  );
};

export default ProfileClient;
