import React, {useState, useContext, useEffect} from 'react';

import InvoicesBroker from './invoicesBroker';
import InvoicesClient from './invoicesClient';

import {AuthContext} from '../../contexts/AuthContext';

export const Invoices = () => {
  const [role, setRole] = useState(false);
  const {user} = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      if (!user.signInUserSession?.accessToken?.payload['cognito:groups']) {
        setRole('broker');
      } else {
        if (
          user.signInUserSession?.accessToken?.payload['cognito:groups'][0] ===
          'clients'
        ) {
          setRole('client');
        } else {
          setRole('broker');
        }
      }
    }
  }, [user]);

  if (!role) {
    return <></>;
  }

  if (role === 'broker') {
    return <InvoicesBroker />;
  }

  if (role === 'client') {
    return <InvoicesClient />;
  }
};
