import React, {useEffect, useContext} from 'react';
import {navigate} from 'hookrouter';

import {AuthContext} from '../../contexts/AuthContext';

import {
  Box,
  NotificationContainer,
  useNotification,
  locale,
} from '@innovago/ui';

export const ClientLoginAuto = props => {
  const authContext = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const {temporaryPasswordToken} = props.props;
  const tokens = temporaryPasswordToken.split('.');
  const {nif, temporaryPassword: password} = JSON.parse(
    Buffer.from(tokens[1], 'base64')
  );
  const email = 'no-reply+inno-%@minuscode.com'.replace('%', nif);

  useEffect(() => {
    const login = async notify => {
      try {
        if (authContext.isSignedIn()) {
          navigate('/dashboard', true);
        } else {
          const loginAvailable = await authContext.login(email, password);

          if (!loginAvailable) {
            notify('Não tem permissões para ver esta página', 'error');
          } else {
            if (authContext.isSignedIn()) {
              navigate('/dashboard', true);
            }
          }
        }
      } catch (err) {
        notify(locale.loginError);
      }
    };
    login(notify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext]);

  return (
    <Box position="relative" height="100%">
      <NotificationContainer {...notifications} />
    </Box>
  );
};

export default ClientLoginAuto;
