import React, {useState} from 'react';

import {
  Box,
  Flex,
  Grid2,
  Wrapper,
  Modal,
  Heading,
  Text,
  Button,
  locale,
  theme,
} from '@innovago/ui';

import {RegisterIntro} from './registerIntro';
import {RegisterForm} from './registerForm';

export const Register = () => {
  const [email, setEmail] = useState(0);

  function changeEmail(email) {
    setEmail(email.toLowerCase());
  }

  if (email) {
    return (
      <Wrapper>
        <Modal>
          <Heading
            level={2}
            color={theme.colors.brand}
            weight={500}
            padding="0 0 1rem"
          >
            {locale.accountRequest}
          </Heading>
          <Text padding="0 0 1rem">{locale.accountRequestReply}</Text>
          <Text font="Lato" size="1.125rem" weight="700" padding="0 0 2rem">
            {email.toLowerCase()}
          </Text>
          <Flex justify="flex-end">
            <Button href="/" filled>
              {locale.backToStart}
            </Button>
          </Flex>
        </Modal>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Grid2 padding="3rem 0 0 0" align="flex-start">
          <Box>
            <RegisterIntro />
          </Box>
          <Box elevated padding="1.5rem 1rem 2rem 1rem" margin="0 0 3rem">
            <RegisterForm onSuccess={changeEmail} />
          </Box>
        </Grid2>
      </Wrapper>
    );
  }
};
