import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../theme';

import {ArrowUp, ArrowDown, Options} from '../Icons';

const TableContainer = styled.table`
  border-collapse: collapse;
  font-family: ${theme.fonts.lato};
  line-height: 1.3;
  margin: ${({spacer}) => (spacer ? '0 0 3rem' : 0)};
  text-align: left;
  width: 100%;
  max-width: 100%;
`;

const TableRow = styled.tr`
  cursor: default;

  &:nth-child(2n) {
    background-color: ${theme.colors.lightGrey};
  }

  &:hover {
    background-color: ${theme.colors.silver};
  }
`;

const TableCell = styled.td`
  color: ${theme.colors.black};
  font-size: 0.875rem;
  padding: 1rem;
  font-weight: 400;
  white-space: ${props => (props.wrap ? props.wrap : 'initial')};
  ${({slimPadding}) => (slimPadding ? `
    padding: 1rem 0.1rem 1rem 0.1rem;
    text-align: center;

    a {
      margin: 0 auto;
    }
    ` : '')
  };

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    position: relative;
    display: block;
    height: calc(30px + 1rem);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 45%;
    border-bottom: 1px solid ${theme.colors.blueyGrey};
    font-size: 0.875rem;

    &::before {
      content: attr(data-content);
      position: absolute;
      left: 2%;
      top: 50%;
      transform: translateY(-50%);
      width: 38%;
    }
  }
`;

const TableHeading = styled(TableCell)`
  background-color: ${({active}) =>
    active ? theme.colors.blueyGrey : theme.colors.dirtyBlue};
  border-left: 4px solid;
  color: #fff;
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-transform: uppercase;
  width: ${({width}) => (width ? width : 'auto')};

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const TableLabel = styled(TableCell)`
  text-align: center;
`;

const TableSortContainer = styled.div`
  display: inline-block;
  padding-left: 0.5rem;
  vertical-align: middle;
`;

const TableSort = styled.button`
  appearance: none;
  background: inherit;
  border: none;
  color: ${({active}) => (active ? theme.colors.dirtyBlue : 'inherit')};
  cursor: pointer;
  display: block;
  padding: 0.3rem;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
    outline: none;
  }
`;

const TableArrow = styled(ArrowUp)`
  display: block;
  pointer-events: none;
  width: 12px;
`;

const TableActionsContainer = styled.div`
  position: relative;
`;

const TableActions = styled.div`
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  left: 0;
  padding: 0.5rem;
  position: absolute;
  row-gap: 0.5rem;
  top: 100%;
  visibility: ${({active}) => (active ? 'visible' : 'hidden')};
  z-index: 1;
`;

const TableAction = styled.button`
  appearance: none;
  background: inherit;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  text-align: left;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
    outline: none;
  }
`;

export const TableMenu = ({actions}) => {
  const [open, setOpen] = useState(false);

  return (
    <TableActionsContainer>
      <TableAction onClick={() => setOpen(!open)}>
        <Options />
      </TableAction>
      <TableActions active={open}>
        {actions.map(({label, onClick}) => (
          <TableAction key={label} transparent onClick={onClick}>
            {label}
          </TableAction>
        ))}
      </TableActions>
    </TableActionsContainer>
  );
};

TableMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      {
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }.isRequired
    )
  ).isRequired,
};

const SortBox = ({active, onChange, property}) => {
  const isActiveProperty = active.column === property;

  return (
    <TableSortContainer>
      <TableSort
        active={isActiveProperty && active.order === 'asc'}
        onClick={() => onChange('asc')}
      >
        <TableArrow />
      </TableSort>
      <TableSort
        active={isActiveProperty && active.order === 'desc'}
        onClick={() => onChange('desc')}
      >
        <TableArrow as={ArrowDown} />
      </TableSort>
    </TableSortContainer>
  );
};

SortBox.propTypes = {
  active: PropTypes.shape(
    {
      column: PropTypes.string,
      order: PropTypes.string,
    }.isRequired
  ),
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
};

export const Table = memo(
  ({
    columns,
    data,
    emptyLabel = 'Não existem dados',
    loading,
    slimPadding,
    onClickSort = () => {},
    sort = {
      column: null,
      order: null,
    },
    spacer,
  }) => {
    const [activeSort, setActiveSort] = useState(sort);

    return (
      <TableContainer spacer>
        <thead>
          <TableRow>
            {columns.map((column, i) => (
              <TableHeading
                as="th"
                key={column.property + i}
                active={activeSort.column === column.property}
                width={column.width}
                slimPadding={slimPadding}
              >
                {column.label}
                {column.sortable && (
                  <SortBox
                    active={activeSort}
                    property={column.property}
                    onChange={sort => {
                      const currSort = {
                        column: column.property,
                        order: sort,
                      };
                      onClickSort(currSort);
                      setActiveSort(currSort);
                    }}
                  />
                )}
              </TableHeading>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <TableRow key={item.id ? item.id : 'key' + index}>
                {columns.map(column => {
                  return (
                    <TableCell
                      data-content={column.label}
                      as="td"
                      key={
                        'row' +
                        index +
                        '_' +
                        (item.id ? item.id : '') +
                        column.property
                      }
                      wrap={column.whitespace ? column.whitespace : 'initial'}
                      slimPadding={slimPadding}
                    >
                      {column.format(item)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableLabel colSpan={columns.length}>
                {loading ? 'A carregar' : emptyLabel}
              </TableLabel>
            </TableRow>
          )}
        </tbody>
      </TableContainer>
    );
  }
);

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape(
      {
        property: PropTypes.string.isRequired,
        label: PropTypes.string,
        sortable: PropTypes.bool,
        format: PropTypes.func.isRequired,
        width: PropTypes.string,
      }.isRequired
    )
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  emptyLabel: PropTypes.string,
  loading: PropTypes.bool,
  onClickSort: PropTypes.func,
  spacer: PropTypes.bool,
  sort: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.string,
  }),
};
