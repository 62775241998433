/* global Rollbar */
import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import moment from 'moment';

import {
  Box,
  NotificationContainer,
  useNotification,
  Loader,
  ModelWithOverlay,
  Text,
  Flex,
  Heading,
  theme,
  Button,
} from '@innovago/ui';

import {AuthContext} from '../../contexts/AuthContext';
import {Campaign} from './campaign';
import {Trainings} from './trainings';
import {Newsletter} from './newsletter';
import {Information} from './information';
import {Charts} from './charts';

import {getDashboardContent} from '../../graphql/queries';

const DashboardBroker = () => {
  const {user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [modalVisibility, setModayVisibility] = useState(false);
  const today = moment().format('YYYY-MM-DD');
  const {data: content, loading} = useQuery(getDashboardContent, {
    skip: !user,
    variables: {
      type1: 'campaign',
      type2: 'newsletter',
      type3: 'information',
      date: today,
      date2: String(today),
      sub: user && user.username,
    },
    onError: () => notify(),
  });

  const userData = content?.user[0];

  useEffect(() => {
    if (content && (!userData?.birthdate || !userData?.id_card)) {
      setModayVisibility(true);
    }
  }, [content, userData]);

  const closeModal = () => {
    setModayVisibility(false);
  };

  if (loading || !userData) {
    return <Loader />;
  }

  const campaignImage = JSON.parse(
    content.setting.find(item => item.name === 'campaignImage').value
  );
  const newsletterImage = JSON.parse(
    content.setting.find(item => item.name === 'newsletterImage').value
  );
  const infoImage = JSON.parse(
    content.setting.find(item => item.name === 'infoImage').value
  );
  const trainingsImage = JSON.parse(
    content.setting.find(item => item.name === 'trainingsImage').value
  );

  let userID;

  try {
    userID = content.user[0].id;
  } catch (e) {
    const debugObj = {
      cognitoUser: user,
      response: content,
    };
    Rollbar.error('Something went wrong', JSON.stringify(debugObj));
  }

  return (
    <Box width="100%">
      <NotificationContainer {...notifications} />

      {content.campaign[0] && (
        <Campaign content={content.campaign[0]} image={campaignImage} />
      )}

      <Charts />

      {content.training[0] && (
        <Trainings
          content={content.training[0]}
          image={trainingsImage}
          userID={userID}
        />
      )}

      {content.newsletter[0] && (
        <Newsletter content={content.newsletter[0]} image={newsletterImage} />
      )}

      {content.information[0] && (
        <Information content={content.information[0]} image={infoImage} />
      )}

      {modalVisibility && (
        <ModelWithOverlay top="50%" onClose={closeModal} hideClose={true}>
          <Heading
            level="2"
            font={theme.fonts.mont}
            weight="500"
            size="2rem"
            padding="0 0 2rem"
          >
            Complete o seu perfil
          </Heading>
          <Text>
            Estes dados são necessarios para que possa receber os certificados
            de participação nos webinares Innovarisk.
          </Text>
          <Flex justify="flex-end">
            <Button filled href="/profile">
              Editar perfil
            </Button>
          </Flex>
        </ModelWithOverlay>
      )}
    </Box>
  );
};

export default DashboardBroker;
