import React from 'react';
import {Badge, locale} from '@innovago/ui';

export const getData = (questions, answers) => {
  const data = [];

  if (!questions) return data;

  questions.Policyholder.map(question => {
    let ans = answers.Policy.Policyholder.filter(
      answer => answer.InputId === question.InputId
    );
    if (ans[0]) {
      data.push({
        label: question.QuestionText,
        value: ans[0].Answer === 'PRT' ? 'Portugal' : ans[0].Answer,
      });
    }
    return false;
  });

  questions.Risks.map(risk => {
    risk.Covers.map(cover => {
      return cover.Items.map(item => {
        return item.Questions.map(q => {
          return answers.Policy.Events[0].Transaction.PolicyRisks[0].PolicyCovers.map(
            aCover => {
              return aCover.PolicyItems.map(aItem => {
                let ans = aItem.PolicyAnswers.filter(
                  pAnswer => q.InputId === pAnswer.InputId
                );

                if (questions.Visibility) {
                  const conditionalField = questions.Visibility.filter(
                    field => {
                      return q.InputId === field.InputId;
                    }
                  );

                  if (
                    conditionalField.length === 0 ||
                    (conditionalField.length > 0 &&
                      ans[0] &&
                      ans[0].Answer !== '')
                  ) {
                    if (ans.length > 0) {
                      data.push({
                        label: q.QuestionText,
                        value: ans[0].Answer,
                      });
                    }
                  }
                } else {
                  if (ans.length > 0) {
                    data.push({
                      label: q.QuestionText,
                      value: ans[0].Answer,
                    });
                  }
                }
                return false;
              });
            }
          );
        });
      });
    });
    return false;
  });

  questions.Risks.map(risk => {
    risk.Questions.map(policyQuestion => {
      let ans =
        answers.Policy.Events[0].Transaction.PolicyRisks[0].PolicyAnswers.filter(
          answer => answer.InputId === policyQuestion.InputId
        );

      if (ans.length > 0) {
        let code = [];
        if (policyQuestion.Attribute === 'List') {
          code = policyQuestion.Answers.filter(
            answer => answer.AnswerCode === ans[0].Answer
          );
        }

        if (questions.Visibility) {
          const conditionalField = questions.Visibility.filter(
            field => policyQuestion.InputId === field.InputId
          );

          if (
            conditionalField.length === 0 ||
            (conditionalField.length > 0 &&
              (ans[0].Answer !== '' || (code[0] && code[0].AnswerText)))
          ) {
            data.push({
              label: policyQuestion.QuestionText,
              value: code.length > 0 ? code[0].AnswerText : ans[0].Answer,
            });
          }
        } else {
          if (ans.length > 0) {
            data.push({
              label: policyQuestion.QuestionText,
              value: code.length > 0 ? code[0].AnswerText : ans[0].Answer,
            });
          }
        }
      }
      return false;
    });
    return false;
  });

  questions.Questions.map(question => {
    let ans = answers.Policy.Events[0].Transaction.PolicyAnswers.filter(
      answer => answer.InputId === question.InputId
    );

    if (ans.length > 0) {
      let code = [];
      if (question.Attribute === 'List') {
        code = question.Answers.filter(
          answer => answer.AnswerCode === ans[0].Answer
        );
      }

      if (questions.Visibility) {
        const conditionalField = questions.Visibility.filter(field => {
          return question.InputId === field.InputId;
        });

        if (
          conditionalField.length === 0 ||
          (conditionalField.length > 0 &&
            (ans[0].Answer !== '' || (code[0] && code[0].AnswerText)))
        ) {
          data.push({
            label: question.QuestionText,
            value: code.length > 0 ? code[0].AnswerText : ans[0].Answer,
          });
        }
      } else {
        data.push({
          label: question.QuestionText,
          value: code.length > 0 ? code[0].AnswerText : ans[0].Answer,
        });
      }
    }
    return false;
  });

  return data;
};

export const getBadge = status => {
  switch (status) {
    case 'accepted':
      return <Badge label={locale.cotationStatus[status]} type="active" />;
    case 'submitted':
      return <Badge label={locale.cotationStatus[status]} type="submitted" />;
    case 'deleted':
      return <Badge label={locale.cotationStatus[status]} type="deleted" />;
    case 'pending':
    case 'expired':
    case 'draft':
    default:
      return <Badge label={locale.cotationStatus[status]} type="pending" />;
  }
};
