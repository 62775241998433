import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../../theme';

export const ItemStatus = Object.freeze({
  Draft: 'draft',
  Published: 'published',
  Deleted: 'deleted',
  Submitted: 'submitted',
  Pendent: 'pendent',
  Accepted: 'accepted',
  Pending: 'pending',
  Rejected: 'rejected',
  Active: 'active',
  Inactive: 'inactive',
  Scheduled: 'scheduled',
});

const BadgeTypes = Object.freeze({
  Default: 'default',
  Warn: 'warn',
  Success: 'success',
  Danger: 'danger',
  Info: 'info',
});

const badgeStyles = {
  [BadgeTypes.Default]: {
    bg: '#fff',
    color: theme.colors.black,
    border: theme.colors.darkBlue,
  },
  [BadgeTypes.Warn]: {
    bg: theme.colors.yellow,
    color: '#fff',
    border: theme.colors.yellow,
  },
  [BadgeTypes.Success]: {
    bg: theme.colors.success,
    color: '#fff',
    border: theme.colors.success,
  },
  [BadgeTypes.Danger]: {
    bg: theme.colors.error,
    color: '#fff',
    border: theme.colors.error,
  },
  [BadgeTypes.Info]: {
    bg: theme.colors.info,
    color: '#fff',
    border: theme.colors.info,
  },
};

function getStyles(type) {
  switch (type) {
    case ItemStatus.Published:
    case ItemStatus.Accepted:
    case ItemStatus.Active:
      return BadgeTypes.Success;
    case ItemStatus.Rejected:
    case ItemStatus.Inactive:
    case ItemStatus.Deleted:
      return BadgeTypes.Danger;
    case ItemStatus.Submitted:
    case ItemStatus.Scheduled:
      return BadgeTypes.Info;
    case ItemStatus.Pendent:
    case ItemStatus.Pending:
      return BadgeTypes.Warn;
    default:
      return BadgeTypes.Default;
  }
}

const Wrapper = styled.div`
  background-color: ${({type}) => badgeStyles[type].bg};
  border: ${({type}) => `1px solid ${badgeStyles[type].border}`};
  border-radius: 4px;
  color: ${({type}) => badgeStyles[type].color};
  display: inline-block;
  font-family: ${theme.fonts.lato};
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.75;
  padding: 0 0.5rem;
  text-transform: uppercase;
  white-space: ${props => (props.whitespace ? props.whitespace : 'initial')};
`;

const Badge = ({label, type, ...rest}) => {
  return (
    <Wrapper type={getStyles(type)} {...rest}>
      {label}
    </Wrapper>
  );
};

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ItemStatus)).isRequired,
};

export default Badge;
