import React, {useState, useEffect, useContext} from 'react';

import {AuthContext} from '../../contexts/AuthContext';
import PoliciesBroker from './policiesBroker';
import PoliciesClient from './policiesClient';

export const Policies = () => {
  const [role, setRole] = useState(false);
  const {user} = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      if (!user.signInUserSession?.accessToken?.payload['cognito:groups']) {
        setRole('broker');
      } else {
        if (
          user.signInUserSession?.accessToken?.payload['cognito:groups'][0] ===
          'clients'
        ) {
          setRole('client');
        } else {
          setRole('broker');
        }
      }
    }
  }, [user]);

  if (!role) {
    return <></>;
  }

  if (role === 'broker') {
    return <PoliciesBroker />;
  }

  if (role === 'client') {
    return <PoliciesClient />;
  }
};
