import React, {useState} from 'react';
import styled from 'styled-components';

import {Box} from '../Box';
import {Text} from '../Text';
import {Heading} from '../Heading';
import theme from '../../theme';

const AccordionItem = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px dashed ${theme.colors.cloudyBlue};
`;

const AccordionItemTitle = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.black};
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: ${theme.fonts.lato};
  padding: 0 0 0.5rem 0;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: 1px solid ${theme.colors.brand};
    border-right: 1px solid ${theme.colors.brand};
    transform-origin: top center;
    transition: all 0.5s;
    transform: ${props => (props.open ? 'rotate(-45deg)' : 'rotate(135deg)')};
  }
`;

const ProcessText = styled(Text)`
  color: ${theme.colors.black};
  font-size: 1rem;
  font-weight: 400;
  font-family: ${theme.fonts.lato};
  line-height: 1.75;
  overflow: hidden;
  transition: all 0.9s;
  max-height: ${props => (props.open ? '200px' : '0')};
`;

export const Accordion = ({...props}) => {
  const [open, setOpen] = useState([]);

  const handleAccordion = index => {
    setOpen(prevState => {
      if (!prevState.includes(index)) {
        return [...prevState, index];
      } else {
        return prevState.filter(item => item !== index);
      }
    });
  };

  return (
    <Box>
      <Heading
        key={`process_accordion_item_title`}
        level="3"
        color={theme.colors.darkBlue}
        size=".875rem"
        weight="500"
        letter="2.25px"
        line="1rem"
        font={theme.fonts.lato}
        padding="0 0 1rem"
      >
        {props.data.title}
      </Heading>

      {props.data.items.map((item, index) => {
        return (
          <AccordionItem key={`process_accordion_item_${index}`}>
            <AccordionItemTitle
              key={`process_accordion_item_title_${index}`}
              onClick={() => handleAccordion(index)}
              open={open.includes(index)}
            >
              {item.label}
            </AccordionItemTitle>
            <ProcessText
              key={`process_accordion_item_text_${index}`}
              open={open.includes(index)}
            >
              {item.text}
            </ProcessText>
          </AccordionItem>
        );
      })}
    </Box>
  );
};
