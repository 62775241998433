import React, {useState, useEffect, useContext} from 'react';
import {useQuery, useMutation, useLazyQuery} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Wrapper,
  Subtitle,
  Title,
  Loader,
  Button,
  Grid2,
  DataTable,
  FormInput,
  NotificationContainer,
  useNotification,
  theme,
  locale,
  currency,
} from '@innovago/ui';

import {
  clientPolicy,
  getClient,
  getPolicyDocumentClient,
} from '../../graphql/queries';
import {UpdatePolicyAddress as UpdatePolicyAddressMutation} from '../../graphql/mutations';
import {AuthContext} from '../../contexts/AuthContext';

const validationSchema = Yup.object().shape({
  ADDRESS1: Yup.string().required('Obrigatório'),
  ADDRESS3: Yup.string().required('Obrigatório'),
  POSTCODE: Yup.string().required('Obrigatório'),
});

const PolicyClient = props => {
  const [notifications, notify] = useNotification();
  const [nif, setNif] = useState(false);
  const [policyData, setPolicyData] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [changeAddress, setChangeAddress] = useState(false);
  const [event, setEvent] = useState(false);
  const [policyNo, setPolicyNo] = useState(false);
  const {user} = useContext(AuthContext);

  useEffect(() => {
    setPolicyNo(String(props.id));
  }, [props.id]);

  const [updatePolicyAddress] = useMutation(UpdatePolicyAddressMutation, {
    onCompleted: data => {
      if (data.updatePolicyAddress?.error) {
        notify('Ocorreu um erro', 'error');
      } else {
        notify('O endereço foi actualizado correctamente', 'success');

        if (data.updatePolicyAddress?.warning) {
          notify(data.updatePolicyAddress.warning, 'warning', false);
        }

        refetchPolicy();
      }
    },
    onError: () => notify('Ocorreu um erro', 'error'),
  });

  const {loading: loading1} = useQuery(getClient, {
    fetchPolicy: 'no-cache',
    skip: !(user && user.username),
    variables: {
      sub: user.username,
    },
    onCompleted: data => {
      setNif(data.client[0].nif);
    },
    onError: error => {
      notify();
    },
  });

  const formik = useFormik({
    initialValues: {
      ADDRESS1: '',
      ADDRESS2: '',
      ADDRESS3: '',
      ADDRESS4: '',
      POSTCODE: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      const address = Object.entries(values).map(item => {
        return {
          Question: item[0],
          Answer: item[1],
          InputId: item[0],
        };
      });

      updatePolicyAddress({
        variables: {
          address: address,
          policyNo: policyNo,
          nif
        },
      });
    },
  });

  const {loading: loading2, refetch: refetchPolicy} = useQuery(clientPolicy, {
    fetchPolicy: 'no-cache',
    skip: !(nif && policyNo),
    variables: {
      data: {nif: nif, policyNo: String(policyNo)},
      data2: {nif: nif, policyNo: String(policyNo)},
    },
    onCompleted: data => {
      const policies = JSON.parse(data.clientPolicies.policies);
      const address = JSON.parse(data.getPolicyAddress.address);

      if (data.getPolicyAddress?.warning) {
        notify(data.getPolicyAddress.warning, 'warning', false);
      }

      let init = [];
      address.forEach(item => {
        formik.setFieldValue(item.Question, item.Answer);
        init[item.Question] = item.Answer;
      });
      setInitialValues(init);

      const policy = policies[0];
      policy['Prémio Anual'] = currency.format(policy['Prémio Anual']);
      policy['Capital Seguro'] = currency.format(policy['Capital Seguro']);
      setPolicyNo(policy['Nº da Apólice']);
      setEvent(policy['LastEvent']);

      delete policy['LastEvent'];
      delete policy['Moeda'];
      const policyKeys = Object.keys(policy);
      const formatedPolicy = policyKeys.map(key => {
        return {
          label: key,
          value: policy[key],
        };
      });
      setPolicyData(formatedPolicy);
    },
    onError: error => {
      notify();
    },
  });

  const [handleRequestPDF, { loading: loadingPdf }] = useLazyQuery(getPolicyDocumentClient, {
    fetchPolicy: 'no-cache',
    skip: !(event && policyNo),
    variables: {
      eventNo: String(event),
      policyNo: String(policyNo),
      nif: nif,
    },
    onCompleted: data => {
      if (data.getPolicyDocument.warning) {
        notify(data.getPolicyDocument.warning);
      } else {
        window.open(data.getPolicyDocument.url, '_blank');
      }
    },
    onError: error => {
      notify();
    },
  });

  if (loading1 || loading2 || !policyData) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Box>
          <Subtitle
            level="2"
            font={theme.fonts.mont}
            color={theme.colors.darkBlue}
            padding="0 0 .25rem"
          >
            Apólice
          </Subtitle>
          <Title
            level={1}
            color={theme.colors.brand}
            font={theme.fonts.mont}
            padding="0 0 2.5rem"
          >
            {}
          </Title>
        </Box>
        <Grid2 align="start">
          <Box padding="0 100px 30px 0">
            <DataTable data={policyData} policy="true"></DataTable>
          </Box>
          <Box>
            <Box
              margin="0 0 2.5rem 0"
              padding="1.5rem"
              backgroundColor={theme.colors.lightGrey}
            >
              <Subtitle
                level="2"
                font={theme.fonts.mont}
                color={theme.colors.darkBlue}
                padding="0 0 2.25rem"
              >
                Documentação da Apólice
              </Subtitle>
              <Button filled disabled={loadingPdf} onClick={() => (loadingPdf ? (() => {}) : handleRequestPDF())}>
                Descarregar PDF
              </Button>
            </Box>
            <Box
              margin="0 0 2.5rem 0"
              padding="1.5rem"
              backgroundColor={theme.colors.lightGrey}
            >
              <Subtitle
                level="2"
                font={theme.fonts.mont}
                color={theme.colors.darkBlue}
                padding="0 0 2.25rem"
              >
                Alterar a morada de correspondência
              </Subtitle>
              {changeAddress && (
                <form onSubmit={formik.handleSubmit}>
                  {Object.entries(initialValues).map(item => {
                    if (item[0] !== 'COUNTRY') {
                      return (
                        <FormInput
                          width="100%"
                          label={locale[item[0]]}
                          key={`key${item[0]}`}
                          formik={formik}
                          name={item[0]}
                          maxLength={50}
                        />
                      );
                    } else {
                      return false;
                    }
                  })}
                  <Button type="submit" filled caps>
                    Gravar Alterações
                  </Button>
                </form>
              )}
              {!changeAddress && (
                <Button
                  filled
                  caps
                  onClick={() => {
                    setChangeAddress(true);
                  }}
                >
                  Alterar
                </Button>
              )}
            </Box>
          </Box>
        </Grid2>
      </Wrapper>
    </Box>
  );
};

export default PolicyClient;
