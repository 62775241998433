import React, {useState, useEffect, useContext} from 'react';
import InvoiceBroker from './invoiceBroker';
import InvoiceClient from './invoiceClient';

import {AuthContext} from '../../contexts/AuthContext';

export const Invoice = ({props}) => {
  const [role, setRole] = useState(false);
  const {user} = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      if (!user.signInUserSession?.accessToken?.payload['cognito:groups']) {
        setRole('broker');
      } else {
        if (
          user.signInUserSession?.accessToken?.payload['cognito:groups'][0] ===
          'clients'
        ) {
          setRole('client');
        } else {
          setRole('broker');
        }
      }
    }
  }, [user]);

  if (!role) {
    return <></>;
  }

  if (role === 'broker') {
    return <InvoiceBroker props={props} />;
  }

  if (role === 'client') {
    return <InvoiceClient props={props} />;
  }
};
