import React from 'react';

import {
  Flex,
  Table,
  Button,
  formatDate,
  Pdf,
  Loader,
  theme,
  locale,
} from '@innovago/ui';

export const Others = ({data, loading, order, setOrder}) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Table
      loading={loading}
      columns={[
        {
          label: locale.title,
          property: 'title',
          sortable: true,
          format: item => item.title,
        },
        {
          label: locale.publishAt,
          property: 'publish_at',
          sortable: true,
          format: item => formatDate(item.publish_at),
        },
        {
          label: locale.type,
          property: 'type',
          sortable: true,
          format: item => {
            return item.type === 'information' ? 'Flash' : 'Newsletter';
          },
        },
        {
          label: locale.download,
          property: 'attachments',
          sortable: false,
          format: item => {
            return (
              <Flex align="center" justify="flex-start">
                {item.attachments.map((attachment, index) => {
                  return (
                    <Button
                      key={'pdf' + index}
                      type="link"
                      title={attachment.file_name}
                      color={theme.colors.black}
                      href={attachment.url}
                      padding="0 1rem 0 0"
                      width="auto"
                    >
                      <Pdf />
                    </Button>
                  );
                })}
              </Flex>
            );
          },
        },
      ]}
      data={data && data.content}
      sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
      onClickSort={sort =>
        setOrder({
          [sort.column]: sort.order,
        })
      }
    />
  );
};
