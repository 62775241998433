import React, {useState} from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import {DatePicker, formatDate, parseDate} from '../DatePicker';
import {Eye, EyeSlash, Pdf, Close, Calendar} from '../Icons';
import {Box} from '../Box';

const Label = styled.label`
  font-family: ${props => (props.labelFont ? props.labelFont : 'Lato')};
  font-size: 0.875rem;
  font-weight: ${props => (props.labelMedium ? '400' : '600')};
  line-height: ${props => (props.inline ? '18px' : 1)};
  color: ${theme.colors.darkBlue};
  padding: ${props => (props.flex ? '0 15px 4px 0' : '0 0 4px')};
  text-align: left;
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  width: ${props =>
    props.inline ? '30%' : props.width ? props.width : 'auto'};

  &:before {
    display: ${props => (props.required ? 'inline-block' : 'none')};
    content: '*';
    color: ${theme.colors.brand};
  }
`;

const LabelCheck = styled.label`
  font-family: ${props => (props.labelFont ? props.labelFont : 'Lato')};
  font-size: 0.875rem;
  font-weight: ${props => (props.labelMedium ? '400' : '600')};
  line-height: ${props => (props.inline ? '18px' : 1)};
  color: ${theme.colors.darkBlue};
  padding: ${props => (props.flex ? '0 15px 4px 0' : '0 0 4px')};
  text-align: left;
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  white-space: ${props => (props.inline ? 'normal' : 'nowrap')};
  width: ${props => (props.inline ? '100%' : 'auto')};

  &:before {
    display: ${props => (props.required ? 'inline-block' : 'none')};
    content: '*';
    color: ${theme.colors.brand};
  }
`;

const LabelFile = styled(Label)`
  line-height: 3rem;
  height: 3rem;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: ${props => (props.hasFile ? '0 1rem' : 0)};

  &::after {
    display: ${props => (props.hasFile ? 'none' : 'inline-block')};
    vertical-align: middle;
    content: 'anexar';
    letter-spacing: 1.25px;
    color: ${theme.colors.brand};
    text-transform: uppercase;
    background: url(/images/ic-file-upload.svg) no-repeat left center / auto;
    padding-left: 2rem;
  }

  span {
    font-family: Lato;
    font-size: 0.9125rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: ${theme.colors.black};
  }
`;

const Error = styled.span`
  padding: 0.5rem 0;
  display: block;
  width: 100%;
  font-family: Lato;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${theme.colors.error};
  flex-basis: 100%;
`;

export const Textarea = styled.textarea`
  font-family: Lato;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.colors.darkBlue};
  padding: 1rem;
  text-align: left;
  line-height: 1.5rem;
  margin: ${props => (props.margin ? props.margin : '0')};
  height: ${props => (props.height ? props.height : 'auto')};
  width: 100%;
  border-radius: 4px;
  border: ${props =>
    props.error
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.colors.darkBlue}`};
  background: transparent;
  outline: none;
  resize: none;

  ::placeholder {
    color: ${theme.colors.grey};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  &[disabled] {
    background-color: ${theme.colors.lightGrey};
    cursor: not-allowed;
  }
`;

const Field = styled.input`
  font-family: Lato;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.colors.black};
  padding: ${({isSmall}) => (isSmall ? '.5rem' : '0 1rem')};
  text-align: left;
  line-height: 3rem;
  height: ${({isSmall}) => (isSmall ? '1.5rem' : '3rem')};
  width: ${props =>
    props.inline ? '70%' : props.width ? props.width : 'auto'};
  border-radius: 4px;
  border: ${props =>
    props.error
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.colors.black}`};
  background: transparent;
  outline: none;
  position: relative;
  flex-grow: 1;
  text-overflow: ellipsis;

  ::placeholder {
    color: ${theme.colors.grey};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  &[disabled] {
    background-color: ${theme.colors.lightGrey};
    cursor: not-allowed;
  }
`;

const FieldFile = styled.input`
  opacity: 0;
  display: none;
  background: transparent;
  outline: none;

  ::placeholder {
    color: ${theme.colors.grey};
  }
`;

const GroupChecked = styled.div`
  display: ${props => (props.flex ? 'flex' : 'block')};
  flex-wrap: ${props => (props.flex ? 'wrap' : 'nowrap')};
  align-items: center;
  margin: ${props => (props.margin ? props.margin : '0')};

  input {
    opacity: ${props => (props.checkbox ? 0 : 1)};
    position: absolute;
    display: none;
  }

  label {
    display: ${props => (props.checkbox ? 'flex' : 'block')};
    margin: ${props => (props.checkbox ? '0' : '0 0 4px 0')};
    font-family: ${props => (props.labelFont ? props.labelFont : 'Lato')};
    font-size: 0.875rem;
    font-weight: ${props => (props.labelMedium ? '400' : '600')};
    position: relative;
    color: ${theme.colors.black};
    padding: 0;

    &:before {
      display: ${props => (props.checkbox ? 'inline-block' : 'none')};
      content: '';
      width: 18px;
      height: 18px;
      margin: 0 4px 0 0;
      border: 1px solid ${theme.colors.navy};
    }
  }

  input:checked + label {
    &:after {
      content: '';
      display: ${props => (props.checkbox ? 'inline-block' : 'none')};
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      background-color: ${theme.colors.navy};
    }
  }
`;

const GroupRadio = styled.div`
  display: ${props => (props.flex ? 'flex' : 'block')};
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => (props.margin ? props.margin : '0')};
  width: ${props => (props.flex ? '100%' : 'auto')};

  input {
    opacity: ${props => (props.checkbox ? 0 : 1)};
    position: absolute;
    display: none;
  }

  label {
    display: ${props => (props.checkbox ? 'flex' : 'block')};
    margin: ${props => (props.checkbox ? '0' : '0 0 4px 0')};
    font-family: ${props => (props.labelFont ? props.labelFont : 'Lato')};
    font-size: 0.875rem;
    font-weight: ${props => (props.labelMedium ? '400' : '600')};
    position: relative;
    padding: 0;
    color: ${theme.colors.black};

    &:before {
      display: ${props => (props.checkbox ? 'inline-block' : 'none')};
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin: 0 4px 0 0;
      border: 1px solid ${theme.colors.navy};
    }
  }

  input:checked + label {
    &:after {
      content: '';
      display: ${props => (props.checkbox ? 'inline-block' : 'none')};
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${theme.colors.navy};
    }
  }
`;

const Group = styled.div`
  display: ${props => (props.vis ? 'none' : props.flex ? 'flex' : 'block')};
  flex-wrap: wrap;
  align-items: center;
  margin: ${props => (props.margin ? props.margin : '0 0 1.5rem')};
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
`;

const GroupFile = styled.div`
  margin: 0 0 1.5rem;
  padding: 0 1rem;
  border-radius: 4px;
  border-color: ${props =>
    props.hasFile ? theme.colors.cloudyBlue : theme.colors.grey};
  border-style: ${props => (props.hasFile ? 'solid' : 'dotted')};
  border-width: ${props => (props.hasFile ? '5px' : '2px')};
  position: relative;
`;

const CalendarContainer = styled.span`
  position: absolute;
  top: ${props => props.iconPosition};
  right: 10px;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const DateModal = styled(DatePicker)`
  display: ${({active}) => (active ? 'block' : 'none')};
  position: absolute;
  z-index: 10;
`;

const TogglePassword = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
  display: flex;
  height: 14px;
  padding: 0 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
    outline: 0;
  }
`;

export const FormCheckbox = ({formik, name, label, ...rest}) => {
  const field = formik.getFieldProps(name);
  const {error, touched, value} = formik.getFieldMeta(name);
  return (
    <GroupChecked flex checkbox {...rest}>
      <Field
        id={name}
        name={name}
        type="checkbox"
        {...field}
        checked={value ? 'checked' : false}
        {...(rest.disabled && {disabled: true})}
      />
      <LabelCheck {...rest} htmlFor={name} inline>
        {label}
      </LabelCheck>
      {error && <Error>{error}</Error>}
    </GroupChecked>
  );
};

export const FormRadio = ({formik, name, value, label, options, ...rest}) => {
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);
  return (
    <GroupRadio checkbox {...rest}>
      {options.map(({label, value}, index) => {
        if (label.length > 0) {
          return (
            <Box margin="0 0 1rem" key={name + index}>
              <Field
                id={name + index}
                name={name}
                value={value}
                type="radio"
                {...(rest.disabled && {disabled: true})}
              />
              <Label {...rest} htmlFor={name + index} inline>
                {label}
              </Label>
              {error && touched && <Error>{error}</Error>}
            </Box>
          );
        }
      })}
    </GroupRadio>
  );
};

export const FormDate = ({disabled, formik, label, name, ...rest}) => {
  const [active, setActive] = useState(false);
  const [focused, setFocused] = useState(false);
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);

  const onContainerBlur = event => {
    if (
      !(
        event.relatedTarget &&
        event.relatedTarget.classList.contains('DayPicker-NavButton')
      )
    ) {
      setTimeout(() => {
        if (focused) return false;
        setActive(false);
      }, 500);
    }
  };

  const onDayFocus = () => {
    setFocused(true);
  };

  return (
    <Group {...rest}>
      {label && <Label {...rest}>{label}</Label>}
      <Field
        {...rest}
        {...field}
        error={error === undefined ? false : true}
        disabled={rest.disabled}
        onFocus={() => setActive(true)}
        onBlur={event => onContainerBlur(event)}
        readOnly={false}
        autoComplete="off"
      />
      <CalendarContainer iconPosition={label && !rest.inline ? '28px' : '14px'}>
        <Calendar />
      </CalendarContainer>
      {error && touched && <Error>{error}</Error>}
      <DateModal
        active={active}
        initial={parseDate(field.value)}
        onSelect={date => {
          formik.setFieldValue(name, formatDate(date));
          setFocused(false);
          setActive(false);
        }}
        onDayFocus={onDayFocus}
        setFocused={setFocused}
        name={name}
        {...(rest.disabled && {disabled: true})}
      />
    </Group>
  );
};

export const FormFile = ({formik, name, data, onChange, onRemove}) => {
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);
  const hasFile = data.size > 0 ? true : false;
  return (
    <GroupFile hasFile={hasFile}>
      <LabelFile hasFile={hasFile} htmlFor={name}>
        {hasFile && <Pdf />}
        {hasFile && (
          <span
            style={{
              width: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {data.filename}
          </span>
        )}
        {!hasFile && data.label}
        {hasFile && <span>{parseInt(data.size / 1024, 10)}kb</span>}
        {hasFile && <Close onClick={() => onRemove(data)} />}
      </LabelFile>
      {!hasFile && (
        <FieldFile name={name} id={name} type="file" onChange={onChange} />
      )}
      {error && touched && <Error>{error}</Error>}
    </GroupFile>
  );
};

export const FormInput = ({
  formik,
  label,
  placeholder,
  name,
  type,
  isSmall,
  onChange,
  ...rest
}) => {
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);
  return (
    <Group {...rest}>
      {label && <Label {...rest}>{label}</Label>}
      <Field
        {...rest}
        {...field}
        type={type}
        placeholder={placeholder ? placeholder : ''}
        error={error === undefined ? false : true}
        {...(rest.disabled && {disabled: true})}
        isSmall={isSmall}
        onChange={
          onChange
            ? onChange
            : val => formik.setFieldValue(name, val.target.value)
        }
      />
      {error && touched && <Error>{error}</Error>}
    </Group>
  );
};

export const FormInputHidden = ({formik, name, ...rest}) => {
  const field = formik.getFieldProps(name);
  return (
    <Group {...rest}>
      <Field {...rest} {...field} type="hidden" />
    </Group>
  );
};

export const FormTextarea = ({formik, label, name, isSmall, ...rest}) => {
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);
  return (
    <Group {...rest}>
      {label && <Label {...rest}>{label}</Label>}
      <Textarea
        {...field}
        error={error === undefined ? false : true}
        disabled={rest.disabled}
        isSmall={isSmall}
      />
      {error && touched && <Error>{error}</Error>}
    </Group>
  );
};

export const FormPassword = ({formik, label, name, ...rest}) => {
  const [visible, setVisible] = useState(false);
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);
  return (
    <Group>
      {label && <Label {...rest}>{label}</Label>}
      <Group margin="0">
        <Field
          {...field}
          {...rest}
          error={error === undefined ? false : true}
          type={visible ? 'text' : 'password'}
          disabled={rest.disabled}
        />
        <TogglePassword
          onClick={e => {
            e.preventDefault();
            setVisible(!visible);
          }}
        >
          {visible ? <EyeSlash /> : <Eye />}
        </TogglePassword>
      </Group>
      {error && touched && <Error>{error}</Error>}
    </Group>
  );
};

export const FormSelect = ({
  formik,
  label,
  name,
  options = [],
  allowEmpty,
  placeholder,
  onChange,
  ...rest
}) => {
  const field = formik.getFieldProps(name);
  const {error, touched} = formik.getFieldMeta(name);

  return (
    <Group {...rest}>
      {label && <Label {...rest}>{label}</Label>}
      <Field
        {...rest}
        {...field}
        as="select"
        error={error === undefined ? false : true}
        {...(rest.disabled && {disabled: true})}
        onChange={
          onChange
            ? onChange
            : val => formik.setFieldValue(name, val.target.value)
        }
      >
        {allowEmpty && <option>{placeholder}</option>}
        {options.map(({label, value}) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Field>
      {error && touched && <Error>{error}</Error>}
    </Group>
  );
};
