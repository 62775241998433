import React, {useState, useEffect} from 'react';
import Auth from '@aws-amplify/auth';

import {Loader} from '@innovago/ui';

const AUTHENTICATOR_AUTHSTATE = 'innovago-authState';

export const AuthContext = React.createContext({
  isSignedIn: () => false,
  login: () => {},
  logout: () => {},
  forgotPassword: () => {},
  forgotPasswordSubmit: () => {},
  register: () => {},
  user: null,
});

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState('signIn');
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    async function getCurrentUser() {
      try {
        const user = await Auth.currentAuthenticatedUser({bypassCache: true});
        if (!user) {
          clearUser();
          setAuthChecked(true);
        } else {
          const refreshToken = (await Auth.currentSession()).getRefreshToken();
          await user.refreshSession(refreshToken, (err, session) => {
            if (err) {
              return err;
            }
            const token = session.idToken.getJwtToken();

            changeAuthState('signedIn');
            setToken(token).then(() => {
              setUser(user);
              setAuthChecked(true);
            });
          });
        }
      } catch (err) {
        let cachedAuthState = null;
        try {
          cachedAuthState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
        } catch (e) {
          console.log('Failed to get the auth state from local storage', e);
        }

        if (cachedAuthState === 'signedIn') {
          await logout();
        } else {
          changeAuthState('signIn');
        }
        setAuthChecked(true);
      }
    }

    if (!authChecked) {
      getCurrentUser();
    }
    /* eslint-disable-next-line */
  }, []);

  function isSignedIn() {
    return authState === 'signedIn';
  }

  function changeAuthState(state) {
    setAuthState(state);
    localStorage.setItem(AUTHENTICATOR_AUTHSTATE, state);
  }

  async function login(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      if (
        user.signInUserSession &&
        user.signInUserSession.accessToken.payload['cognito:groups'] &&
        user.signInUserSession.accessToken.payload['cognito:groups'][0] ===
          'admins'
      ) {
        return false;
      } else {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(
            user, // the Cognito User Object
            password // the new password
          );
          setToken(user);
          setAuthChecked(true);
          setUser(user);
          return user;
        } else {
          setToken(user);
          setAuthChecked(true);
          setUser(user);
          return user;
        }
      }
    } catch (err) {
      throw new Error('Error with login: ' + err);
    }
  }

  async function register(username, email, password, inviteId) {
    let response = {
      status: false,
      data: {},
    };
    await Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
      clientMetadata: {inviteId},
      validationData: [],
    })
      .then(data => {
        response.status = true;
        response.data = data;
      })
      .catch(err => {
        console.log(err);
        response.status = false;
        response.data = err;
      });

    return response;
  }

  async function setToken(user) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    localStorage.setItem('token', token);
    changeAuthState('signedIn');
  }

  async function logout() {
    await Auth.signOut();
    clearUser();
  }

  async function forgotPassword(email) {
    return await Auth.forgotPassword(email);
  }

  async function forgotPasswordSubmit(email, code, password) {
    return await Auth.forgotPasswordSubmit(email, code, password);
  }

  function clearUser() {
    localStorage.setItem('token', '');
    changeAuthState('signIn');
    setUser(null);
  }

  if (!authChecked) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        login,
        logout,
        forgotPassword,
        forgotPasswordSubmit,
        register,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
