import React from 'react';
import {useQuery} from '@apollo/react-hooks';

import {
  Flex,
  Box,
  Text,
  List,
  Heading,
  theme,
  Button,
  NotificationContainer,
  useNotification,
  Loader,
} from '@innovago/ui';

import {setting} from '../../graphql/queries';

export const RegisterIntro = () => {
  const [notifications, notify] = useNotification();

  const {data, loading} = useQuery(setting, {
    onError: () => notify(),
  });

  if (loading) {
    return <Loader />;
  }

  const pdf = JSON.parse(
    data.setting.filter(item => item.name === 'nominationPdf')[0].value
  ).url;

  return (
    <div>
      <NotificationContainer {...notifications} />
      <Heading
        level={1}
        color={theme.colors.brand}
        font={theme.fonts.mont}
        weight="500"
        size="2rem"
        padding="0 0 2rem"
      >
        Junte-se à nossa equipa!
      </Heading>
      <Text
        font={theme.fonts.lato}
        padding="0 0 1rem"
        color={theme.colors.black}
      >
        O projeto Innova|GO é a solução da Innovarisk que lhe permite de forma
        simples e fácil aceder aos nossos produtos especializados para nichos de
        mercado.  
      </Text>
      <Text
        font={theme.fonts.lato}
        padding="0 0 1.5rem"
        color={theme.colors.black}
      >
        Para trabalhar connosco e ter acesso à plataforma, precisa apenas de:
      </Text>
      <List
        font={theme.fonts.lato}
        padding="0"
        color={theme.colors.black}
        items={[
          'Ser mediador ou corretor de seguros certificado pela ASF',
          'Fazer download e preencher a Ficha de nomeação (passo 2)',
          'Preencher o formulário ao lado com os seus dados e da sua empresa.',
          'Anexar todos os documentos.',
        ]}
      />
      <Heading
        level={2}
        order="1"
        color={theme.colors.darkBlue}
        font={theme.fonts.mont}
        size="1.5rem"
        padding="1rem 0"
      >
        Documentos necessários
      </Heading>
      <Text
        font={theme.fonts.lato}
        padding="0 0 1rem"
        color={theme.colors.black}
      >
        Para que possamos proceder à sua nomeação precisamos que anexe no
        formulário ao lado os seguintes documentos:
      </Text>
      <List
        font={theme.fonts.lato}
        padding="0"
        color={theme.colors.black}
        items={[
          'Ficheiro de nomeação',
          'Apólice Responsabilidade Civil',
          'Relatório e contas',
          'Certificado ASF de Mediador',
          'Comprovativo de IBAN/NIB',
        ]}
      />
      <Heading
        level={2}
        order="2"
        color={theme.colors.darkBlue}
        font={theme.fonts.mont}
        size="1.5rem"
        padding="1rem 0"
      >
        Preencha a Ficha de Nomeação
      </Heading>
      <Text
        font={theme.fonts.lato}
        padding="0 0 1rem"
        color={theme.colors.black}
      >
        Faça o download da ficha de nomeação, preencha e anexe juntamente com os
        documentos do passo anterior no formulário ao lado.
      </Text>
      <Flex align="center" justify="flex-start" padding="0 0 4rem 3.5rem">
        <img
          src="./images/ic-file-download.svg"
          alt="Download Ficha de nomeação"
        />
        <Button
          href={pdf}
          target="_blank"
          width="auto"
          color={theme.colors.brand}
          caps
        >
          Download Ficha de nomeação
        </Button>
      </Flex>
      <Box padding="2rem" backgroundColor={theme.colors.lightGrey} width="80%">
        <Text font={theme.fonts.lato} color={theme.colors.black} weight="600">
          Tem alguma dúvida?
        </Text>
        <Text font={theme.fonts.lato} color={theme.colors.black}>
          Caso tenha alguma dúvida ou questão, por favor contacte: +351 211 351
          290
        </Text>
      </Box>
    </div>
  );
};
