import React from 'react';
import {useQuery} from '@apollo/react-hooks';

import {
  Box,
  Flex,
  Wrapper,
  Heading,
  Text,
  Image,
  Button,
  Linkedin,
  Facebook,
  Youtube,
  Loader,
  theme,
  NotificationContainer,
  useNotification,
} from '@innovago/ui';

import {setting} from '../../graphql/queries';

const FooterBroker = () => {
  const [notifications, notify] = useNotification();

  const {data, loading} = useQuery(setting, {
    fetchPolicy: 'cache-and-network',
    onError: () => {
      notify();
    },
  });

  if (loading || !data) {
    return <Loader />;
  }

  const fbLink = data.setting.find(item => item.name === 'facebookLink');
  const liLink = data.setting.find(item => item.name === 'linkedinLink');
  const ytLink = data.setting.find(item => item.name === 'youtubeLink');

  return (
    <div style={{flex: 0, width: '100%'}}>
      <NotificationContainer {...notifications} />
      <Box padding="1.5rem 0" backgroundColor={theme.colors.darkGrey}>
        <Wrapper>
          <Flex justify="space-between" align="center">
            <Flex justify="space-between" align="flex-end">
              <Button link href="https://innovarisk.pt" padding="0">
                <Image src="/images/innovarisk-logo-footer.png" />
              </Button>
              <Image src="/images/hiscox.png" margin="0 0 0 2rem" />
              <Image src="/images/lloyds.png" margin="0 0 0 2rem" />
            </Flex>
            <Box>
              <Heading
                level="3"
                caps
                color={theme.colors.white}
                font={theme.fonts.lato}
                size=".875rem"
                letter="2.25px"
              >
                contactos
              </Heading>
              <Box
                backgroundColor={theme.colors.white}
                height="2px"
                width="100%"
                margin=".33rem 0"
              ></Box>
              <Box>
                <Text
                  color={theme.colors.white}
                  line="1.71"
                  letter="0.9px"
                  size=".875rem"
                  padding="0 1.5rem 0 0"
                  display="inline-block"
                >
                  +351 211 351 290
                </Text>
                <Button
                  link
                  href="mailto:innovago@innovarisk.pt"
                  color={theme.colors.white}
                  size=".875rem"
                  display="inline-block"
                  underline
                >
                  innovago@innovarisk.pt
                </Button>
              </Box>
            </Box>
            <Flex justify="flex-end" align="center">
              <Button target="_blank" link href={liLink.value}>
                <Linkedin />
              </Button>
              <Button target="_blank" link href={fbLink.value}>
                <Facebook />
              </Button>
              <Button target="_blank" link href={ytLink.value}>
                <Youtube color={theme.colors.white} />
              </Button>
            </Flex>
          </Flex>
        </Wrapper>
        <Box
          height="1px"
          margin="1rem 0 .875rem"
          backgroundColor={theme.colors.silver}
        ></Box>
        <Box>
          <Wrapper>
            <Flex align="center" justify="flex-start">
              <Button
                link
                href="https://innovarisk.pt/politica-de-reclamacoes/"
                font={theme.fonts.lato}
                color={theme.colors.white}
                size=".75rem"
                padding="0 .5rem 0 0"
                target="_blank"
              >
                Política de Reclamações
              </Button>
              <Text
                font={theme.fonts.lato}
                color={theme.colors.white}
                size=".75rem"
                padding="0 .5rem 0 0"
              >
                -
              </Text>
              <Button
                link
                href="https://innovarisk.pt/cookies-privacidade/"
                font={theme.fonts.lato}
                color={theme.colors.white}
                size=".75rem"
                padding="0 .5rem 0 0"
                target="_blank"
              >
                Cookies & Privacidade
              </Button>
              <Text
                font={theme.fonts.lato}
                color={theme.colors.white}
                size=".75rem"
                padding="0 .5rem 0 0"
              >
                -
              </Text>
              <Button
                link
                href="https://innovarisk.pt/termos-condicoes/"
                font={theme.fonts.lato}
                color={theme.colors.white}
                size=".75rem"
                padding="0 .5rem 0 0"
                target="_blank"
              >
                Termos & Condições
              </Button>
            </Flex>
          </Wrapper>
        </Box>
      </Box>
    </div>
  );
};

export default FooterBroker;
