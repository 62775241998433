import React from 'react';
import styled from 'styled-components';
import {Box} from '../Box';

const FixedBox = styled.div`
  fill: rgba(0, 0, 0, 0.2);
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = () => (
  <FixedBox>
    <svg
      viewBox="0 0 18 18"
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9h2a7 7 0 1 1 7 7v2a9 9 0 1 0-9-9z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 9 9"
          to="360 9 9"
          dur="0.7s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </FixedBox>
);

export const LoaderInline = () => (
  <Box>
    <svg
      viewBox="0 0 18 18"
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9h2a7 7 0 1 1 7 7v2a9 9 0 1 0-9-9z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 9 9"
          to="360 9 9"
          dur="0.7s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </Box>
);
