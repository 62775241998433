const theme = {
  breakpoints: {
    small: '640px',
    medium: '960px',
    large: '1200px',
  },
  colors: {
    brand: '#e86137',
    brandFaded: 'rgba(232, 97, 55, 0.4)',
    melon: '#ee8868',
    rustyRedTwo: '#af300a',
    black: 'rgba(0,0,0,.87)',
    white: '#fff',
    mango: '#f5a62a',
    darkBlue: '#20365f',
    dirtyBlue: '#3787a1',
    cloudyBlue: '#d2d7df',
    cloudyBlue2: '#c2d8db',
    lightGrey: '#f4f5f7',
    darkGrey: '#313131',
    blueyGrey: '#86b1b8',
    grey: '#909baf',
    silver: '#d7dbde',
    yellow: '#f5a62a',
    error: '#d71616',
    success: '#509753',
    info: '#62bbff',
    navy: '#03173d',
    lightpink: '#FEF2DF',
  },
  fonts: {
    mont: 'Montserrat',
    lato: 'Lato',
  },
};

export default theme;
