import React, {useContext} from 'react';
import {useQuery} from '@apollo/react-hooks';
import moment from 'moment';

import {
  Box,
  Table,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Button,
  Badge,
  theme,
  locale,
  Loader,
  formatDate,
} from '@innovago/ui';

import {getCertificates, getUser} from '../../graphql/queries';
import {AuthContext} from '../../contexts/AuthContext';

export const Certificates = () => {
  const {user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();

  const today = moment().format('YYYY-MM-DD');
  const currentHour = moment().subtract(2, 'hour').format('HH:mm');

  const where = {
    _and: [
      {
        status: {_eq: 'published'},
        tutorial: {_eq: false},
      },
      {
        _or: [
          {
            date: {_lt: today},
          },
          {
            _and: [{date: {_eq: today}}, {hour: {_lt: currentHour}}],
          },
        ],
      },
    ],
  };

  const queryVars = {
    where: where,
    sub: user && user.username,
  };

  const {data, loading} = useQuery(getCertificates, {
    fetchPolicy: 'no-cache',
    variables: queryVars,
    skip: !user,
    onError: () => notify(),
  });

  const {loading: loading2} = useQuery(getUser, {
    skip: !(user && user.username),
    fetchPolicy: 'no-cache',
    variables: {
      sub: user.username,
    },
    onError: () => notify(),
  });

  if (loading || loading2) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      <Wrapper>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          weight="500"
          caps
          padding="0 0 .25rem"
        >
          Formação
        </Heading>

        <Heading
          level="3"
          font={theme.fonts.mont}
          size="1.5rem"
          color={theme.colors.darkBlue}
          weight="500"
          padding="2.5rem 0 1.5rem"
        >
          Certificações
        </Heading>

        <Table
          loading={loading}
          columns={[
            {
              label: locale.title,
              property: 'name',
              sortable: false,
              format: item => item.name,
            },
            {
              label: locale.teacher,
              property: 'teacher',
              sortable: false,
              format: item => item.teacher,
            },
            {
              label: locale.date,
              property: 'date',
              sortable: false,
              format: item => formatDate(item.date),
            },
            {
              label: 'Certificado',
              property: 'presences.status',
              sortable: false,
              format: item => {
                return item.presences[0] &&
                  item.presences[0].status === 'attended' ? (
                  item.presences[0].certificate_url ? (
                    <Button
                      link={true}
                      underline={true}
                      href={item.presences[0].certificate_url}
                      target="_blank"
                    >
                      Download
                    </Button>
                  ) : (
                    <Badge type="scheduled" label="Ainda não disponível" />
                  )
                ) : (
                  <Badge type="rejected" label="Não participou" />
                );
              },
            },
          ]}
          data={data && data.trainings}
        />
      </Wrapper>
    </Box>
  );
};
