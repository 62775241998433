import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {navigate} from 'hookrouter';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  Grid2,
  Wrapper,
  Heading,
  Subtitle,
  Title,
  Text,
  Button,
  DataTable,
  Download,
  SendByEmail,
  FormFile,
  Loader,
  NotificationContainer,
  useNotification,
  locale,
  theme,
} from '@innovago/ui';

import {getPolicy} from '../../graphql/queries';
import {
  sendPolicyEmail as sendPolicyEmailMutation,
  updatePolicy as updatePolicyMutation,
  sendDocuments as sendDocumentsMutation,
} from '../../graphql/mutations';

import {getData, getBadge} from './helper';

const validationSchema = Yup.object().shape({
  document: Yup.string().required('Obrigatório'),
});

const CotationBox = styled(Box)`
  border-left: 2px solid #c7cdd7;
  padding: 0 0 0 1rem;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    border-left: 0;
    border-top: 2px solid #c7cdd7;
    padding: 2rem 0 0 0;
  }
`;

export const Cotation = ({id}) => {
  const [notifications, notify] = useNotification();
  const [files, setFiles] = useState([
    {
      file: null,
      size: 0,
      type: null,
      base64: null,
      fieldname: 'document1',
    },
  ]);

  const [sendPolicyEmail] = useMutation(sendPolicyEmailMutation, {
    onCompleted: data => {
      if (data.sendPolicyEmail) {
        notify('O email foi enviado com sucesso', 'success');
      } else {
        notify();
      }
    },
    onError: error => {
      console.log(error);
      notify();
    },
  });

  const {data: policyData, loading} = useQuery(getPolicy, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id.id,
    },
    onError: () => notify(),
  });

  const [updatePolicy] = useMutation(updatePolicyMutation, {
    onCompleted: data => {
      if (data.updatePolicy.revisions[0].status === 'deleted') {
        navigate('/quotations');
      }
    },
    onError: error => {
      notify();
    },
  });

  const [sendDocuments] = useMutation(sendDocumentsMutation, {
    onCompleted: data => {
      if (data.sendDocuments.success) {
        notify('O documento foi enviado com sucesso', 'success');
      } else {
        notify('Ocorreu um erro, por favor tente mais tarde', 'error');
      }
    },
    onError: error => {
      notify('Ocorreu um erro, por favor tente mais tarde', 'error');
    },
  });

  const deleteCotationHandler = () => {
    updatePolicy({
      variables: {
        args: {
          answers: policyData.policies_view[0].answers,
          clientId: 1,
          id: policyData.policies_view[0].policy_id,
          status: 'deleted',
          ...(policyData.policies_view[0].product && {productId: policyData.policies_view[0].product.id}),
        },
      },
    });
  };

  const onFileChange = e => {
    const uploadedFile = e.target.files[0];
    const fieldname = e.currentTarget.name;
    const reader = new FileReader();

    if (e.target.files[0].size > 5000000) {
      notify('O tamanho máximo do ficheiro são 5MB', 'error');
    } else {
      if (uploadedFile) {
        reader.readAsDataURL(uploadedFile);
        reader.addEventListener(
          'load',
          () => {
            const readedFile = reader.result;
            formik.setFieldValue('document', readedFile);
            const newFiles = files.filter(item => item.fieldname !== fieldname);

            setFiles([
              ...newFiles,
              {
                base64: readedFile.split(',')[1],
                file: uploadedFile.name,
                size: uploadedFile.size,
                type: uploadedFile.type,
                fieldname: fieldname,
              },
            ]);
          },
          false
        );
      }
    }
  };

  const onFileRemove = data => {
    const newFiles = files.filter(item => item.fieldname !== data.fieldname);

    if (newFiles.length === 0) {
      setFiles([
        {
          file: null,
          size: 0,
          type: null,
          base64: null,
          fieldname: 'document1',
        },
      ]);
    } else {
      setFiles(newFiles);
    }
  };

  const addAnotherFile = e => {
    if (files[files.length - 1].base64) {
      e.preventDefault();
      const index = files.length;
      const newFiles = files;
      setFiles([
        ...newFiles,
        {
          file: null,
          size: 0,
          type: null,
          base64: null,
          fieldname: `document${index + 1}`,
        },
      ]);
    }
  };

  const handleSubmit = values => {
    const documents = files.map(file => {
      return {document: file.base64, filename: file.file};
    });
    sendDocuments({
      variables: {
        documents: documents,
        policyNo: String(policyData.policies_view[0].policy_no),
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      document: null,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  if (loading) {
    return <Loader />;
  }

  const questions = policyData.policies_view[0].product?.questions;
  const answers = policyData.policies_view[0].answers;
  const status = policyData.policies_view[0].policy_status;
  const allowSubmission =
    policyData.policies_view[0].user?.broker?.allow_submission;

  const tableData = getData(questions, answers);
  const badge = getBadge(status);

  const sendByEmailHandler = () => {
    sendPolicyEmail({
      variables: {
        policyId: policyData.policies_view[0].policy_id,
      },
    });
  };

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Box>
          <Subtitle
            level="2"
            font={theme.fonts.mont}
            color={theme.colors.darkBlue}
            padding="0 0 .25rem"
          >
            Cotação
          </Subtitle>
          <Title
            level={1}
            color={theme.colors.brand}
            font={theme.fonts.mont}
            padding="0 0 2.5rem"
          >
            {policyData.policies_view[0].product?.title || 'Produto indisponível'}
          </Title>
          <Heading
            level={3}
            color={theme.colors.darkBlue}
            size="1.5rem"
            font={theme.fonts.mont}
            padding="0 0 2.5rem"
          >
            Confirmar os dados para emitir a proposta
          </Heading>
        </Box>
        <Grid2 align="start">
          <Box padding="0 100px 30px 0">
            <DataTable data={tableData} policy></DataTable>
            <Flex
              align="center"
              justify="space-between"
              backgroundColor={theme.colors.lightpink}
              margin="30px 0 0 0"
              padding="1rem .5rem"
            >
              <Heading
                level="3"
                size="1.125rem"
                font={theme.fonts.mont}
                weight="500"
                caps
                color="{theme.colors.darkBlue"
              >
                Prémio total
              </Heading>
              <Text
                level="3"
                size="1.125rem"
                font={theme.fonts.mont}
                weight="500"
                color="{theme.colors.darkBlue"
              >
                {(
                  parseFloat(policyData.policies_view[0].annual_premium) +
                  parseFloat(policyData.policies_view[0].annual_tax)
                ).toFixed(2)}
                €
              </Text>
            </Flex>
          </Box>

          <CotationBox>
            <Box margin="0 0 2.5rem 0">
              <Heading
                level={4}
                color={theme.colors.darkBlue}
                size="0.875rem"
                weight="500"
                font={theme.fonts.lato}
                padding="0 0 1rem"
                caps="uppercase"
                letter="2.25px"
              >
                {locale.status}
              </Heading>
              {badge}
            </Box>
            <Box margin="0 0 2.5rem 0">
              <Heading
                level={4}
                color={theme.colors.darkBlue}
                size="0.875rem"
                weight="500"
                font={theme.fonts.lato}
                padding="0 0 1rem"
                caps="uppercase"
                letter="2.25px"
              >
                {locale.messages}
              </Heading>
              <Text>{locale.cotation.message[status]}</Text>
            </Box>
            {status === 'submitted' && policyData.policies_view[0].attachments[0]?.url && (
              <Box margin="0 0 2.5rem 0">
                <Heading
                  level={4}
                  color={theme.colors.darkBlue}
                  size="0.875rem"
                  weight="500"
                  font={theme.fonts.lato}
                  padding="0 0 1rem"
                  caps="uppercase"
                  letter="2.25px"
                >
                  DOWNLOAD DA COTAÇÃO
                </Heading>
                <Flex align="center" justify="flex-start">
                  <Button
                    link
                    filled
                    target="_blank"
                    href={policyData.policies_view[0].attachments[0].url}
                  >
                    <Flex>
                      <Download height="14px" color={theme.colors.brand} />
                      <Text
                        transform="uppercase"
                        color={theme.colors.brand}
                        padding="0 0 0 1rem"
                        size=".875rem"
                      >
                        Download
                      </Text>
                    </Flex>
                  </Button>
                  <Button
                    margin="0 0 0 1rem"
                    tranparent
                    color={theme.colors.brand}
                    width="auto"
                    onClick={() => sendByEmailHandler()}
                  >
                    <Flex>
                      <SendByEmail height="14px" color={theme.colors.brand} />
                      <Text
                        transform="uppercase"
                        color={theme.colors.brand}
                        padding="0 0 0 1rem"
                        size=".875rem"
                      >
                        Enviar por email
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </Box>
            )}

            {status === 'submitted' && allowSubmission && (
              <Box
                margin="0 0 2.5rem 0"
                padding="1.5rem"
                backgroundColor={theme.colors.lightGrey}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Heading
                    level={4}
                    color={theme.colors.darkBlue}
                    size="0.875rem"
                    weight="500"
                    font={theme.fonts.lato}
                    padding="0 0 1rem"
                    caps="uppercase"
                    letter="2.25px"
                  >
                    CONVERTER COTAÇÃO EM APÓLICE
                  </Heading>
                  <Text>
                    Para finalizar a colocação da apólice deve inserir a
                    Proposta/Cotação assinada pelo Cliente (assinatura deve ser
                    feita na página 4)
                  </Text>
                  {files.map((item, index) => {
                    return (
                      <FormFile
                        key={`file${index}`}
                        formik={formik}
                        name={item.fieldname}
                        data={item}
                        size={item.size}
                        onChange={onFileChange}
                        onRemove={e => onFileRemove(e)}
                        type="file"
                      />
                    );
                  })}

                  <Flex justify="space-between" align="center">
                    <Button
                      type="link"
                      outline
                      caps
                      onClick={e => addAnotherFile(e)}
                    >
                      Carregar mais um ficheiro
                    </Button>
                    <Button type="submit" filled caps>
                      Submeter
                    </Button>
                  </Flex>
                </form>
              </Box>
            )}

            {(status === 'submitted' ||
              status === 'draft' ||
              status === 'expired' ||
              status === 'pending') && (
              <Box
                margin="0 0 2.5rem 0"
                padding="1.5rem"
                backgroundColor={theme.colors.lightGrey}
              >
                <Heading
                  level={4}
                  color={theme.colors.darkBlue}
                  size="0.875rem"
                  weight="500"
                  font={theme.fonts.lato}
                  padding="0 0 1rem"
                  caps="uppercase"
                  letter="2.25px"
                >
                  VOLTAR A SIMULAR
                </Heading>
                {policyData.policies_view[0].product && <Button
                  link
                  outline
                  caps
                  line="34px"
                  href={`/simuladores/${policyData.policies_view[0].product.slug}/${policyData.policies_view[0].policy_id}`}
                >
                  Simular
                </Button>}
              </Box>
            )}

            {(status === 'submitted' ||
              status === 'draft' ||
              status === 'expired' ||
              status === 'pending') && (
              <Box
                margin="0 0 2.5rem 0"
                padding="1.5rem"
                backgroundColor={theme.colors.lightGrey}
              >
                <Heading
                  level={4}
                  color={theme.colors.darkBlue}
                  size="0.875rem"
                  weight="500"
                  font={theme.fonts.lato}
                  padding="0 0 1rem"
                  caps="uppercase"
                  letter="2.25px"
                >
                  APAGAR SIMULAÇÃO
                </Heading>
                <Button
                  outline
                  color={theme.colors.brand}
                  width="auto"
                  onClick={() => deleteCotationHandler()}
                >
                  Apagar Simulação
                </Button>
              </Box>
            )}
          </CotationBox>
        </Grid2>
      </Wrapper>
    </Box>
  );
};
