import React, {useContext, useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {EditBrokerProfile} from './editBrokerProfile';
import {CreateInvite} from './createInvite';

import {
  Box,
  Flex,
  Table,
  Button,
  Heading,
  Text,
  Loader,
  Wrapper,
  locale,
  formatDate,
  Pdf,
  theme,
  NotificationContainer,
  useNotification,
  ModelWithOverlay,
  Grid3,
  Badge,
} from '@innovago/ui';

import {AuthContext} from '../../contexts/AuthContext';

import {getBrokerDetails} from '../../graphql/queries';

import {
  CreateInvite as CreateInviteMutation,
  UpdateBrokerById as UpdateBrokerMutation,
  DeactivateUser as DeactivateUserMutation,
  ActivateUser as ActivateUserMutation,
} from '../../graphql/mutations';

export const ProfileBroker = props => {
  const {user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [modalVisibility, setModayVisibility] = useState(false);
  const [invitePopin, setViewInvitePopin] = useState(false);
  const [editProfile, setViewEditProfile] = useState(false);

  const [createInviteStatus] = useMutation(CreateInviteMutation, {
    onCompleted: () => {
      notify(
        'O broker irá receber por email o convite para se inscrever',
        'success'
      );
      setModayVisibility(false);
      setViewInvitePopin(false);
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBrokerDetails'],
  });

  const [updateBrokerProfileStatus] = useMutation(UpdateBrokerMutation, {
    onCompleted: () => {
      notify('O broker foi actualizado', 'success');
      setModayVisibility(false);
      setViewEditProfile(false);
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBrokerDetails'],
  });

  const [inactivationStatus] = useMutation(DeactivateUserMutation, {
    onCompleted: () => {
      notify('O broker foi desactivado', 'success');
      setModayVisibility(false);
      setViewInvitePopin(false);
    },
    onError: () => {
      notify(
        'Ocorreu um erro. Por favor contacte a Innovarisk ou tente novamente mais tarde.',
        'error'
      );
    },
    refetchQueries: ['getBrokerDetails'],
  });

  const [activationStatus] = useMutation(ActivateUserMutation, {
    onCompleted: () => {
      notify('O broker foi activado', 'success');
      setModayVisibility(false);
      setViewInvitePopin(false);
    },
    onError: () => {
      notify(
        'Ocorreu um erro. Por favor contacte a Innovarisk ou tente novamente mais tarde.',
        'error'
      );
    },
    refetchQueries: ['getBrokerDetails'],
  });

  const {data, loading} = useQuery(getBrokerDetails, {
    skip: !(user && user.username),
    fetchPolicy: 'no-cache',
    variables: {
      sub: user && user.username,
    },
    onError: () => {
      notify();
    },
  });

  const closeModal = () => {
    setModayVisibility(false);
  };

  const handleActivation = id => {
    activationStatus({
      variables: {
        id: id,
      },
    });
  };

  const handleInactivation = id => {
    inactivationStatus({
      variables: {
        id: id,
      },
    });
  };

  if (loading || !user) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <Wrapper>
        <NotificationContainer {...notifications} />

        {modalVisibility && (
          <ModelWithOverlay top="50%" onClose={closeModal}>
            {invitePopin && (
              <CreateInvite
                broker={data.broker[0]}
                createInviteStatus={createInviteStatus}
              ></CreateInvite>
            )}

            {editProfile && (
              <EditBrokerProfile
                updateBrokerProfileStatus={updateBrokerProfileStatus}
                broker={data.broker[0]}
              ></EditBrokerProfile>
            )}
          </ModelWithOverlay>
        )}

        <Box>
          <Heading
            level="2"
            font={theme.fonts.mont}
            size=".875rem"
            color={theme.colors.darkBlue}
            padding="0 0 .5rem"
            caps
          >
            perfil empresa
          </Heading>
          <Heading
            level="1"
            font={theme.fonts.mont}
            weight="500"
            size="2rem"
            color={theme.colors.brand}
            letter=".24px"
            padding="0 0 2.5rem"
          >
            {data.broker[0].name}
          </Heading>
        </Box>

        <Box padding="0 0 3rem">
          <Heading
            level="3"
            color={theme.colors.darkBlue}
            size="1.5rem"
            padding="0 0 1.5rem"
            weight="500"
          >
            {locale.companyIdentification}
          </Heading>
          <Grid3 align="flex-start">
            <Box>
              <Heading
                level="4"
                color={theme.colors.darkBlue}
                size=".875rem"
                padding="0 0 .25rem"
                weight="500"
                letter="2.25px"
                line="1.14"
                font={theme.fonts.lato}
                caps
              >
                {locale.companyName}
              </Heading>
              <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
                {data.broker[0].name}
              </Text>
              <Heading
                level="4"
                color={theme.colors.darkBlue}
                size=".875rem"
                padding="0 0 .25rem"
                weight="500"
                letter="2.25px"
                line="1.14"
                font={theme.fonts.lato}
                caps
              >
                {locale.vat}
              </Heading>
              <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
                {data.broker[0].vat}
              </Text>
            </Box>
            <Box>
              <Heading
                level="4"
                color={theme.colors.darkBlue}
                size=".875rem"
                padding="0 0 .25rem"
                weight="500"
                letter="2.25px"
                line="1.14"
                font={theme.fonts.lato}
                caps
              >
                {locale.phone}
              </Heading>
              <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
                {data.broker[0].phone}
              </Text>
              <Heading
                level="4"
                color={theme.colors.darkBlue}
                size=".875rem"
                padding="0 0 .25rem"
                weight="500"
                letter="2.25px"
                line="1.14"
                font={theme.fonts.lato}
                caps
              >
                {locale.address}
              </Heading>
              <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
                {data.broker[0].correspondance_address_line_1}
                <br />
                {data.broker[0].correspondance_address_line_2}
                <br />
                {data.broker[0].correspondance_address_line_3}
                <br />
                {data.broker[0].correspondance_address_line_4}
              </Text>
            </Box>
            <Box>
              <Heading
                level="4"
                color={theme.colors.darkBlue}
                size=".875rem"
                padding="0 0 .25rem"
                weight="500"
                letter="2.25px"
                line="1.14"
                font={theme.fonts.lato}
                caps
              >
                {locale.documents}
              </Heading>
              {data.broker[0].f1[0] && (
                <Box padding="0 0 1rem 0">
                  <Button
                    href={data.broker[0].f1[0].url}
                    display="inline-block"
                    width="auto"
                    target="_blank"
                    padding="0"
                  >
                    <Flex align="center">
                      <Pdf color={theme.colors.brand} />
                      <Text nowrap size=".875rem" padding="0 0 0 1rem">
                        Ficha de nomeação
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              )}
              {data.broker[0].f2[0] && (
                <Box padding="0 0 1rem 0">
                  <Button
                    href={data.broker[0].f2[0].url}
                    display="inline-block"
                    width="auto"
                    target="_blank"
                    padding="0"
                  >
                    <Flex align="center">
                      <Pdf color={theme.colors.brand} />
                      <Text nowrap size=".875rem" padding="0 0 0 1rem">
                        Apólice de responsabilidade Civil
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              )}
              {data.broker[0].f3[0] && (
                <Box padding="0 0 1rem 0">
                  <Button
                    href={data.broker[0].f3[0].url}
                    display="inline-block"
                    width="auto"
                    target="_blank"
                    padding="0"
                  >
                    <Flex align="center">
                      <Pdf color={theme.colors.brand} />
                      <Text nowrap size=".875rem" padding="0 0 0 1rem">
                        Relatório de Contas
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              )}
              {data.broker[0].f4[0] && (
                <Box padding="0 0 1rem 0">
                  <Button
                    href={data.broker[0].f4[0].url}
                    display="inline-block"
                    width="auto"
                    target="_blank"
                    padding="0"
                  >
                    <Flex align="center">
                      <Pdf color={theme.colors.brand} />
                      <Text nowrap size=".875rem" padding="0 0 0 1rem">
                        Certificado de ASF de mediador
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              )}
              {data.broker[0].f5[0] && (
                <Box padding="0 0 1rem 0">
                  <Button
                    href={data.broker[0].f5[0].url}
                    display="inline-block"
                    width="auto"
                    target="_blank"
                    padding="0"
                  >
                    <Flex align="center">
                      <Pdf color={theme.colors.brand} />
                      <Text nowrap size=".875rem" padding="0 0 0 1rem">
                        IBAN
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              )}
            </Box>
          </Grid3>
          <Flex padding="0 0 2rem 0" justify="flex-start">
            <Button
              filled
              display="inline-block"
              onClick={() => {
                setModayVisibility(true);
                setViewEditProfile(true);
                setViewInvitePopin(false);
              }}
            >
              {locale.profile}
            </Button>
          </Flex>
        </Box>

        <Box padding="0 0 3rem">
          <Heading
            font="montserrat"
            weight="500"
            size="1.5rem"
            color="darkBlue"
            level="3"
            padding="0 0 1.5rem"
          >
            {locale.users}
          </Heading>

          <Table
            loading={loading}
            columns={[
              {
                label: locale.name,
                property: 'forename',
                sortable: false,
                format: user => user.forename,
              },
              {
                label: locale.email,
                property: 'email',
                sortable: false,
                format: user => user.email,
              },
              {
                label: locale.role,
                property: 'role',
                sortable: false,
                format: user => locale[user.role],
              },
              {
                label: locale.status,
                property: 'status',
                sortable: false,
                format: user => (
                  <Badge
                    type={user.status}
                    label={locale.statusObj[user.status]}
                  />
                ),
              },
              {
                label: locale.actions,
                property: 'status1',
                sortable: false,
                format: user => (
                  <Button
                    onClick={
                      user.status === 'active'
                        ? () => handleInactivation(user.id)
                        : () => handleActivation(user.id)
                    }
                    filled
                  >
                    {user.status === 'active'
                      ? locale.activate
                      : locale.inactivate}
                  </Button>
                ),
              },
            ]}
            data={data.broker[0].users}
          />
          <Flex padding="0 0 2rem 0" justify="flex-end">
            <Button
              filled
              onClick={() => {
                setModayVisibility(true);
                setViewEditProfile(false);
                setViewInvitePopin(true);
              }}
            >
              {locale.invite} {locale.user}
            </Button>
          </Flex>
        </Box>

        <Box padding="0 0 3rem">
          <Heading
            font="montserrat"
            weight="500"
            size="1.5rem"
            color="darkBlue"
            level="3"
            padding="0 0 1.5rem"
          >
            {locale.invites}
          </Heading>

          <Table
            loading={loading}
            columns={[
              {
                label: locale.name,
                property: 'name',
                sortable: false,
                format: invite => invite.name,
              },
              {
                label: locale.email,
                property: 'email',
                sortable: false,
                format: invite => invite.email,
              },
              {
                label: locale.created_at,
                property: 'accountCreatedOn',
                sortable: false,
                format: invite => formatDate(invite.created_at),
              },
              {
                label: locale.role,
                property: 'role',
                sortable: false,
                format: invite => locale[invite.role],
              },
              {
                label: locale.status,
                property: 'status',
                sortable: false,
                format: invite => (
                  <Badge
                    type={invite.status}
                    label={
                      invite.status === 'active'
                        ? locale.statusObj['submitted']
                        : locale.statusObj[invite.status]
                    }
                  />
                ),
              },
            ]}
            data={data.broker[0].invites}
          />
        </Box>
      </Wrapper>
    </Box>
  );
};
