import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {theme, Box, AddSymbol, Text, Button} from '@innovago/ui';

export const Dropzone = props => {
  const {onFileAdded} = props;

  const onDrop = useCallback(
    acceptedFiles => {
      onFileAdded(acceptedFiles);
    },
    [onFileAdded]
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const styles = {
    box: {
      border: `2px dashed ${theme.colors.grey}`,
      borderRadius: '12px',
      padding: '2rem',
      textAlign: 'center',
      margin: '0 0 1.5rem 0',
      outline: 'none',
    },
  };

  return (
    <div {...getRootProps()} style={styles.box}>
      <AddSymbol style={{width: '40px', height: '40px'}} />
      <input {...getInputProps()} />
      {isDragActive ? (
        <Text>Largue os ficheiros aqui</Text>
      ) : (
        <Box padding="1.25rem 0 0 0">
          <Box>
            <Button filled caps>
              Selecionar ficheiro
            </Button>
          </Box>
          <Text
            font={theme.fonts.lato}
            weight="500"
            line="1.75"
            color={theme.colors.black}
            align="center"
          >
            ou arraste os ficheiros para aqui.
          </Text>
        </Box>
      )}
    </div>
  );
};
