import React, {useContext, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';

import {
  Box,
  useNotification,
  NotificationContainer,
  ClientBanner,
  ClientPolicies,
  Columns,
  Wrapper,
  Heading,
  theme,
  Flex,
  Button,
  Text,
} from '@innovago/ui';
import {AuthContext} from '../../contexts/AuthContext';
import {clientPolicies, getClientDashboard} from '../../graphql/queries';
import {ClausesForm} from '../Process/form';

const DashboardClient = props => {
  const {user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [nif, setNif] = useState(false);
  const [policyData, setPolicyData] = useState(false);
  const [bannerContent, setBannerContent] = useState(false);
  const [policiesContentBody, setPoliciesContentBody] = useState(false);
  const [formLightbox, toggleFormLightbox] = useState(false);

  useQuery(getClientDashboard, {
    fetchPolicy: 'no-cache',
    skip: !(user && user.username),
    variables: {sub: user.username},
    onCompleted: data => {
      setNif(data.client[0].nif);
      setBannerContent(data.bannerContent[0]);
      setPoliciesContentBody(JSON.parse(data.policiesContent[0]?.body || '[]'));
    },
    onError: error => notify(),
  });

  useQuery(clientPolicies, {
    fetchPolicy: 'no-cache',
    skip: !nif,
    variables: {data: {nif}},
    onCompleted: data => {
      const policies = JSON.parse(data.clientPolicies.policies);

      setPolicyData(policies);
    },
    onError: error => notify(),
  });

  const formItem =
    policiesContentBody &&
    policiesContentBody?.filter(c => c.type === 'formPlaceholder')[0];
  const headerItems =
    policiesContentBody &&
    policiesContentBody?.filter(c => c.type === 'header');
  const bannerContentBody =
    bannerContent && JSON.parse(bannerContent?.body || '[]');

  return (
    <Box width="100%">
      <NotificationContainer {...notifications} />
      <ClientBanner data={bannerContentBody} title={bannerContent?.title} />
      <Box width="100%" padding="3rem 0 8.5rem">
        <NotificationContainer {...notifications} />

        {formLightbox && (
          <ClausesForm
            toggleFormLightbox={toggleFormLightbox}
            notify={notify}
          />
        )}

        <Wrapper>
          <Columns align="flex-start" justify="space-between">
            <Box height="100%">
              {headerItems[0]?.data && (
                <Heading
                  level={headerItems[0]?.data?.level}
                  color={theme.colors.darkBlue}
                  size="1.5rem"
                  weight="500"
                  font={theme.fonts.mont}
                  padding="0 0 1rem"
                >
                  {headerItems[0]?.data?.text}
                </Heading>
              )}
              <ClientPolicies policies={policyData} />
            </Box>
            <Box
              width="1px"
              height="100%"
              backgroundColor={theme.colors.grey}
            />
            <Box height="100%">
              {headerItems[0]?.data && (
                <Heading
                  level={headerItems[1]?.data?.level}
                  color={theme.colors.darkBlue}
                  size="1.5rem"
                  weight="500"
                  font={theme.fonts.mont}
                  padding="0 0 1rem"
                >
                  {headerItems[1]?.data?.text}
                </Heading>
              )}
              <Box
                backgroundColor={theme.colors.lightGrey}
                padding="1.5rem"
                margin="3rem 0"
              >
                <Heading
                  level="3"
                  font={theme.fonts.lato}
                  size=".875rem"
                  weight="500"
                  line="1.14"
                  letter="2.25px"
                  color={theme.colors.darkBlue}
                  padding="0 0 1rem 0"
                  caps
                >
                  {formItem?.data?.title}
                </Heading>
                <Text
                  font={theme.fonts.lato}
                  line="1.75"
                  color={theme.colors.black}
                  padding="0 0 1rem"
                >
                  {formItem?.data?.text}
                </Text>
                <Flex justify="flex-end">
                  <Button
                    color={theme.colors.brand}
                    borderColor={theme.colors.brand}
                    display="inline-block"
                    width="auto"
                    onClick={() => toggleFormLightbox(true)}
                  >
                    FORMULÁRIO
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Columns>
        </Wrapper>
      </Box>
    </Box>
  );
};

export default DashboardClient;
