import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';

import {
  Box,
  Flex,
  Heading,
  Text,
  FormInput,
  FormInputHidden,
  FormSelect,
  FormDate,
  Button,
  FormCheckbox,
  List,
  LoaderInline,
  NotificationContainer,
  useNotification,
  theme,
  locale,
} from '@innovago/ui';

import {getAnswers, getValidations, getVisibility, isEmpty} from './helper';

import {
  createPolicy as createPolicyMutation,
  updatePolicy as updatePolicyMutation,
} from '../../graphql/mutations';

export const SimulatorsStep2 = ({
  setCurrentStep,
  simulatorData,
  conditions,
  nif,
  productID,
  userData,
  policyID,
  setPolicyID,
  setPdf,
  holder,
  previousValues,
}) => {
  const {validationSchema, initialValues} = getValidations(
    simulatorData,
    holder,
    nif
  );

  const [prize, setPrize] = useState(false);
  const [answers, setAnswers] = useState(false);
  const [isGettingQuote, setIsGettingQuote] = useState(false);
  const [visibilityRules, setVisibilityRules] = useState([]);
  const [notifications, notify] = useNotification();

  useEffect(() => {
    if (simulatorData.Visibility && simulatorData.Visibility.length > 0) {
      setVisibilityRules(simulatorData.Visibility);
    }
  }, [simulatorData.Visibility]);

  const policyHolderValue = initialValues.POLICYHOLDER?.trim();
  const formikStep2 = useFormik({
    initialValues: previousValues ? previousValues : initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const answers = getAnswers(simulatorData, nif, userData, values);
      setAnswers(answers);
      setIsGettingQuote(true);

      if (policyID) {
        updatePolicy({
          variables: {
            args: {
              answers: JSON.stringify(answers),
              clientId: 1,
              id: policyID,
              productId: productID,
              status: 'draft',
            },
          },
        });
      } else {
        createPolicy({
          variables: {
            args: {
              answers: JSON.stringify(answers),
              clientId: 1,
              productId: productID,
            },
          },
        });
      }
    },
  });

  const [createPolicy] = useMutation(createPolicyMutation, {
    onCompleted: data => {
      setIsGettingQuote(false);
      if (data.createPolicy.revisions[0].rating.Status.OK) {
        setPrize(
          (
            data.createPolicy.revisions[0].rating.Policy.Events[0].Transaction
              .AnnualPremium +
            data.createPolicy.revisions[0].rating.Policy.Events[0].Transaction
              .AnnualTax
          ).toFixed(2)
        );
        setPolicyID(data.createPolicy.id);
        notify('Simulação criada com sucesso', 'success');
      } else {
        data.createPolicy.revisions[0].rating.Status.Errors.map(error =>
          notify(error.ErrorText, 'error')
        );
      }
    },
    onError: error => {
      console.log(error);
      setIsGettingQuote(false);
      notify();
    },
  });

  const [updatePolicy] = useMutation(updatePolicyMutation, {
    onCompleted: data => {
      setIsGettingQuote(false);
      if (data.updatePolicy.revisions[0].rating.Status.OK) {
        //only runs on simulate
        if (data.updatePolicy.revisions[0].rating.Policy) {
          setPrize(
            (
              data.updatePolicy.revisions[0].rating.Policy.Events[0].Transaction
                .AnnualPremium +
              data.updatePolicy.revisions[0].rating.Policy.Events[0].Transaction
                .AnnualTax
            ).toFixed(2)
          );
        }

        setPolicyID(data.updatePolicy.id);
        notify('Simulação criada com sucesso', 'success');

        //only runs on save
        if (data.updatePolicy.revisions[0].attachment) {
          setPdf(data.updatePolicy.revisions[0].attachment.url);
          setCurrentStep(3);
        }
      } else {
        data.updatePolicy.revisions[0].rating.Status.Errors.forEach(error => {
          notify(error.ErrorText);
        });
      }
    },
    onError: error => {
      console.error(error);
      setIsGettingQuote(false);
      notify();
    },
  });

  const submitPolicyHandler = () => {
    setIsGettingQuote(true);
    updatePolicy({
      variables: {
        args: {
          answers: JSON.stringify(answers),
          clientId: 1,
          id: policyID,
          productId: productID,
          status: 'submitted',
        },
      },
    });
  };

  const handleValidation = async () => {
    try {
      const formikErrors = await formikStep2.validateForm();

      if (!isEmpty(formikErrors) && !formikErrors.POLICYHOLDER) {
        const answers = getAnswers(
          simulatorData,
          nif,
          userData,
          formikStep2.values
        );
        setAnswers(answers);
        if (policyID) {
          setIsGettingQuote(true);
          updatePolicy({
            variables: {
              args: {
                answers: JSON.stringify(answers),
                clientId: 1,
                id: policyID,
                productId: productID,
                status: 'draft',
              },
            },
          });
        } else {
          setIsGettingQuote(true);
          createPolicy({
            variables: {
              args: {
                answers: JSON.stringify(answers),
                clientId: 1,
                productId: productID,
              },
            },
          });
        }
      } else {
        formikStep2.handleSubmit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const personalData = useMemo(() => {
    let newData = simulatorData.Policyholder;
    let tempLocal = newData[5];
    newData[5] = simulatorData.Policyholder[6];
    newData[6] = tempLocal;

    return newData;
  }, [simulatorData.Policyholder]);

  const isVisible = useCallback(
    (InputId, visibilityRules, formikStep2) =>
      getVisibility(InputId, visibilityRules, formikStep2),
    []
  );

  return (
    <Box margin="1.5rem 0 0">
      <NotificationContainer {...notifications} />
      <Heading
        level="3"
        background={theme.colors.lightGrey}
        color={theme.colors.darkBlue}
        size="1.5rem"
        line="3.5rem"
        order="2"
        alignItem="center"
        justify="flex-start"
        padding="0 1.5rem 0 1rem"
        margin="0 0 .5rem"
        display="grid"
        align="left"
        gridColumns="60px 1fr 20px"
        backgroundBefore={theme.colors.brand}
        colorBefore={theme.colors.white}
        sizeBefore="1.125rem"
        widthBefore="2rem"
        heightBefore="2rem"
        lineBefore="2rem"
      >
        Capitais Seguros
      </Heading>

      <Box>
        <form>
          <Box margin="2rem 0 .5rem">
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              weight="500"
              size="1.125rem"
              margin="0 0 .5rem"
              font={theme.fonts.mont}
              line="1.6"
              caps
            >
              {locale.insuranceClient}
            </Heading>
            {personalData.map(item => {
              if (
                !(
                  item.InputId === 'POLICYHOLDERID' ||
                  item.InputId === 'COUNTRY'
                )
              ) {
                return (
                  <FormInput
                    key={item.InputId}
                    labelFont={theme.fonts.mont}
                    labelMedium
                    margin="0 0 .5rem"
                    type="text"
                    name={item.InputId}
                    label={item.QuestionText}
                    formik={formikStep2}
                    placeholder={item.InputId === 'POSTCODE' ? '####-###' : ''}
                    flex
                    inline
                    disabled={item.InputId === 'POLICYHOLDER' && policyHolderValue}
                    maxLength={50}
                  />
                );
              } else {
                return false;
              }
            })}
          </Box>

          <Box>
            {simulatorData.Risks &&
              simulatorData.Risks.map((risk, indexr) => {
                return (
                  <Box key={`risk${indexr}`}>
                    {risk.Covers.map((cover, index1) => {
                      return (
                        <Box
                          key={`cover${index1}`}
                          data-cover={cover.CoverCode}
                          margin="2rem 0"
                        >
                          <Heading
                            level="4"
                            color={theme.colors.darkBlue}
                            weight="500"
                            size="1.125rem"
                            margin="0 0 .5rem"
                            font={theme.fonts.mont}
                            line="1.6"
                            caps
                          >
                            {cover.Description}
                          </Heading>
                          {cover.Items.map(item => {
                            return item.Questions.map(question => {
                              return (
                                <Box key={question.InputId}>
                                  {question.Attribute === 'Number' && (
                                    <FormInput
                                      labelFont={theme.fonts.mont}
                                      labelMedium
                                      margin="0 0 .5rem"
                                      type="number"
                                      name={question.InputId}
                                      label={question.QuestionText}
                                      formik={formikStep2}
                                      flex
                                      inline
                                      vis={isVisible(
                                        question.InputId,
                                        visibilityRules,
                                        formikStep2.values
                                      )}
                                    />
                                  )}
                                  {question.Attribute === 'Alpha' && (
                                    <FormInput
                                      labelFont={theme.fonts.mont}
                                      labelMedium
                                      margin="0 0 .5rem"
                                      type="text"
                                      name={question.InputId}
                                      label={question.QuestionText}
                                      formik={formikStep2}
                                      flex
                                      inline
                                      vis={isVisible(
                                        question.InputId,
                                        visibilityRules,
                                        formikStep2.values
                                      )}
                                    />
                                  )}
                                  {question.Attribute === 'List' && (
                                    <FormSelect
                                      labelFont={theme.fonts.mont}
                                      labelMedium
                                      margin="0 0 .5rem"
                                      name={question.InputId}
                                      label={question.QuestionText}
                                      allowEmpty
                                      placeholder={locale.selectPlaceholder}
                                      options={question.Answers.map(answer => {
                                        return {
                                          label: answer.AnswerText,
                                          value: answer.AnswerCode,
                                        };
                                      })}
                                      formik={formikStep2}
                                      flex
                                      inline
                                      vis={isVisible(
                                        question.InputId,
                                        visibilityRules,
                                        formikStep2.values
                                      )}
                                    />
                                  )}
                                </Box>
                              );
                            });
                          })}

                          {cover.Questions.map((question, index3) => {
                            return (
                              <Box key={`cover${index1}_question${index3}`}>
                                {question.Attribute === 'List' && (
                                  <FormSelect
                                    labelFont={theme.fonts.mont}
                                    labelMedium
                                    margin="0 0 .5rem"
                                    name={question.InputId}
                                    label={question.QuestionText}
                                    allowEmpty
                                    placeholder={locale.selectPlaceholder}
                                    options={question.Answers.map(answer => {
                                      return {
                                        label: answer.AnswerText,
                                        value: answer.AnswerCode,
                                      };
                                    })}
                                    formik={formikStep2}
                                    flex
                                    inline
                                    vis={isVisible(
                                      question.InputId,
                                      visibilityRules,
                                      formikStep2.values
                                    )}
                                  />
                                )}
                                {question.Attribute === 'Alpha' && (
                                  <FormInput
                                    labelFont={theme.fonts.mont}
                                    labelMedium
                                    margin="0 0 .5rem"
                                    type="text"
                                    name={question.InputId}
                                    label={question.QuestionText}
                                    formik={formikStep2}
                                    flex
                                    inline
                                    vis={isVisible(
                                      question.InputId,
                                      visibilityRules,
                                      formikStep2.values
                                    )}
                                  />
                                )}
                                {question.Attribute === 'Number' && (
                                  <FormInput
                                    labelFont={theme.fonts.mont}
                                    labelMedium
                                    margin="0 0 .5rem"
                                    type="number"
                                    name={question.InputId}
                                    label={question.QuestionText}
                                    formik={formikStep2}
                                    flex
                                    inline
                                    vis={isVisible(
                                      question.InputId,
                                      visibilityRules,
                                      formikStep2.values
                                    )}
                                  />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}

                    {risk && risk.Questions.length > 0 && (
                      <Box>
                        <Heading
                          level="4"
                          color={theme.colors.darkBlue}
                          weight="500"
                          size="1.125rem"
                          margin="0 0 .5rem"
                          font={theme.fonts.mont}
                          line="1.6"
                          caps
                        >
                          Outras Questões
                        </Heading>
                      </Box>
                    )}

                    {risk &&
                      risk.Questions.length > 0 &&
                      risk.Questions.map((question, index) => {
                        return (
                          <Box key={`question1${index}`}>
                            {question.Attribute === 'List' && (
                              <FormSelect
                                labelFont={theme.fonts.mont}
                                labelMedium
                                margin="0 0 .5rem"
                                name={question.InputId}
                                label={question.QuestionText}
                                allowEmpty
                                placeholder={locale.selectPlaceholder}
                                options={question.Answers.map(answer => {
                                  return {
                                    label: answer.AnswerText,
                                    value: answer.AnswerCode,
                                  };
                                })}
                                formik={formikStep2}
                                flex
                                inline
                                vis={isVisible(
                                  question.InputId,
                                  visibilityRules,
                                  formikStep2.values
                                )}
                              />
                            )}
                            {question.Attribute === 'Alpha' && (
                              <FormInput
                                labelFont={theme.fonts.mont}
                                labelMedium
                                margin="0 0 .5rem"
                                type="text"
                                name={question.InputId}
                                label={question.QuestionText}
                                formik={formikStep2}
                                flex
                                inline
                                vis={isVisible(
                                  question.InputId,
                                  visibilityRules,
                                  formikStep2.values
                                )}
                              />
                            )}
                            {question.Attribute === 'Number' && (
                              <FormInput
                                labelFont={theme.fonts.mont}
                                labelMedium
                                margin="0 0 .5rem"
                                type="number"
                                name={question.InputId}
                                label={question.QuestionText}
                                formik={formikStep2}
                                flex
                                inline
                                vis={isVisible(
                                  question.InputId,
                                  visibilityRules,
                                  formikStep2.values
                                )}
                              />
                            )}
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}

            {simulatorData.Questions.length > 0 &&
              simulatorData.Questions.map((question, index) => {
                return (
                  <Box key={`question2${index}`} overflow="initial">
                    {question.Attribute === 'List' && (
                      <FormSelect
                        labelFont={theme.fonts.mont}
                        labelMedium
                        margin="0 0 .5rem"
                        name={question.InputId}
                        label={question.QuestionText}
                        allowEmpty
                        placeholder={locale.selectPlaceholder}
                        options={question.Answers.map(answer => {
                          return {
                            label: answer.AnswerText,
                            value: answer.AnswerCode,
                          };
                        })}
                        formik={formikStep2}
                        flex
                        half
                        inline
                        vis={isVisible(
                          question.InputId,
                          visibilityRules,
                          formikStep2.values
                        )}
                      />
                    )}
                    {question.Attribute === 'Alpha' && (
                      <FormInput
                        labelFont={theme.fonts.mont}
                        labelMedium
                        margin="0 0 .5rem"
                        type="text"
                        name={question.InputId}
                        label={question.QuestionText}
                        formik={formikStep2}
                        flex
                        inline
                        vis={isVisible(
                          question.InputId,
                          visibilityRules,
                          formikStep2.values
                        )}
                      />
                    )}
                    {question.Attribute === 'Number' && (
                      <FormInput
                        labelFont={theme.fonts.mont}
                        labelMedium
                        margin="0 0 .5rem"
                        type="number"
                        name={question.InputId}
                        label={question.QuestionText}
                        formik={formikStep2}
                        flex
                        inline
                        vis={isVisible(
                          question.InputId,
                          visibilityRules,
                          formikStep2.values
                        )}
                      />
                    )}
                    {question.Attribute === 'Date' && (
                      <FormDate
                        id="simulator-date"
                        labelFont={theme.fonts.mont}
                        labelMedium
                        margin="0 0 .5rem"
                        type="text"
                        name={question.InputId}
                        label={question.QuestionText}
                        formik={formikStep2}
                        flex
                        inline
                        vis={isVisible(
                          question.InputId,
                          visibilityRules,
                          formikStep2.values
                        )}
                      />
                    )}
                  </Box>
                );
              })}
            <Box margin="3.5rem 0 3.5rem">
              <Heading
                level="3"
                font={theme.fonts.mont}
                size=".875rem"
                padding="1rem 0"
              >
                {locale.insuranceConditions}
              </Heading>
              {conditions &&
                conditions.map((item, index) => {
                  switch (item.type) {
                    case 'header':
                      return (
                        <Heading
                          level="4"
                          key={`information${index}`}
                          size="1rem"
                          weight="700"
                          font={theme.fonts.lato}
                          line="1.75"
                          padding="0 0 1rem"
                        >
                          {item.data.text}
                        </Heading>
                      );
                    case 'paragraph':
                      return (
                        <Text
                          html={item.data.text}
                          key={`information${index}`}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          line="1.75"
                          padding="0 0 1rem"
                        />
                      );
                    case 'list':
                      return (
                        <List
                          key={`information${index}`}
                          type={item.data.style}
                          items={item.data.items}
                          color={theme.colors.black}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          line="1.75"
                        />
                      );
                    default:
                      return false;
                  }
                })}
              <FormCheckbox
                labelFont={theme.fonts.mont}
                labelMedium
                margin="0 0 .5rem"
                name="acceptance"
                label={locale.insuranceAcceptance}
                formik={formikStep2}
              />
              <FormInputHidden name="COUNTRY" formik={formikStep2} />
            </Box>
          </Box>
          <Box
            height="2px"
            width="100%"
            backgroundColor={theme.colors.cloudyBlue2}
            margin="2.5rem 0 1.5rem"
          />
          <Flex align="center" justify="space-between">
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              weight="500"
              size="1.125rem"
              font={theme.fonts.mont}
              line="1.6"
              caps
            >
              {locale.totalPrize}
            </Heading>
            {prize && (
              <Heading
                level="4"
                color={theme.colors.darkBlue}
                weight="500"
                size="1.125rem"
                font={theme.fonts.mont}
                line="1.6"
                caps
              >
                {prize}€
              </Heading>
            )}
          </Flex>
          <Flex margin="1rem 0 0 0" align="center" justify="flex-end">
            {!isGettingQuote && (
              <Button
                filled
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  if (!formikStep2.isValidating) {
                    handleValidation();
                  }
                }}
              >
                Simular
              </Button>
            )}
            {policyID && !isGettingQuote && (
              <Button
                margin="0 0 0 1rem"
                outline
                onClick={() => submitPolicyHandler()}
              >
                Gravar
              </Button>
            )}
            {isGettingQuote && <LoaderInline />}
          </Flex>
          {Object.keys(formikStep2.errors).length > 0 && (
            <Text color={theme.colors.error} size=".75rem" align="right">
              Por favor corrija os campos assinalados
            </Text>
          )}
          <Box
            height="2px"
            width="100%"
            backgroundColor={theme.colors.cloudyBlue2}
            margin="1.5rem 0"
          />
        </form>
      </Box>
    </Box>
  );
};
