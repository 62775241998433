import React from 'react';

import {
  Flex,
  Box,
  Heading,
  Text,
  Image,
  Button,
  Loader,
  formatDate,
  theme,
  locale,
} from '@innovago/ui';

export const Campaigns = ({data, loading, order, setOrder}) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Flex align="flex-start" justify="flex-start">
      {data &&
        data.content.map((campaign, index) => {
          const image = campaign.attachments.filter(
            attach => attach.field_name === 'thumbnail'
          );
          const body = JSON.parse(campaign.body);
          const fileList = body.filter(item => item.type === 'fileList');
          return (
            <Box
              margin="0 1rem 1rem 0"
              width="210px"
              elevated
              key={'campaign' + index}
            >
              {image[0] && <Image src={image[0].url} />}
              <Heading
                level="3"
                font={theme.fonts.lato}
                size="1rem"
                align="center"
                padding="1rem 1rem .5rem 1rem"
                height="72px"
                caps
              >
                {campaign.title}
              </Heading>
              <Text
                align="center"
                padding="0 0 1rem 0"
                font={theme.fonts.mont}
                size=".875rem"
              >
                {formatDate(campaign.publish_at)}
              </Text>
              <Flex justify="center" margin="0 0 2rem">
                {fileList[0].data.items.map((attachment, index) => {
                  return (
                    attachment.file && (
                      <Button
                        key={'pdf' + index}
                        type="link"
                        title={attachment.label}
                        color={theme.colors.black}
                        href={attachment.file}
                        padding="0 .5rem"
                        width="auto"
                        filled
                      >
                        {locale.download}
                      </Button>
                    )
                  );
                })}
              </Flex>
            </Box>
          );
        })}
    </Flex>
  );
};
