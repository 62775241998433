/*global gtag */
import React, {useContext, useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {navigate} from 'hookrouter';
import {useQuery} from '@apollo/react-hooks';

import {AuthContext} from '../../contexts/AuthContext';

import RecoverPassword from './recoverPass';

import {setting} from '../../graphql/queries';

import {
  Box,
  BgImage,
  Flex,
  Grid2,
  Wrapper,
  Text,
  Heading,
  Button,
  FormInput,
  FormPassword,
  FormCheckbox,
  Loader,
  NotificationContainer,
  useNotification,
  theme,
  locale,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(locale.required),
  password: Yup.string().required(locale.required),
});

const validationSchema2 = Yup.object().shape({
  nif: Yup.string()
    .matches(/^[0-9]{9}$/, locale.validation.digits9)
    .required(locale.required),
  password2: Yup.string().required(locale.required),
});

export const Login = () => {
  const authContext = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [modalVisibility, setModayVisibility] = useState(false);
  const [loginType, setLoginType] = useState('broker');
  const [loginLocked, setLoginLocked] = useState(false);

  const {data, loading} = useQuery(setting, {
    fetchPolicy: 'cache-and-network',
    onError: () => {
      notify();
    },
  });

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        document.querySelector("form button[type='submit']").click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  // usequeryparams para ver client=1

  const formik = useFormik({
    initialValues: {email: '', password: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      try {
        setLoginLocked(true);
        const loginAvailable = await authContext.login(
          values.email.toLowerCase(),
          values.password
        );
        if (!loginAvailable) {
          setLoginLocked(false);
          notify('Não tem permissões para ver esta página', 'error');
        }
      } catch (err) {
        setLoginLocked(false);
        notify(locale.loginError);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {nif: '', password2: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchema2,
    onSubmit: async values => {
      try {
        const nif = values.nif.replace(/\s/g, '');
        const password = values.password2.replace(/\s/g, '');

        setLoginLocked(true);
        const loginAvailable = await authContext.login(
          'no-reply+inno-%@minuscode.com'.replace('%', nif),
          password
        );
        if (!loginAvailable) {
          setLoginLocked(false);
          notify('Não tem permissões para ver esta página', 'error');
        } else {
          if (authContext.isSignedIn()) {
            navigate('/dashboard', true);
          }
        }
      } catch (err) {
        setLoginLocked(false);
        notify(locale.loginError);
      }
    },
  });

  const closeModal = () => {
    setModayVisibility(false);
  };

  const goToRegister = () => {
    gtag('event', 'action', {
      event_category: 'click',
      event_label: 'Abriu Form de Nomeação',
    });
  };

  const goToRegisterClient = () => {
    gtag('event', 'action', {
      event_category: 'click',
      event_label: 'Client pediu registo',
    });
  };

  if (authContext.isSignedIn()) {
    navigate('/dashboard', true);
  }

  if (loading || !data) {
    return <Loader />;
  }

  const image = JSON.parse(
    data.setting.find(item => item.name === 'loginImage')?.value || '{}'
  );

  const imageClient = JSON.parse(
    data.setting.find(item => item.name === 'clientLoginImage')?.value || '{}'
  );

  const brokerText =
    data.setting.find(item => item.name === 'brokerLoginText')?.value || '';

  const clientLoginText =
    data.setting.find(item => item.name === 'clientLoginText')?.value || '';

  const maintenanceMode =
    data.setting.find(item => item.name === 'maintenanceMode').value === 'true'
      ? true
      : false;

  const maintenanceImage = JSON.parse(
    data.setting.find(item => item.name === 'maintenanceImage')?.value || '{}'
  );

  const maintenanceMobileImage = JSON.parse(
    data.setting.find(item => item.name === 'maintenanceMobileImage')?.value ||
      '{}'
  );

  if (maintenanceMode) {
    return (
      <Box position="absolute" height="100%" width="100%">
        <BgImage
          image={maintenanceImage.url}
          aspectRatio={false}
          size="contain"
          width="100%"
          height="100%"
          hideOnMobile={true}
          showOnMobile={false}
          bgColor="#f5a814"
        ></BgImage>
        <BgImage
          image={maintenanceMobileImage.url}
          aspectRatio={false}
          size="contain"
          width="100%"
          height="100%"
          hideOnMobile={false}
          showOnMobile={true}
          bgColor="#f5a814"
        ></BgImage>
      </Box>
    );
  }

  return (
    <Box position="absolute" height="100%" width="100%">
      <BgImage
        image={loginType === 'broker' ? image.url : imageClient.url}
        aspectRatio={false}
        width="100%"
        height="100%"
        showOnMobile={true}
      >
        {modalVisibility && (
          <RecoverPassword loginType={loginType} onClose={closeModal} />
        )}
        <Wrapper>
          <NotificationContainer {...notifications} />
          <Flex align="flex-start" justify="space-between">
            <Box columns="4" margin="15% 0 0 0" rounded>
              <h1
                style={{
                  fontfamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: '30px',
                  color: theme.colors.yellow,
                  textShadow: '0 2px 4px rgba(0,0,0,0.50)',
                }}
              >
                {loginType === 'broker' ? brokerText : clientLoginText}
              </h1>
              <Text
                color="#fff"
                padding="3rem 0 1rem 0"
                font="Lato"
                weight="500"
              >
                {loginType === 'broker'
                  ? 'Se a sua empresa não tem nomeação?'
                  : 'Ainda não está registado?'}
              </Text>
              <Button
                href={loginType === 'broker' ? 'register' : 'client-register'}
                color="#fff"
                caps="uppercase"
                border={true}
                borderColor="#fff"
                margin="0 0 10% 0"
                filled
                onClick={() =>
                  loginType === 'broker' ? goToRegister() : goToRegisterClient()
                }
              >
                {loginType === 'broker' ? 'pedir nomeação' : 'registe-se'}
              </Button>
            </Box>
            <Box
              position="relative"
              columns="4"
              margin="15% 0 0 0"
              backgroundColor="#fff"
              padding="1.5rem 1rem"
              overflow="visible"
            >
              <Box
                position="absolute"
                zIndex="10"
                backgroundColor="transparent"
                elevated
                top="-52px"
                left="0"
                roundedTop
                width="100%"
              >
                <Grid2 nowrap inline style={{gridColumnGap: '0'}}>
                  <Box
                    style={{
                      textAlign: 'center',
                      height: loginType === 'broker' ? '100%' : 'inherit',
                      width: '100%',
                      padding: '0.5rem 0',
                      borderRadius:
                        loginType === 'broker' ? '4px 4px 0 0' : '4px 0 0 0',
                      background:
                        loginType === 'broker' ? theme.colors.brand : '#FFF',
                      marginTop: loginType === 'broker' ? '0' : '10px',
                    }}
                  >
                    <Button
                      background="transparent"
                      transition={false}
                      color={
                        loginType === 'broker' ? '#FFF' : theme.colors.grey
                      }
                      margin="0 auto"
                      marginTop={loginType !== 'broker' ? '0' : '5px'}
                      width="auto"
                      onClick={() => setLoginType('broker')}
                    >
                      Broker
                    </Button>
                  </Box>
                  <Box
                    style={{
                      textAlign: 'center',
                      height: loginType === 'client' ? '100%' : 'inherit',
                      width: '100%',
                      padding: '0.5rem 0',
                      borderRadius:
                        loginType === 'client' ? '4px 4px 0 0' : '0 4px 0 0',
                      background:
                        loginType === 'client' ? theme.colors.brand : '#FFF',
                      marginTop: loginType === 'client' ? '0' : '10px',
                    }}
                  >
                    <Button
                      background="transparent"
                      transition={false}
                      color={
                        loginType === 'client' ? '#FFF' : theme.colors.grey
                      }
                      margin="0 auto"
                      marginTop={loginType !== 'client' ? '0' : '5px'}
                      width="auto"
                      onClick={() => setLoginType('client')}
                    >
                      Clientes
                    </Button>
                  </Box>
                </Grid2>
              </Box>
              <Heading
                level={2}
                color={theme.colors.brand}
                weight="500"
                size={'1.5rem'}
                padding={'0 0 2rem'}
              >
                Entrar no portal
              </Heading>
              {loginType === 'broker' ? (
                <form onSubmit={formik.handleSubmit}>
                  <FormInput
                    type="email"
                    name="email"
                    label={locale.email}
                    formik={formik}
                    width="100%"
                  />
                  <FormPassword
                    name="password"
                    label={locale.password}
                    formik={formik}
                    width="100%"
                  />
                  <Flex justify="space-between" margin="0 0 2rem">
                    <FormCheckbox
                      name="remember"
                      label="Lembrar-me"
                      margin="0"
                      formik={formik}
                      disable={false}
                    />
                    <Button
                      size={'.875rem'}
                      color={theme.colors.brand}
                      align={'right'}
                      underline
                      link={true}
                      padding="0"
                      href="#"
                      onClick={() => setModayVisibility(true)}
                    >
                      {locale.recoverPassword}
                    </Button>
                  </Flex>
                  <Button
                    type="submit"
                    display="block"
                    width="100%"
                    filled
                    disabled={loginLocked}
                  >
                    {locale.login}
                  </Button>
                </form>
              ) : (
                <form onSubmit={formik2.handleSubmit}>
                  <FormInput
                    name="nif"
                    label="NIF"
                    formik={formik2}
                    width="100%"
                  />
                  <FormPassword
                    name="password2"
                    label={locale.password}
                    formik={formik2}
                    width="100%"
                  />
                  <Flex justify="space-between" margin="0 0 2rem">
                    <FormCheckbox
                      name="remember"
                      label="Lembrar-me"
                      margin="0"
                      formik={formik2}
                      disable={false}
                    />
                    <Button
                      size={'.875rem'}
                      color={theme.colors.brand}
                      align={'right'}
                      underline
                      link={true}
                      padding="0"
                      href="#"
                      onClick={() => setModayVisibility(true)}
                    >
                      {locale.recoverPassword}
                    </Button>
                  </Flex>
                  <Button
                    type="submit"
                    display="block"
                    width="100%"
                    filled
                    disabled={loginLocked}
                  >
                    {locale.login}
                  </Button>
                </form>
              )}
            </Box>
          </Flex>
        </Wrapper>
      </BgImage>
    </Box>
  );
};

export default Login;
