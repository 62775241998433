import React from 'react';
import ReactDOM from 'react-dom';
import 'url-search-params-polyfill';
import './index.css';
import App from './App';
import Auth from '@aws-amplify/auth';
import * as serviceWorker from './serviceWorker';
import config from './config';

Auth.configure(config.Auth);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
