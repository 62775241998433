import React from 'react';
import styled from 'styled-components';

import {
  Box,
  Flex,
  WrapperSmall,
  Download,
  BgImage,
  Heading,
  Title,
  Text,
  Button,
  theme,
  locale,
} from '@innovago/ui';

const NewsletterBgImage = styled(BgImage)`
  background-size: auto 50%;
  background-position: center left;
  background-color: rgba(194, 216, 219, 0.2);

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    background-position: 90% 10%;
    background-size: 200px;
    padding: 0;
  }
`;

export const Newsletter = ({content, image}) => {
  const {title, body} = content;
  const formatedBody = JSON.parse(body);

  if (formatedBody.length === 0) {
    return null;
  }

  return (
    <NewsletterBgImage
      size="contain"
      bgPosition="left bottom"
      height="auto"
      image={image.url}
    >
      <Box padding="3.5rem 0">
        <WrapperSmall>
          <Title
            level="2"
            font={theme.fonts.mont}
            color={theme.colors.brand}
            padding="0 0 1.5rem"
          >
            {title}
          </Title>
          <Box padding="0 0 2rem">
            {formatedBody.map((item, index) => {
              switch (item.type) {
                case 'header':
                  return (
                    <Heading
                      key={`campaign${index}`}
                      level={item.data.level}
                      color={theme.colors.black}
                      size="1.125rem"
                      weight="500"
                      font={theme.fonts.mont}
                      padding="0 0 1rem"
                    >
                      {item.data.text}
                    </Heading>
                  );

                case 'fileList':
                  return (
                    <Flex
                      key={`campaign${index}`}
                      align="center"
                      justify="space-between"
                      width="100%"
                    >
                      <Text
                        font={theme.fonts.lato}
                        size="1rem"
                        line="1.75"
                        color={theme.colors.black}
                        nowrap
                      >
                        {item.data.title}
                      </Text>
                      <Button
                        type="link"
                        href={item.data.items[0].file}
                        target="_blank"
                      >
                        <Flex justify="flex-start">
                          <Download color={theme.colors.brand} />
                          <Text
                            font={theme.fonts.mont}
                            size=".875rem"
                            color={theme.colors.brand}
                            transform="uppercase"
                            padding="0 1rem"
                          >
                            {item.data.items[0].label}
                          </Text>
                        </Flex>
                      </Button>
                    </Flex>
                  );

                default:
                case 'paragraph':
                  return (
                    <Text
                      key={`campaign${index}`}
                      color={theme.colors.darkBlue}
                      size="1rem"
                      weight="400"
                      font={theme.fonts.lato}
                      line="1.75"
                      padding="0 0 1rem"
                      html={item.data.text}
                    ></Text>
                  );
              }
            })}
          </Box>
          <Button
            display="inline-block"
            size={'.875rem'}
            color={theme.colors.brand}
            href="/communications/newsletter"
            padding={'0 2rem'}
            filled
          >
            {locale.seeAllNewsletters}
          </Button>
        </WrapperSmall>
      </Box>
    </NewsletterBgImage>
  );
};
