import React, {useState, useContext} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import moment from 'moment';

import {
  Box,
  Grid2,
  Table,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Text,
  Button,
  theme,
  locale,
  Loader,
  formatDate,
} from '@innovago/ui';

import {getTrainings, getUser} from '../../graphql/queries';
import {AuthContext} from '../../contexts/AuthContext';

import {InsertPresence as insertPresenceMutation} from '../../graphql/mutations';

export const Trainings = () => {
  const {user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [userData, setUserData] = useState(false);

  const today = moment().format('YYYY-MM-DD');
  const currentHour = moment().subtract(2, 'hour').format('HH:mm');

  const currentYear = moment().format('YYYY');

  let where = {
    _and: [
      {
        status: {_eq: 'published'},
        tutorial: {_eq: false},
      },
      {
        _or: [
          {
            date: {_gt: today},
          },
          {
            _and: [{date: {_eq: today}}, {hour: {_gte: currentHour}}],
          },
        ],
      },
    ],
  };

  const queryVars = {
    where: where,
    sub: user && user && user.username,
  };

  const {data, loading} = useQuery(getTrainings, {
    fetchPolicy: 'no-cache',
    variables: queryVars,
    skip: !user,
    onError: () => notify(),
  });

  const {loading: loading2} = useQuery(getUser, {
    skip: !(user && user.username),
    fetchPolicy: 'no-cache',
    variables: {
      sub: user.username,
    },
    onCompleted: data => {
      setUserData(data);
    },
    onError: () => notify(),
  });

  const handleRegistration = trainingID => {
    insertPresence({
      variables: {
        training_id: String(trainingID),
        user_id: String(userData.user[0].id),
      },
    });
  };

  const [insertPresence] = useMutation(insertPresenceMutation, {
    onCompleted: data => {
      notify('O registo foi feito com sucesso', 'success');
    },
    onError: error => {
      console.log(error);
      notify();
    },
    refetchQueries: ['getTrainings'],
  });

  if (loading || loading2) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      <Wrapper>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          weight="500"
          caps
          padding="0 0 .25rem"
        >
          Formação
        </Heading>
        <Heading
          level={1}
          color={theme.colors.brand}
          size="2rem"
          font={theme.fonts.mont}
          padding="0 0 2rem"
        >
          Sessões de Formação
        </Heading>
        <Grid2 align="flex-start">
          <Box>
            <Text>
              Sabemos que os nossos seguros são especializados e, muitas vezes,
              soluções diferentes daquelas que habitualmente gere no seu
              dia-a-dia. Por isso compreendemos que por vezes é complicado ter
              um conhecimento aprofundado de todos os produtos disponibilizados
              pelo mercado segurador.
            </Text>
            <Text>
              Sendo que um dos nossos pilares principais é proporcionar aos
              nossos parceiros ferramentas e apoio para o seu negócio, queremos
              facilitar o seu trabalho, convidando-o a participar nas nossas
              formações ao longo deste ano.
            </Text>
          </Box>
          <Box>
            <Heading
              level="3"
              font={theme.fonts.mont}
              size="1.5rem"
              color={theme.colors.darkBlue}
              weight="500"
              padding="0 0 .5rem"
            >
              Inscrever numa formação e|GO
            </Heading>
            <Text>
              Caso pretenda frequentar uma destas formações, consulte o mapa
              abaixo e carrege no botão Inscrever, ou contacte o gestor do
              Innova|GO para mais informações:
            </Text>
            <Button
              size={'.875rem'}
              color={theme.colors.brand}
              link={true}
              underline={true}
              target="_blank"
              href="mailto:innovago@innovarisk.pt"
              transparent
              padding="0"
            >
              innovago@innovarisk.pt
            </Button>
          </Box>
        </Grid2>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size="1.5rem"
          color={theme.colors.darkBlue}
          weight="500"
          padding="2.5rem 0 1.5rem"
        >
          Mapa de formações {currentYear}
        </Heading>

        <Table
          loading={loading}
          columns={[
            {
              label: locale.title,
              property: 'name',
              sortable: false,
              format: item => item.name,
            },
            {
              label: locale.teacher,
              property: 'teacher',
              sortable: false,
              format: item => item.teacher,
            },
            {
              label: locale.date,
              property: 'date',
              sortable: false,
              format: item => formatDate(item.date),
            },
            {
              label: locale.hour,
              property: 'hour',
              sortable: false,
              format: item => item.hour,
            },
            {
              label: locale.place,
              property: 'city',
              sortable: false,
              format: item => item.city,
            },
            {
              label: locale.duration,
              property: 'duration',
              sortable: false,
              format: item => item.duration + ' minutos',
            },
            {
              label: 'Categorias',
              property: 'training_categories',
              sortable: false,
              format: item => {
                if (
                  item.training_categories &&
                  item.training_categories.length > 0
                ) {
                  return item.training_categories
                    .map(category => {
                      return category.category.name;
                    })
                    .toString();
                } else {
                  return '---';
                }
              },
            },
            {
              label: locale.registration,
              property: 'link',
              sortable: false,
              format: item =>
                item.presences.length === 0 ? (
                  <Button
                    size={'.875rem'}
                    color={theme.colors.brand}
                    filled
                    caps
                    onClick={() => handleRegistration(item.id)}
                  >
                    INSCREVER
                  </Button>
                ) : (
                  <Button filled href={item.link} target="_blank">
                    Ir para a conferência
                  </Button>
                ),
            },
          ]}
          data={data && data.trainings}
        />
      </Wrapper>
    </Box>
  );
};
