import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../theme';

import {Flex, Box, Grid2, Grid3, Wrapper} from '../Box';
import {Heading} from '../Heading';
import {Logo, LogoBackoffice, Menu} from '../Icons';
import {locale} from '../..';

const NavbarWrap = styled.div`
  width: 100%;
  position: relative;
  z-index: 11;
`;

const TopBar = styled(Wrapper)`
  align-items: center;
  color: ${props =>
    props.barColor ? theme.colors.white : theme.colors.darkBlue};
  column-gap: 1rem;
  display: grid;
  font-family: ${theme.fonts.mont};
  font-size: 0.75rem;
  grid-auto-flow: column;
  line-height: 1.3;
  justify-content: end;
  justify-items: end;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: relative;
  text-transform: uppercase;
  overflow: hidden;

  &::before {
    background-color: ${props =>
      props.barColor ? props.barColor : theme.colors.lightGrey};
    content: '';
    display: block;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
`;

const TopLink = styled.a`
  color: ${({active, barColor}) =>
    active
      ? theme.colors.brand
      : barColor
      ? theme.colors.white
      : theme.colors.darkBlue};
  display: block;
  text-overflow: ellipsis;
  text-decoration: none;
  text-transform: 'uppercase';
  outline: none;
  font-size: 0.7rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
  }

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    display: block;
  }
`;

const BottomBar = styled(TopBar)`
  align-items: end;
  font-size: 0.85rem;
  justify-content: space-between;
  padding-bottom: 1rem;
  position: static;

  &::before {
    background-color: ${props =>
      props.barColor ? props.barColor : theme.colors.brand};
    bottom: 0;
    height: 0.5rem;
    top: auto;
  }
`;

const Button = styled.button`
  appearance: none;
  background-color: transparent;
  border: 1px solid;
  border-radius: 18px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  padding: 0.1rem 0.5rem;
  text-transform: inherit;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
    outline: none;
  }
`;

const MenuScroller = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const MenuContainer = styled(Flex)`
  transition: all 0.3s;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    position: fixed;
    right: 0;
    top: ${props => (props.top ? props.top : '166px')};
    flex-direction: column;
    background-color: ${theme.colors.white};
    z-index: 9998;
    padding: 1rem 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const Link = styled.a`
  color: ${({active, barColor}) =>
    active
      ? theme.colors.brand
      : barColor
      ? theme.colors.white
      : theme.colors.darkBlue};
  display: grid;
  padding-left: 2rem;
  row-gap: 1rem;
  text-decoration: none;
  text-transform: ${props => (props.caps ? 'uppercase' : 'initial')};
  position: relative;
  outline: none;
  font-size: 0.7rem;
  font-weight: 500;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% + 1rem - 2px);
    bottom: -5px;
    width: 0;
    height: 0;
    border-top: 5px solid
      ${props => (props.submenu ? theme.colors.darkBlue : 'transparent')};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  &:hover,
  &:focus {
    color: ${theme.colors.brand};

    &::after {
      border-top: 5px solid ${theme.colors.brand};

      &::after {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    row-gap: 0;
    padding: 0 35px;
    line-height: 40px;

    &::after {
      display: none;
    }
  }
`;

const SubMenu = styled(Box)`
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  ${Link}:focus + & {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    background-color: #fff;
    position: static;
    visibility: 'visible';
    display: ${props => (props.mobileVisibility ? 'block' : 'none')};
    opacity: 1;
  }
`;

const MenuInfo = styled(Box)`
  width: calc(5 / 12 * 100%);
  padding: 0 1rem 0 0;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const SubMenuWrapper = styled(Flex)`
  padding: 2.5rem 0 3rem;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    padding: 1rem 0;
  }
`;

const SubMenuCtn = styled(Box)`
  width: calc(7 / 12 * 100%);
  padding: 0 0 0 2rem;
  border-left: 3px solid ${theme.colors.cloudyBlue};

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    padding: 0;
    width: 100%;
    border-left: none;
  }
`;

const SubLink = styled.a`
  color: ${({active}) => (active ? theme.colors.brand : theme.colors.darkBlue)};
  display: block;
  padding-bottom: 0.75rem;
  text-decoration: none;
  text-transform: initial;
  font-family: ${theme.fonts.mont};
  font-size: ${props => (props.small ? '.875rem' : '1rem')};
  font-weight: 500;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
  }
`;

const MenuBtn = styled.a`
  color: ${props =>
    props.barColor ? theme.colors.white : theme.colors.darkBlue};
  display: block;
  padding-bottom: 0.75rem;
  text-decoration: none;
  display: none;
  position: relative;
  z-index: 9999;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    display: block;
  }
`;

const MenuGrid3 = styled(Grid3)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;

const MenuGrid2 = styled(Grid2)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;

const StyledIcon = styled.svg`
  fill: currentcolor;
  width: 20px;
  margin: auto;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    margin: 0 1rem 0 0;
  }
`;

const StyledLogo = styled(Logo)`
  width: 150px;
`;

const StyledLogo2 = styled(LogoBackoffice)`
  width: 150px;
`;

export const Navbar = ({activeItem, topBar, bottomBar, barColor, top}) => {
  const [menuStyle, setMenuStyle] = useState({left: '100%'});
  const [subMenuVisible, setSubMenuVisible] = useState([0, 0, 0, 0, 0, 0]);

  const handleMenuBtn = e => {
    e.preventDefault();
    const body = document.querySelector('body');
    if (menuStyle.left === '100%') {
      setMenuStyle({left: '0'});
      body.style.height = '100vh';
      body.style.width = '100vw';
      body.style.overflow = 'hidden';
    } else {
      setMenuStyle({left: '100%'});
      const arr = [0, 0, 0, 0, 0, 0];
      setSubMenuVisible(arr);
      body.style.height = 'auto';
      body.style.width = 'auto';
      body.style.overflow = 'initial';
    }
  };

  const handleMenuClick = (e, i, hasSubMenu) => {
    if (!hasSubMenu) return false;
    e.preventDefault();
    if (subMenuVisible[i] === 1) {
      const arr = [0, 0, 0, 0, 0, 0];
      setSubMenuVisible(arr);
    } else {
      const arr = [0, 0, 0, 0, 0, 0];
      arr[i] = 1;
      setSubMenuVisible(arr);
    }
  };

  return (
    <NavbarWrap>
      {topBar && (
        <TopBar barColor={barColor}>
          {topBar.items.map(item => {
            return (
              <TopLink caps key={item.url} href={item.url} barColor={barColor}>
                {item.label}
              </TopLink>
            );
          })}

          <Button onClick={topBar.onLogout}>{locale.logout}</Button>
        </TopBar>
      )}
      <BottomBar barColor={barColor}>
        <a href="/">
          {barColor ? (
            <StyledLogo2 color={theme.colors.darkBlue} />
          ) : (
            <StyledLogo />
          )}
        </a>

        <MenuContainer justify="space-between" style={menuStyle} top={top}>
          <MenuScroller>
            {bottomBar.map(
              (
                {icon: Icon, label, url, grid, hasSubMenu, submenu, height},
                i
              ) => {
                return (
                  <Box key={label} overflow="hidden">
                    <Link
                      caps
                      href={url}
                      submenu={submenu}
                      active={activeItem === url}
                      onClick={e => handleMenuClick(e, i, hasSubMenu)}
                      tabIndex="-1"
                    >
                      {Icon && <StyledIcon as={Icon} />}
                      {label}
                    </Link>
                    {hasSubMenu && submenu && (
                      <SubMenu
                        backgroundColor={theme.colors.lightGrey}
                        position="absolute"
                        top="100%"
                        left="0"
                        width="100%"
                        zIndex="100"
                        mobileVisibility={subMenuVisible[i]}
                      >
                        <Wrapper>
                          <SubMenuWrapper
                            padding="2.5rem 0 3rem"
                            align="center"
                            justify="flex-start"
                          >
                            <MenuInfo>
                              <Heading
                                level="3"
                                font={theme.fonts.mont}
                                size="1.5rem"
                                line="2rem"
                                weight="300"
                                color={theme.colors.grey}
                              >
                                {submenu.label}
                              </Heading>
                            </MenuInfo>
                            <SubMenuCtn>
                              {grid && grid === 3 && (
                                <Grid3 align="flex-start">
                                  {submenu.columns &&
                                    Object.entries(submenu.columns).map(
                                      ([key, value]) => {
                                        return (
                                          <Box key={key}>
                                            <Heading
                                              level="3"
                                              font={theme.fonts.lato}
                                              size=".875rem"
                                              line="1.14rem"
                                              weight="500"
                                              caps
                                              color={theme.colors.brand}
                                              letter="2.25px"
                                              padding="0 2rem 0 0"
                                              underline
                                              display="inline-block"
                                              height={height ? height : 'auto'}
                                            >
                                              {value.label}
                                            </Heading>
                                            <Box padding="1rem 0">
                                              {value.items.map(item => {
                                                return (
                                                  <SubLink
                                                    small={true}
                                                    href={
                                                      submenu.type
                                                        ? window.location
                                                            .origin +
                                                          '/' +
                                                          submenu.type +
                                                          '/' +
                                                          item.slug
                                                        : window.location
                                                            .origin +
                                                          '/' +
                                                          item.slug
                                                    }
                                                    key={item.slug}
                                                  >
                                                    {item.label}
                                                  </SubLink>
                                                );
                                              })}
                                            </Box>
                                          </Box>
                                        );
                                      }
                                    )}
                                </Grid3>
                              )}
                              {!grid && (
                                <Grid2 align="flex-start">
                                  {submenu.columns &&
                                    Object.entries(submenu.columns).map(
                                      ([key, value]) => {
                                        return (
                                          <Box key={key}>
                                            <Heading
                                              level="3"
                                              font={theme.fonts.lato}
                                              size=".875rem"
                                              line="1.14rem"
                                              weight="500"
                                              caps
                                              color={theme.colors.brand}
                                              letter="2.25px"
                                              padding="0 2rem 0 0"
                                              underline
                                              display="inline-block"
                                            >
                                              {value.label}
                                            </Heading>
                                            <Box padding="1rem 0">
                                              {value.items.map(item => {
                                                return (
                                                  <SubLink
                                                    href={
                                                      submenu.type
                                                        ? window.location
                                                            .origin +
                                                          '/' +
                                                          submenu.type +
                                                          '/' +
                                                          item.slug
                                                        : window.location
                                                            .origin +
                                                          '/' +
                                                          item.slug
                                                    }
                                                    key={item.slug}
                                                  >
                                                    {item.label}
                                                  </SubLink>
                                                );
                                              })}
                                            </Box>
                                          </Box>
                                        );
                                      }
                                    )}
                                </Grid2>
                              )}
                            </SubMenuCtn>
                          </SubMenuWrapper>
                        </Wrapper>
                      </SubMenu>
                    )}
                  </Box>
                );
              }
            )}
          </MenuScroller>
        </MenuContainer>
        <MenuBtn barColor={barColor} href="#" onClick={e => handleMenuBtn(e)}>
          <Menu />
        </MenuBtn>
      </BottomBar>
    </NavbarWrap>
  );
};

Navbar.propTypes = {
  activeItem: PropTypes.string,
  topBar: PropTypes.shape({
    onLogout: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
  }),
  bottomBar: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};
