import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import 'moment/locale/pt';

import theme from '../../theme';

const DATE_FORMAT = 'YYYY-MM-DD';

const StyledDatePicker = styled(DayPicker)`
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);

  .DayPicker-Day:focus {
    outline: none;
  }

  .DayPicker-Day--today {
    color: inherit;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${theme.colors.brand};
    color: #fff;
  }
`;

export const formatDate = date => moment(date).format(DATE_FORMAT);

export const parseDate = date => moment(date).toDate();

export const DatePicker = ({
  disabled = null,
  initial = new Date(),
  onSelect,
  onDayFocus,
  setFocused,
  ...rest
}) => {
  const [selected, setSelected] = useState(initial);

  return (
    <StyledDatePicker
      localeUtils={MomentLocaleUtils}
      locale="pt"
      disabledDays={disabled}
      onDayClick={day => {
        setSelected(day);
        setFocused(false);
        onSelect(day);
      }}
      onDayFocus={() => onDayFocus()}
      selectedDays={selected}
      {...rest}
    />
  );
};

DatePicker.propTypes = {
  disabled: PropTypes.object,
  initial: PropTypes.instanceOf(Date),
  onSelect: PropTypes.func.isRequired,
};
