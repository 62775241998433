import gql from 'graphql-tag';

export const getInvite = gql`
  query getInvite($id: uuid!) {
    invite: invite_by_pk(id: $id) {
      id
      email
      name
      role
      broker_id
      metadata
    }
  }
`;

export const getBroker = gql`
  query getBroker($id: Int) {
    broker(where: {id: {_eq: $id}}) {
      id
      name
      status
    }
  }
`;

export const getBrokerByToken = gql`
  query getBrokerByToken($review_token: String) {
    broker(where: {review_token: {_eq: $review_token}}) {
      id
      name
      vat
      phone
      observations
      attachments {
        field_name
        file_name
        file_size
        url
        id
      }
    }
  }
`;

export const getBrokerDetails = gql`
  query getBrokerDetails($sub: String, $id: Int) {
    broker(where: {users: {sub: {_eq: $sub}}}) {
      id
      name
      phone
      vat
      created_at
      correspondance_address_line_1
      correspondance_address_line_2
      correspondance_address_line_3
      correspondance_address_line_4
      correspondance_country
      correspondance_post_code
      invites(where: {_not: {user: {id: {_gt: 0}}}, status: {_eq: "active"}}) {
        email
        name
        role
        status
      }
      users {
        email
        forename
        id
        status
        role
      }
      f1: attachments(
        where: {field_name: {_eq: "f1"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f2: attachments(
        where: {field_name: {_eq: "f2"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f3: attachments(
        where: {field_name: {_eq: "f3"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f4: attachments(
        where: {field_name: {_eq: "f4"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f5: attachments(
        where: {field_name: {_eq: "f5"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
    }
  }
`;

export const getUser = gql`
  query getUser($sub: String) {
    user(where: {sub: {_eq: $sub}}) {
      id
      role
      contact_no
      broker {
        id
        broker_no
      }
    }
  }
`;

export const getPolicies = gql`
  query getPolicies(
    $sub: String
    $order: [policies_view_order_by!]
    $offset: Int
    $where: policies_view_bool_exp!
  ) {
    currentUser: user(where: {sub: {_eq: $sub}}) {
      role
    }
    policies_view(offset: $offset, limit: 20, order_by: $order, where: $where) {
      policy_no
      policy_holder
      policy_id
      policy_status
      updated_at
      product {
        title
      }
      user_id
      user {
        forename
      }
    }
    policies_view_aggregate(order_by: $order, where: $where) {
      aggregate {
        count
      }
    }
    product(distinct_on: integration_code) {
      id
      title
    }
    user {
      id
      forename
      role
      broker_id
    }
  }
`;

export const getPolicy = gql`
  query getPolicy($id: Int!) {
    policies_view(limit: 1, where: {policy_id: {_eq: $id}}) {
      product {
        id
        title
        questions
        slug
      }
      policy_id
      policy_no
      answers
      policy_status
      updated_at
      annual_premium
      annual_tax
      attachments(order_by: {created_at: desc}, limit: 1) {
        url
      }
      user {
        broker {
          allow_submission
        }
      }
    }
  }
`;

export const getDashboardContent = gql`
  query getDashboardContent(
    $type1: content_type_enum!
    $type2: content_type_enum!
    $type3: content_type_enum!
    $date: timestamp
    $date2: String
    $sub: String
  ) {
    campaign: content(
      limit: 1
      order_by: {publish_at: desc}
      where: {
        type: {_eq: $type1}
        status: {_eq: "published"}
        publish_at: {_lte: $date}
      }
    ) {
      id
      title
      body
    }
    newsletter: content(
      limit: 1
      order_by: {publish_at: desc}
      where: {
        type: {_eq: $type2}
        status: {_eq: "published"}
        publish_at: {_lte: $date}
      }
    ) {
      id
      title
      body
    }
    user(where: {sub: {_eq: $sub}}) {
      id
      email
      id_card
      birthdate
    }
    information: content(
      limit: 1
      order_by: {publish_at: desc}
      where: {
        type: {_eq: $type3}
        status: {_eq: "published"}
        publish_at: {_lte: $date}
      }
    ) {
      id
      title
      body
    }
    training: training(
      limit: 1
      where: {
        date: {_gte: $date2}
        status: {_eq: "published"}
        tutorial: {_eq: false}
      }
    ) {
      id
      city
      date
      hour
      name
      link
      presences(where: {user: {sub: {_eq: $sub}}}) {
        status
      }
    }
    setting {
      name
      value
    }
  }
`;

export const getChartData = gql`
  query getChartData($where: policies_view_bool_exp!) {
    policies_view(where: $where) {
      policy_id
      policy_status
      annual_premium
      annual_tax
      user_id
      user {
        forename
      }
      product {
        id
        type
        short_title
        title
        group_id
      }
    }
    group {
      id
      title
      products {
        id
        title
        short_title
        type
      }
    }
    product(where: {group_id: {_is_null: true}, status: {_eq: "published"}}) {
      id
      type
      title
      short_title
    }
  }
`;

export const getTrainings = gql`
  query getTrainings($where: training_bool_exp, $sub: String) {
    trainings: training(where: $where) {
      id
      city
      date
      hour
      name
      link
      conference_id
      duration
      teacher
      training_categories {
        category {
          name
          id
        }
      }
      presences(where: {user: {sub: {_eq: $sub}}}) {
        status
      }
    }
  }
`;

export const getTrainingsPast = gql`
  query getTrainings($order: [training_order_by!], $where: training_bool_exp) {
    pasttrainings: training(where: $where, order_by: $order) {
      city
      date
      name
      conference_video
      teacher
      training_categories {
        category {
          name
          id
        }
      }
      attachments(order_by: {created_at: desc}, limit: 1) {
        url
      }
    }
    category {
      id
      name
    }
  }
`;

export const getTutorials = gql`
  query getTrainings($where: training_bool_exp) {
    tutorials: training(where: $where) {
      name
      conference_video
    }
  }
`;

export const getCertificates = gql`
  query getTrainings($where: training_bool_exp, $sub: String) {
    trainings: training(where: $where) {
      id
      city
      date
      hour
      name
      link
      conference_id
      duration
      teacher
      presences(where: {user: {sub: {_eq: $sub}}}) {
        status
        certificate_url
      }
    }
  }
`;

export const getCommunications = gql`
  query getCommunications(
    $offset: Int
    $limit: Int
    $where: content_bool_exp!
  ) {
    content(
      where: $where
      offset: $offset
      limit: $limit
      order_by: {publish_at: desc}
    ) {
      id
      title
      body
      publish_at
      type
      attachments {
        url
        field_name
        file_size
        file_name
      }
    }
    content_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const getProcesses = gql`
  query getProcesses {
    content(where: {type: {_eq: "process"}, status: {_eq: "published"}}) {
      title
      slug
    }
  }
`;

export const getHeader = gql`
  query getHeader($sub: String) {
    user(where: {sub: {_eq: $sub}}) {
      id
      forename
      role

      broker {
        id
        name
      }
    }
    processes: content(
      where: {type: {_eq: "process"}, status: {_eq: "published"}}
    ) {
      title
      slug
    }
    products: product(
      where: {status: {_eq: "published"}}
      order_by: {order: asc}
    ) {
      title
      short_title
      type
      slug
      has_simulator
    }
  }
`;

export const getHeader2 = gql`
  query getHeader2($sub: String) {
    client(where: {sub: {_eq: $sub}}) {
      nif
      name
    }
    processes: content(
      where: {type: {_eq: "process"}, status: {_eq: "published"}}
    ) {
      title
      slug
    }
    products: product(
      where: {status: {_eq: "published"}}
      order_by: {order: asc}
    ) {
      title
      short_title
      type
      slug
      has_simulator
    }
  }
`;

export const getFooter = gql`
  query getFooter {
    processes: content(
      where: {type: {_eq: "process"}, status: {_eq: "published"}}
    ) {
      title
      slug
    }
    products: product(
      where: {status: {_eq: "published"}}
      order_by: {order: asc}
    ) {
      title
      short_title
      type
      slug
    }
  }
`;

export const getProcess = gql`
  query getProcess($slug: String) {
    content(
      where: {
        type: {_eq: "process"}
        status: {_eq: "published"}
        slug: {_eq: $slug}
      }
    ) {
      title
      body
      slug
    }
  }
`;

export const getProduct = gql`
  query getProduct($slug: String, $sub: String) {
    product(where: {status: {_eq: "published"}, slug: {_eq: $slug}}) {
      id
      title
      body
      type
      slug
      has_simulator
      attachments {
        url
        file_name
      }
    }
    user(where: {sub: {_eq: $sub}}) {
      id
      forename
      email
      broker {
        name
      }
    }
  }
`;

export const getProductClient = gql`
  query getProductClient($slug: String) {
    product(where: {status: {_eq: "published"}, slug: {_eq: $slug}}) {
      id
      title
      body
      type
      slug
      has_simulator
      attachments {
        url
        file_name
      }
    }
  }
`;

export const getSimulator = gql`
  query getSimulator($slug: String) {
    product(where: {status: {_eq: "published"}, slug: {_eq: $slug}}) {
      id
      title
      integration_code
      questions
      conditions
      helper
      type
      slug
      attachments {
        url
        file_name
      }
    }
  }
`;

export const getProfile = gql`
  query getProfile($sub: String) {
    user(where: {sub: {_eq: $sub}}) {
      id
      forename
      id_card
      id_card_expiricy
      birthdate
      email
    }
  }
`;

export const getSimulators = gql`
  query getSimulators {
    patrimony: product(
      where: {status: {_eq: "published"}, type: {_eq: "patrimony"}}
    ) {
      title
      integration_code
    }
    responsability: product(
      where: {status: {_eq: "published"}, type: {_eq: "responsability"}}
    ) {
      title
      integration_code
    }
  }
`;

export const setting = gql`
  query setting {
    setting {
      name
      value
    }
  }
`;

export const getProducts = gql`
  query getProducts {
    product {
      title
      integration_code
    }
  }
`;

export const listPolicies = gql`
  query listPolicies($data: ListPoliciesInput!) {
    listPolicies(data: $data) {
      policies
      warning
    }
  }
`;

export const listPolicy = gql`
  query listPolicies(
    $data: ListPoliciesInput!
    $data2: GetPolicyAddressInput!
  ) {
    listPolicies(data: $data) {
      policies
      warning
    }
    getPolicyAddress(data: $data2) {
      address
      error
      warning
    }
  }
`;

export const getPolicyDocument = gql`
  query getPolicyDocument($brokerNo: ID!, $eventNo: String!, $policyNo: ID!) {
    getPolicyDocument(
      data: {eventNo: $eventNo, policyNo: $policyNo, brokerNo: $brokerNo}
    ) {
      url
      warning
    }
  }
`;

export const getReceiptDocument = gql`
  query getReceiptDocument($docType: ID, $brokerNo: String!, $policyNo: ID!, $receiptNo: String!) {
    getReceiptDocument(data: {docType: $docType, brokerNo: $brokerNo, policyNo: $policyNo, receiptNo: $receiptNo}) {
      url
      warning
    }
  }
`;

export const listReceipts = gql`
  query listReceipts($data: ListReceiptsInput!) {
    listReceipts(data: $data) {
      invoices
      warning
    }
  }
`;

export const clientPolicies = gql`
  query clientPolicies($data: ListPoliciesInput!) {
    clientPolicies(data: $data) {
      policies
      warning
    }
  }
`;

export const clientPolicy = gql`
  query clientPolicy(
    $data: ListPoliciesInput!
    $data2: GetPolicyAddressInput!
  ) {
    clientPolicies(data: $data) {
      policies
    }
    getPolicyAddress(data: $data2) {
      address
      error
      warning
    }
  }
`;

export const getClient = gql`
  query getClient($sub: String) {
    client(where: {sub: {_eq: $sub}}) {
      nif
      name
      nif
      phone
      email
      id
    }
  }
`;

export const getClientDashboard = gql`
  query getClientDashboard($sub: String) {
    client(where: {sub: {_eq: $sub}}) {
      nif
      name
      nif
      phone
      email
      id
    }
    bannerContent: content(where: {type: {_eq: "process"}, status: {_eq: "published"}, slug: {_eq: "client-dashboard"}}) {
      title
      slug
      body
    }
    policiesContent: content(where: {type: {_eq: "process"}, status: {_eq: "published"}, slug: {_eq: "client_dashboard"}}) {
      title
      slug
      body
    }
  }
`;

export const getPolicyDocumentClient = gql`
  query getPolicyDocumentClient($policyNo: ID!, $eventNo: String!, $nif: ID!) {
    getPolicyDocument(
      data: {eventNo: $eventNo, policyNo: $policyNo, nif: $nif}
    ) {
      url
      warning
    }
  }
`;

export const getReceiptDocumentClient = gql`
  query getReceiptDocumentClient($docType: ID, $policyNo: ID!, $nif: ID!, $receiptNo: String!) {
    getReceiptDocument(data: {docType: $docType, policyNo: $policyNo, nif: $nif, receiptNo: $receiptNo}) {
      url
      warning
    }
  }
`;

export const clientReceipts = gql`
  query clientReceipts($data: ListReceiptsInput!) {
    clientReceipts(data: $data) {
      invoices
      warning
    }
  }
`;
