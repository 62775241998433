import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import {Box} from '../Box';
import {Text} from '../Text';
import {Heading} from '../Heading';

const List = styled.ul`
  padding: 0 0 0 1rem;
  margin: 0;
`;

const Item = styled.li`
  font-family: ${theme.fonts.mont};
  font-size: 0.875rem;
  color: ${props => (props.status ? theme.colors.success : theme.colors.error)};
  line-height: 1.8;
  font-weight: 700;
`;

export const passwordValidator = password => {
  let caps = false;
  let normal = false;
  let number = false;
  let length = false;
  let special = false;

  if (password) {
    caps = password.replace(/[^A-Z]/g, '').length > 0 ? true : false;
    normal = password.replace(/[^a-z]/g, '').length > 0 ? true : false;
    number = password.replace(/[^0-9]/g, '').length > 0 ? true : false;
    length = password.length >= 10 ? true : false;
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    special = specialChars.test(password);
  }

  return {caps, normal, number, length, special};
};

export const PasswordHelper = props => {
  const {password} = props;
  const {caps, normal, number, length, special} = passwordValidator(password);

  return (
    <Box
      margin="2rem 0"
      backgroundColor={theme.colors.lightGrey}
      padding="1rem"
    >
      <Heading
        level="3"
        size="1.2rem"
        color={theme.colors.darkBlue}
        caps
        padding="0 0 1rem"
      >
        Ajudante de escolha de password
      </Heading>
      <Text>Crie uma password segura com as seguintes regras:</Text>
      <List>
        <Item status={length}>Mínimo de 10 caractéres</Item>
        <Item status={caps}>Pelo menos uma maiúscula</Item>
        <Item status={normal}>Pelo menos uma minúscula</Item>
        <Item status={number}>Pelo menos um algarismo</Item>
        <Item status={special}>
          Pelo menos um caracter especial (por exemplo: $-_!)
        </Item>
      </List>
    </Box>
  );
};
