import React, {useContext, useState, useEffect} from 'react';

import {useQuery} from '@apollo/react-hooks';
import {Doughnut, HorizontalBar} from 'react-chartjs-2';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {AuthContext} from '../../contexts/AuthContext';
import {getUser, getChartData} from '../../graphql/queries';
import {setChartData} from './charthelper';

import {
  Box,
  Flex,
  Wrapper,
  DataTable,
  Heading,
  Title,
  Text,
  FormSelect,
  Loader,
  NotificationContainer,
  useNotification,
  ModelWithOverlay,
  FormDate,
  Button,
  theme,
} from '@innovago/ui';

import {
  FirstRowGrid,
  SecondRowBox,
  SecondRowGrid,
  PieBox,
  PieGrid,
} from './helper';

const validationSchema = Yup.object().shape({
  chart_dates: Yup.string(),
});

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const Charts = () => {
  const {user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [portalUser, setPortalUser] = useState(false);
  const [policies, setPolicies] = useState(false);
  const [whereClause, setWhereClause] = useState();
  const [modalVisibility, setModayVisibility] = useState(false);

  const [patrimonyPieData, setPatrimonyPieData] = useState(false);
  const [patrimonyColumnData, setPatrimonyColumnData] = useState(false);
  const [responsabilityPieData, setResponsabilityPieData] = useState(false);
  const [responsabilityColumnData, setResponsabilityColumnData] =
    useState(false);
  const [teamData, setTeamData] = useState(false);
  const size = useWindowSize();
  let graphsLineHeight = 20;

  if (size.width < 360) {
    graphsLineHeight = 100
  } else if (size.width < 560) {
    graphsLineHeight = 90
  } else if (size.width < 760) {
    graphsLineHeight = 50
  } else if (size.width < 960) {
    graphsLineHeight = 30
  }

  const initialWhereClause = {
    policy_status: {_neq: 'deleted'},
    product: {status: {_eq: 'published'}},
  };

  const {loading: loading1} = useQuery(getUser, {
    skip: !(user && user.username),
    variables: {
      sub: user.username,
    },
    onCompleted: data => {
      setPortalUser(data.user[0]);
      setWhereClause({
        ...initialWhereClause,
        updated_at: {
          _gte: moment().startOf('month'),
          _lte: moment().endOf('month'),
        },
      });
    },
    onError: () => notify(),
  });

  const {loading: loading2} = useQuery(getChartData, {
    skip: !portalUser,
    variables: {
      where: whereClause,
    },
    onCompleted: data => {
      setChartData(
        data,
        setPolicies,
        setPatrimonyPieData,
        setPatrimonyColumnData,
        setResponsabilityPieData,
        setResponsabilityColumnData,
        setTeamData,
        portalUser
      );
    },
    onError: () => notify(),
  });

  const formik = useFormik({
    initialValues: {
      chart_dates: 0,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      console.log(values);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      cal_start: moment().format('YYYY-MM-DD'),
      cal_end: moment().format('YYYY-MM-DD'),
    },
    onSubmit: async values => {
      const startDate = moment(values.cal_start).format('YYYY-MM-DD');
      const endDate = moment(values.cal_end).format('YYYY-MM-DD');
      setWhereClause({
        ...initialWhereClause,
        updated_at: {_gte: startDate, _lte: endDate},
      });
      setModayVisibility(false);
    },
  });

  const handleDateChange = e => {
    let currentMonth = moment().month();
    let currentYear = moment().year();
    let startDate, endDate;

    switch (e.target.value) {
      case '1':
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        break;
      case '2':
        if (currentMonth < 6) {
          startDate = moment().set({year: currentYear - 1, month: 6, date: 1});
          endDate = moment()
            .set({year: currentYear - 1, month: 11})
            .endOf('month');
        } else {
          startDate = moment().set({year: currentYear, month: 0, date: 1});
          endDate = moment().set({year: currentYear, month: 5}).endOf('month');
        }
        break;
      case '3':
        if (currentMonth - 3 < 0) {
          startDate = moment().set({year: currentYear - 1, month: 9, date: 1});
          endDate = moment()
            .set({year: currentYear - 1, month: 11})
            .endOf('month');
        } else {
          let sm = parseInt(currentMonth / 3, 10);
          startDate = moment().set({
            year: currentYear,
            month: (sm - 1) * 3,
            date: 1,
          });
          endDate = moment()
            .set({
              year: currentYear,
              month: (sm - 1) * 3 + 2,
            })
            .endOf('month');
        }
        break;

      case '4':
        startDate = moment().subtract(1, 'months').startOf('month');
        endDate = moment().subtract(1, 'months').endOf('month');
        break;

      case '5':
        setModayVisibility(true);
        break;
      default:
      case '0':
        break;
    }

    setWhereClause({
      ...initialWhereClause,
      updated_at: {_gte: startDate, _lte: endDate},
    });

    formik.setFieldValue('chart_dates', e.target.value);
  };

  const onClose = () => {
    setModayVisibility(false);
  };

  if (loading1 || loading2) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />

      {modalVisibility && (
        <ModelWithOverlay
          top="50%"
          overflow="initial"
          onClose={() => onClose()}
        >
          <form onSubmit={formik2.handleSubmit}>
            <Flex direction="column" align="center" justify="space-between">
              <FormDate
                formik={formik2}
                name="cal_start"
                label="Data de início"
                width="100%"
              />
              <FormDate
                formik={formik2}
                name="cal_end"
                label="Data de fim"
                width="100%"
              />
              <Button type="submit" filled>
                Escolher
              </Button>
            </Flex>
          </form>
        </ModelWithOverlay>
      )}
      <Box padding="2rem 0">
        <Title level="2" padding="0 0 2rem">
          Segurómetro
        </Title>

        <Box margin="0 0 3rem" backgroundColor={theme.colors.lightGrey}>
          <form onSubmit={formik.handleSubmit}>
            <FormSelect
              options={[
                {label: 'Mês corrente', value: 1},
                {label: 'Último semestre', value: 2},
                {label: 'Último trimestre', value: 3},
                {label: 'Último mês', value: 4},
                {label: 'Especificar datas', value: 5},
              ]}
              formik={formik}
              placeholder="Seleccione Datas"
              name="chart_dates"
              width="100%"
              allowEmpty={true}
              onChange={e =>
                handleDateChange(
                  e,
                  initialWhereClause,
                  whereClause,
                  setWhereClause
                )
              }
              margin="0"
            ></FormSelect>
          </form>
        </Box>

        <FirstRowGrid>
          <DataTable title="Propostas" data={policies} height="100%" />

          <PieBox padding="0 1rem 2rem">
            <Flex
              align="center"
              justify="space-between"
              padding="1.5rem 1.875rem"
              backgroundColor={theme.colors.lightGrey}
              margin="0 0 2rem"
              nowrap
            >
              <Heading
                level="2"
                font={theme.fonts.mont}
                size="1.5rem"
                weight="500"
                color={theme.colors.darkBlue}
              >
                Valor Faturado por Seguro
              </Heading>
            </Flex>
            <PieGrid>
              {patrimonyPieData && (
                <Box>
                  <Doughnut
                    width={250}
                    height={250}
                    data={patrimonyPieData}
                    options={{
                      title: {
                        display: true,
                        text: 'PATRIMÓNIO',
                        fontFamily: theme.fonts.mont,
                        fontSize: 14,
                        fontColor: theme.colors.brand,
                        fontStyle: 'normal',
                        padding: '12',
                      },
                      legend: {
                        display: false,
                      },
                    }}
                  />
                  <Text weight="700" align="center" padding="1rem 0 0">
                    {patrimonyPieData.datasets[0].data.reduce((total, value) =>
                      (parseFloat(total) + parseFloat(value)).toFixed(2)
                    , 0)}
                    €
                  </Text>
                </Box>
              )}
              {responsabilityPieData && (
                <Box>
                  <Doughnut
                    width={250}
                    height={250}
                    data={responsabilityPieData}
                    options={{
                      title: {
                        display: true,
                        text: 'RESPONSABILIDADES & SPECIALTIES',
                        fontFamily: theme.fonts.mont,
                        fontSize: 14,
                        fontColor: theme.colors.darkBlue,
                        fontStyle: 'normal',
                        padding: '12',
                      },
                      legend: {
                        display: false,
                      },
                    }}
                  />
                  <Text weight="700" align="center" padding="1rem 0 0">
                    {responsabilityPieData.datasets[0].data.reduce(
                      (total, value) =>
                        (parseFloat(total) + parseFloat(value)).toFixed(2)
                    , 0)}
                    €
                  </Text>
                </Box>
              )}
            </PieGrid>
          </PieBox>
        </FirstRowGrid>

        <SecondRowBox>
          <Flex
            align="center"
            justify="flex-start"
            padding="1.5rem 1.875rem"
            backgroundColor={theme.colors.lightGrey}
            margin="0 0 2rem"
          >
            <Heading
              level="2"
              font={theme.fonts.mont}
              size="1.5rem"
              weight="500"
              color={theme.colors.darkBlue}
            >
              Cotações vs Apólices Subscritas
            </Heading>
          </Flex>
          <SecondRowGrid>
            {patrimonyColumnData && (
              <Box>
                <HorizontalBar
                  width={450}
                  height={patrimonyColumnData.labels.length * graphsLineHeight + 50}
                  data={patrimonyColumnData}
                  options={{
                    title: {
                      display: true,
                      text: 'PATRIMÓNIO',
                      fontFamily: theme.fonts.mont,
                      fontSize: 14,
                      fontColor: theme.colors.brand,
                      fontStyle: 'normal',
                      padding: '12',
                    },
                    legend: {
                      display: true,
                    },
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          ticks: {
                            callback: function (value, index, values) {
                              value = value.toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                              });

                              if (size.width < 560) {
                                value = value.split(' ');
                              }

                              return value;
                            },
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          gridLines: {
                            drawOnChartArea: false,
                          },
                        },
                      ],
                    },
                  }}
                />
              </Box>
            )}
            {responsabilityColumnData && (
              <Box>
                <HorizontalBar
                  width={450}
                  height={responsabilityColumnData.labels.length * graphsLineHeight + 50}
                  data={responsabilityColumnData}
                  options={{
                    title: {
                      display: true,
                      text: 'RESPONSABILIDADES & SPECIALTIES',
                      fontFamily: theme.fonts.mont,
                      fontSize: 14,
                      fontColor: theme.colors.darkBlue,
                      fontStyle: 'normal',
                      padding: '12',
                    },
                    legend: {
                      display: true,
                    },

                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          ticks: {
                            callback: function (value, index, values) {
                              value = value.toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                              });

                              if (size.width < 560) {
                                value = value.split(' ');
                              }

                              return value;
                            },
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          gridLines: {
                            drawOnChartArea: false,
                          },
                        },
                      ],
                    },
                  }}
                />
              </Box>
            )}
          </SecondRowGrid>
        </SecondRowBox>

        <SecondRowBox>
          <Flex
            align="center"
            justify="flex-start"
            padding="1.5rem 1.875rem"
            backgroundColor={theme.colors.lightGrey}
            margin="0 0 2rem"
          >
            <Heading
              level="2"
              font={theme.fonts.mont}
              size="1.5rem"
              weight="500"
              color={theme.colors.darkBlue}
            >
              Valor Faturado por Colaborador
            </Heading>
          </Flex>
          <SecondRowGrid>
            {teamData && portalUser.role === 'team_admin' && (
              <HorizontalBar
                width={450}
                data={teamData}
                options={{
                  title: {
                    display: false,
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  scales: {
                    yAxes: [
                      {
                        stacked: true,
                        gridLines: {
                          drawOnChartArea: false,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        stacked: true,
                        gridLines: {
                          drawOnChartArea: false,
                        },
                        ticks: {
                          callback: function (value, index, values) {
                            return value.toLocaleString('pt-PT', {
                              style: 'currency',
                              currency: 'EUR',
                            });
                          },
                        },
                      },
                    ],
                  },
                }}
              />
            )}
          </SecondRowGrid>
        </SecondRowBox>
      </Box>
    </Wrapper>
  );
};
