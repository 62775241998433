import React, {useContext} from 'react';
import {navigate} from 'hookrouter';
import {AuthContext} from '../../contexts/AuthContext';
import {Contacts} from '@innovago/ui';
import {useQuery} from '@apollo/react-hooks';
import {setting} from '../../graphql/queries';

const Private = ({component: Component, ...rest}) => {
  const {isSignedIn, logout} = useContext(AuthContext);
  const {data: settings} = useQuery(setting, {
    fetchPolicy: 'cache-and-network',
  });

  if (!settings) return null;

  const maintenanceMode = settings.setting.find(
    setting => setting.name === 'maintenanceMode'
  ).value;

  if (maintenanceMode === 'true') {
    logout();
  }

  if (!isSignedIn()) {
    navigate('/');
    return null;
  }

  return (
    <>
      <Component {...rest} />
      <Contacts {...settings} />
    </>
  );
};

export default Private;
