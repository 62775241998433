import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const sButton = styled.button`
  display: ${props => (props.display ? props.display : 'inline-block')};
  width: ${props => (props.width ? props.width : 'auto')};
  font-family: ${theme.fonts.mont};
  font-weight: ${props => (props.weight ? props.weight : '500')};
  font-size: 14px;
  text-transform: uppercase;
  line-height: 36px;
  transition: all 0.3s;
  text-align: center;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  padding: ${props => (props.padding ? props.padding : '0 0.5rem')};
  white-space: ${props => (props.wrap ? 'initial' : 'nowrap')};
  margin: ${props => (props.margin ? props.margin : 0)};
  ${props => (props.height && `height: ${props.height};`)};
`;

const StyledButtonCustom = styled(sButton)`
  font-size: ${props => (props.size ? props.size : '14px')};
  color: ${props => (props.color ? props.color : '#fff')};
  background-color: ${props =>
    props.background ? props.background : 'transparent'};
  border: ${props =>
    props.borderColor ? '1px solid ' + props.borderColor : 'none'};
  width: ${props => (props.width ? props.width : '100%')};
  ${props => (props.transition === false && `transition: none;`)}
  ${props => (props.marginTop && `margin-top: ${props.marginTop};`)}

  &:disabled {
    background-color: ${theme.colors.brand}99;
  }
`;

const StyledButtonTransparent = styled(sButton)`
  color: ${theme.colors.brand};

  &:focus,
  &:hover {
    background-color: rgba(${theme.colors.brand}, 0.4);
  }

  &:active {
    background-color: ${theme.colors.brand};
    color: ${theme.colors.white};
  }

  &:disabled {
    color: ${theme.colors.blueyGrey};
  }
`;

const StyledButtonFilled = styled(sButton)`
  color: ${theme.colors.white};
  background-color: ${theme.colors.brand};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);

  &:focus,
  &:hover {
    background-color: ${theme.colors.rustyRedTwo};
  }

  &:active {
    background-color: ${theme.colors.melon};
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const StyledButtonOutline = styled(sButton)`
  color: ${props => (props.color ? props.color : theme.colors.brand)};
  background-color: transparent;
  line-height: 34px;
  border: 1px solid ${props => (props.color ? props.color : theme.colors.brand)};

  &:focus,
  &:hover {
    background-color: rgba(${theme.colors.brand}, 0.4);
  }

  &:active {
    background-color: ${theme.colors.brand};
    color: ${theme.colors.white};
  }

  &:disabled {
    color: ${theme.colors.blueyGrey};
    border-color: ${theme.colors.blueyGrey};
  }
`;

const StyledButtonLink = styled(sButton)`
  color: ${props => (props.color ? props.color : theme.colors.brand)};
  background-color: transparent;
  line-height: ${({line}) => (line ? line : '1')};
  display: inline-block;
  text-align: left;
  width: auto;
  display: ${({display}) => (display ? display : 'inline-block')};
  text-transform: ${props => (props.caps ? 'uppercase' : 'none')};
  border: ${props =>
    props.outline ? `1px solid ${theme.colors.brand}` : 'none'};

  &:focus,
  &:hover {
    color: ${theme.colors.rustyRedTwo};
  }
`;

export const Button = props => {
  const btnType = props.type ? props.type : 'button';
  const linkProps = {
    as: 'a',
    href: props.href,
    title: props.title ? props.title : '',
    target: props.target ? props.target : '_parent',
    onClick: props.onClick,
  };

  return props.link ? (
    <StyledButtonLink
      {...(props.href ? linkProps : '')}
      {...props}
      title={linkProps.title}
    />
  ) : props.transparent ? (
    <StyledButtonTransparent
      {...(props.href ? linkProps : '')}
      type={btnType}
      {...props}
    />
  ) : props.filled ? (
    <StyledButtonFilled
      {...(props.href ? linkProps : '')}
      type={btnType}
      {...props}
    />
  ) : props.outline ? (
    <StyledButtonOutline
      {...(props.href ? linkProps : '')}
      type={btnType}
      {...props}
    />
  ) : (
    <StyledButtonCustom
      {...(props.href ? linkProps : '')}
      type={btnType}
      {...props}
    />
  );
};
