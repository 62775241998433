/* global gtag */
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/react-hooks';
import {uuid} from 'uuidv4';

import {
  InsertBroker as InsertBrokerMutation,
  InsertBrokerAttachment as InsertBrokerAttachmentMutation,
} from '../../graphql/mutations';

import {
  Text,
  Heading,
  FormInput,
  FormFile,
  Flex,
  Button,
  NotificationContainer,
  useNotification,
  LoaderInline,
  theme,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  vat: Yup.number().required('Obrigatório'),
  phone: Yup.string().required('Obrigatório'),
  forename: Yup.string().required('Obrigatório'),
  email: Yup.string().email().required('Obrigatório'),
  f1: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f2: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f3: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f4: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f5: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
});

const filesArr = [
  {
    name: 'f1',
    file: null,
    label: 'Ficha de nomeação',
    size: false,
  },
  {
    name: 'f2',
    file: null,
    label: 'Apólice de Responsabilidade Civil',
    size: false,
  },
  {
    name: 'f3',
    file: null,
    label: 'Relatório e Contas',
    size: false,
  },
  {
    name: 'f4',
    file: null,
    label: 'Certificado de ASF de Mediador',
    size: false,
  },
  {
    name: 'f5',
    file: null,
    label: 'IBAN',
    size: false,
  },
];

export const RegisterForm = props => {
  const {onSuccess} = props;
  const [files, setFiles] = useState(filesArr);
  const [email, setEmail] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [notifications, notify] = useNotification();
  const [insertBroker] = useMutation(InsertBrokerMutation, {
    onCompleted: data => {
      setIsUploading(true);
      const {id, email, reviewToken} = data.insertBroker;
      setEmail(email.toLowerCase());

      Promise.all(files.map(file => uploadFile(file, id, reviewToken))).then(
        result => {
          setIsUploading(false);
          notify('Os dados foram enviados', 'success');
          onSuccess(email.toLowerCase());
        }
      );
    },
    onError: error => {
      if (error.message.indexOf('UQ_ebd2644947edf993840aa6ef8dc') > -1) {
        notify('Já existe uma conta com esse email');
      } else {
        notify();
      }
    },
  });

  const uploadFile = async (file, id, reviewToken) => {
    await insertBrokerAttachment({
      variables: {
        attachments: [
          {
            filename: file.filename,
            fieldname: file.name,
            filesize: file.size,
            file: file.file,
          },
        ],
        brokerId: id,
      },
      context: {
        headers: {
          'x-hasura-broker-review-token': reviewToken,
        },
      },
    });
  };

  const [insertBrokerAttachment] = useMutation(InsertBrokerAttachmentMutation, {
    onCompleted: data => {},
    onError: error => {
      //even if the file upload fails we get a success notification
      setIsUploading(false);
      notify('Os dados foram enviados', 'success');
      onSuccess(email.toLowerCase());
    },
  });

  const onFileChange = e => {
    const name = e.currentTarget.id;
    const uploadedFile = e.target.files[0];
    const reader = new FileReader();

    if (e.target.files[0].size > 5000000) {
      notify('O tamanho máximo do ficheiro são 5MB', 'error');
    } else {
      if (uploadedFile) {
        reader.readAsDataURL(uploadedFile);
        reader.addEventListener(
          'load',
          () => {
            const file = reader.result;
            formik.setFieldValue(name, {file: file, type: uploadedFile.type});

            const newFiles = files.map(item => {
              return item.name === name
                ? {
                    ...item,
                    file: file,
                    filename: uploadedFile.name,
                    size: uploadedFile.size,
                  }
                : item;
            });

            setFiles(newFiles);
          },
          false
        );
      }
    }
  };

  const handleSubmit = brokerData => {
    const d = new Date();
    insertBroker({
      variables: {
        data: brokerData,
      },
      context: {
        headers: {
          'x-hasura-broker-review-token': uuid(d.toISOString),
        },
      },
    });
    gtag('event', 'action', {
      event_category: 'click',
      event_label: 'Submeteu Nomeação',
    });
  };

  const onFileRemove = data => {
    const newFiles = files.map(item => {
      return item.name === data.name
        ? {
            ...item,
            file: null,
            filename: null,
            size: false,
          }
        : item;
    });
    setFiles(newFiles);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      vat: '',
      phone: '',
      forename: '',
      email: '',
      f1: '',
      f2: '',
      f3: '',
      f4: '',
      f5: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const brokerData = {
        name: values.name,
        vat: values.vat,
        phone: values.phone,
        email: values.email.toLowerCase(),
        invites: [
          {
            name: values.forename,
            email: values.email.toLowerCase(),
            role: 'team_admin',
          },
        ],
      };
      handleSubmit(brokerData);
    },
  });

  return (
    <div>
      <NotificationContainer {...notifications} />
      <form onSubmit={formik.handleSubmit}>
        <Heading
          level={2}
          order="3"
          color={theme.colors.darkBlue}
          font={theme.fonts.mont}
          size="1.5rem"
          padding="0 0 2rem 0"
          required
        >
          Identificação da empresa
        </Heading>
        <FormInput
          type="text"
          name="name"
          label="Empresa"
          placeholder="Insira o nome da empresa"
          border={true}
          formik={formik}
          required
          width="100%"
        />
        <FormInput
          type="text"
          name="vat"
          label="NIF da empresa"
          placeholder="NIF"
          border={true}
          width="100%"
          formik={formik}
          required
        />
        <FormInput
          type="text"
          name="phone"
          label="Telefone / Telemóvel"
          placeholder="+351 20 000 00 00"
          border={true}
          width="100%"
          formik={formik}
          required
        />
        <FormInput
          type="text"
          name="forename"
          label="Nome do administrador"
          placeholder="Insira o nome do administrador da conta empresarial"
          border={true}
          formik={formik}
          required
          width="100%"
        />
        <FormInput
          type="email"
          name="email"
          label="Email"
          placeholder="Insira o email do administrador"
          border={true}
          formik={formik}
          required
          width="100%"
        />
        <Text font={theme.fonts.lato} padding="0 0 1.5rem" weight="500">
          Anexe os 5 documentos para nomeação (tamanho máximo 10MB):
        </Text>
        {files.map((item, index) => (
          <FormFile
            key={index}
            formik={formik}
            name={item.name}
            data={item}
            size={item.size}
            onChange={onFileChange}
            onRemove={() => onFileRemove(item)}
            type="file"
            required
          />
        ))}
        <Flex justify="flex-end">
          {!isUploading && (
            <Button
              caps
              background={theme.colors.brand}
              width="33.33%"
              type="submit"
            >
              submeter
            </Button>
          )}
          {isUploading && <LoaderInline />}
        </Flex>
      </form>
    </div>
  );
};
