import React from 'react';

import {Box, Flex, StickyGrid} from '../Box';
import {Button} from '../Button';
import {Heading} from '../Heading';
import {Text} from '../Text';
import {LoaderInline} from '../Loader';
import {Search} from '../Icons';
import theme from '../../theme';

export const ClientPolicies = ({...props}) => {
  let policies = props.policies || [];

  policies = policies.sort((a,b) => b['Data de Inicio'].localeCompare(a['Data de Inicio']));

  return (props.policies === false ?
    <Box>
      <LoaderInline />
    </Box> :
    <Box>
      <Heading
        level="3"
        weight="500"
        size="1rem"
        padding="0 0 2rem"
        color={theme.colors.darkBlue}
      >
        Tem {policies.length} {policies.length === 1 ? 'apólice' : 'apólices'} em vigor
      </Heading>

      {policies.slice(0, 3).map(policy => {
        return (
          <Box key={`police_${policy['Nº da Apólice']}`} width='fit-content'>
            <StickyGrid>
              <Button
                type="button"
                color={theme.colors.darkBlue}
                href={`policies/${policy['Nº da Apólice']}`}
                width='34px'
                height='100%'
              >
                <Search />
              </Button>
              <Box>
                <Heading
                  level="3"
                  size=".875rem"
                  weight="500"
                  line="1.14"
                  letter="2.25px"
                  color={theme.colors.darkBlue}
                  caps
                >
                  {policy['Produto']}
                </Heading>
                <Text
                  line="1.75"
                  color={theme.colors.black}
                  padding="0 0 1rem"
                >
                  Nº contrato {policy['Nº da Apólice']}
                </Text>
              </Box>
            </StickyGrid>
          </Box>
      )})}

      <Flex align="center" justify="flex-end">
        <Button outline type="button" href="/policies">
          Ver mais
        </Button>
      </Flex>
    </Box>
  );
};
