const locales = {
  actions: 'Ações',
  add: 'Adicionar',
  create: 'Criar',
  edit: 'Editar',
  campaign: {
    singular: 'Campanha',
    plural: 'Campanhas',
  },
  deletedSuccessfully: 'Conteúdo eliminado com sucesso.',
  editorPlaceholder: 'Insira o conteúdo aqui',
  errorSaving: 'Erro a guardar as alterações',
  errorUpload: 'Erro a carregar o ficheiro',
  errorDeleting: 'Erro a apagar o ficheiro',
  fileListTitle: 'Título da lista',
  fileListLabel: 'Legenda do ficheiro',
  fileListChoose: 'escolha um ficheiro',
  imageDeleted: 'A imagem foi apagada com sucesso',
  forename: 'Nome',
  information: {
    singular: 'Flash',
    plural: 'Flash',
  },
  logout: 'Sair',
  newsletter: {
    singular: 'Newsletter',
    plural: 'Newsletters',
  },
  notFound: 'Não encontrado',
  notFoundInfo: 'O conteúdo que procura não existe ou foi movido.',
  process: {
    singular: 'Procedimento',
    plural: 'Procedimentos',
    route: 'processo',
  },
  administrative: 'administrativos',
  product: {
    singular: 'Produto',
    plural: 'Produtos',
    route: 'produto',
  },
  profile: 'Editar perfil',
  profileUpdated: 'Perfil actualizado.',
  publishAt: 'Data lançamento',
  required: 'Este campo é obrigatório',
  save: 'Guardar',
  title: 'Título',
  short_title: 'Título abreviado',
  training: {
    singular: 'Formação',
    plural: 'Formações',
  },
  trainingCat: {
    singular: 'Categoria de Formação',
    plural: 'Categorias de Formação',
  },
  categoryInsertionSuccess: 'Categoria inserida com sucesso',
  categoryInsertionError: 'Erro ao inserir categoria',
  categoryDeletionSuccess: 'Categoria apagada com sucesso',
  categoryDeletionError: 'Erro ao apagar categoria',
  trainer: 'Formador',
  email: 'E-mail',
  broker: {
    plural: 'Brokers',
    singular: 'Broker',
    createdAt: 'Pedido feito a',
  },
  invite: 'Convidar',
  invites: 'Convites',
  name: 'Nome',
  vat: 'NIF',
  role: 'Cargo',
  status: 'Estado',
  accountCreatedOn: 'PERFIL CRIADO EM:',
  phone: 'Telefone',
  taxNumber: 'NIF',
  companyData: 'Dados Empresa',
  approve: 'Aprovar',
  reject: 'Rejeitar',
  activate: 'Inactivar',
  inactivate: 'Activar',
  users: 'Utilizadores',
  seeDetails: 'Ver detalhes',
  backToStart: 'Voltar ao início',
  accountRequest: 'Nomeação solicitada',
  accountRequestReply:
    'Nas próximas 48 horas iremos responder ao seu pedido por email, para o endereço:',
  genericError: 'Ocorreu um erro, por favor tente mais tarde.',
  loginError: 'Dados de login inválidos.',
  savedSuccessfully: 'Alterações guardadas com sucesso.',
  surname: 'Apelido',
  personalData: 'Dados pessoais',
  passwordChange: 'Alterar password',
  oldPassword: 'Password actual',
  newPassword: 'Password nova',
  passwordUpdated: 'Password alterada',
  passwordMin: 'A password deve ter pelo menos 6 caracteres',
  draft: 'Rascunho',
  published: 'Publicado',
  unavailable: 'Indisponível',
  deleted: 'Lixo',
  accountCreationRequired: 'Nomeação solicitada',
  accountCreationRequiredIntro:
    'Para concluir o seu processo de acesso ao Portal Innova|GO, só falta criar a sua conta de administrador para a sua empresa:',
  fullName: 'Nome Completo',
  accessEmail: 'Email de acesso',
  password: 'Palavra-chave',
  confirmPassword: 'Repetir a palavra-passe',
  team_admin: 'Administrador',
  user: 'Colaborador',
  accountCreationSuccessfull: 'Conta criada com sucesso',
  goToLogin: 'Ir para o login',
  responsability: 'RC Geral e Outros Produtos',
  patrimony: 'Património',
  professional: 'Riscos Profissionais',
  productType: 'Tipo de produto',
  integrationCode: 'Código de produto',
  change: 'Alterar',
  slug: 'Slug',
  updatedAt: 'Última actualização',
  publish: 'Publicar',
  saveDraft: 'Guardar rascunho',
  schedule: 'Agendar',
  scheduled: 'Agendado',
  statusObj: {
    active: 'Activo',
    inactive: 'Inactivo',
    submitted: 'Enviado',
    pendent: 'Pendente',
    rejected: 'Rejeitado',
  },
  label: 'Legenda',
  passwordsMustMatch: 'As passwords têm de ser iguais',
  min6Chars: 'Deve ter no mínimo 6 caractéres',
  min10Chars: 'Deve ter no mínimo 10 caractéres',
  companyIdentification: 'Identificação da empresa',
  companyName: 'Designação',
  address: 'Morada',
  documents: 'Documentos',
  created_at: 'Criado a',
  recoverPassword: 'Recuperar password',
  login: 'Entrar',
  submit: 'Submeter',
  seeAllCampaigns: 'Ver todas as campanhas',
  seeAllNewsletters: 'Ver todas as newsletters',
  seeAllTrainings: 'Ver todas as formações',
  confirmInactivation:
    'Tem a certeza que quer desactivar este broker e todos os seus utilizadores?',
  brokerRefusal: 'Recusa de broker',
  brokerRefusalIntro:
    'Por favor escreva abaixo o texto que o broker irá receber para justificar a recusa ao seu pedido.',
  registerInnovagoPortal: 'Registo no portal Innova|GO',
  accountCreated: 'Conta criada',
  accountCreatedInfo: 'A sua conta foi criada. Pode agora fazer login.',
  ourCommunications: 'Consulte as nossas comunicações',
  search: 'Pesquisa',
  documentType: 'Tipo de documento',
  registrationLink: 'Link da conferência',
  tutorial: 'Tutorial?',
  date: 'Data',
  hour: 'Hora',
  place: 'Local',
  conference_url: 'URL de inscrição',
  conference_id: 'ID da conferência',
  duration: 'Duração',
  video_id: 'Vídeo ID',
  idCard: 'Cartão de cidadão',
  idCardValidity: 'Validade do Cartão de cidadão',
  birthdate: 'Data de nascimento',
  teacher: 'Formador',
  openRegistrations: 'Inscrições abertas',
  timeSchedule: 'Horário',
  register: 'Inscrever',
  registration: 'Inscrição',
  download: 'Descarregar',
  settings: 'Definições',
  simulator: {
    singular: 'Simulador',
    plural: 'Simuladores',
    action: 'Simular',
  },
  groups: {
    singular: 'Grupo',
    plural: 'Grupos',
  },
  capitalToInsure: 'Capitais a segurar',
  totalPrize: 'Prémio Total',
  insuranceClient: 'Identificação do Tomador',
  insuranceConditions: 'CONDIÇÕES DA COTAÇÃO',
  insuranceAcceptance: 'Li e aceito as condições da cotação.',
  simulationSaved: 'Simulação guardada no portal.',
  simulations: {
    SAFE: {
      FIXED: 'Cofre ancorado',
      NOTFIXED: 'Cofre não ancorado',
      NONE: 'Sem cofre',
    },
    ALARM: {
      BELLS: 'Apenas sonoro',
      'C/S': 'Ligado a central de segurança',
      MOBILE: 'Ligado ao telemóvel',
      NONE: 'Sem alarme',
    },
  },
  validation: {
    required: 'Este campo é obrigatório',
    onlyNumbers: 'Apenas algarismos',
    digits9: 'Deve ter 9 dígitos',
    max60: 'Deve ter no máximo 60 caracteres',
    max100: 'Deve ter no máximo 100 caracteres',
    integer: 'Deve ser um número inteiro',
    positive: 'Deve ser um número positivo',
    maxlength4: 'Deve ter no máximo 4 dígitos',
    maxlength12: 'Deve ter no máximo 12 dígitos',
    zip: 'Deve ser no formato ####-###',
  },
  selectPlaceholder: 'Escolha uma opção',
  policiesTable: {
    ref: 'Ref.',
    holder: 'Tomador do Seguro',
    product: 'Produto',
    date: 'Data',
    status: 'Estado',
    user: ' Colaborador',
  },
  messages: 'Mensagens',
  cotation: {
    message: {
      submitted:
        'A sua simulação/cotação encontra-se gravada mas o seguro não está colocado.',
      accepted: 'Está apólice foi colocada com sucesso.',
      expired:
        'A sua simulação/cotação necessita de ser submetida e/ou retificada. Volte a simular e retifique os dados inseridos. Em caso de dúvida contacte a equipa Innova|GO.',
      draft:
        'A sua simulação/cotação necessita de ser submetida e/ou retificada. Volte a simular e retifique os dados inseridos. Em caso de dúvida contacte a equipa Innova|GO.',
      pending:
        'A sua simulação/cotação necessita de ser submetida e/ou retificada. Volte a simular e retifique os dados inseridos. Em caso de dúvida contacte a equipa Innova|GO.',
      deleted: 'A sua simulação/cotação foi apagada.',
    },
  },
  cotationStatus: {
    accepted: 'Colocada',
    inactive: 'Inactivo',
    submitted: 'Gravada',
    pending: 'Por gravar',
    expired: 'Expirada',
    rejected: 'Rejeitada',
    draft: 'Por gravar',
    deleted: 'Apagada',
  },
  close: 'Fechar',
  has_simulator: 'Tem simulador',
  send: 'Enviar',
  ADDRESS1: 'Endereço 1',
  ADDRESS2: 'Endereço 2',
  ADDRESS3: 'Localidade 1',
  ADDRESS4: 'Localidade 2',
  POSTCODE: 'Código Postal',
  invoicedFrom: 'Período – Data de Início',
  invoicedTo: 'Período - Data de Fim',
  paidFrom: 'Pagamento – Data de Início',
  paidTo: 'Pagamento – Data de Fim',
  valueFrom: 'Valor Mínimo',
  valueTo: 'Valor Máximo',
  receiptNo: 'Nº Recibo',
  renewalFrom: 'Data de início da apólice',
  renewalTo: 'Data de fim da apólice',
  periodFrom: 'Apólice em vigor à data de',
  periodTo: 'Data de fim da apólice',
  stage: 'Estado',
  'dummy id used': 'NIF inválido'
};

export default {
  ...locales,
  fetch: key => locales[key?.trim()?.toLowerCase()] || key
};
