import React, {useState, useContext} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  Columns,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Text,
  Button,
  Download,
  List,
  FormTextarea,
  FormInput,
  ModelWithOverlay,
  theme,
  locale,
  Loader,
  Grid2,
} from '@innovago/ui';

import {AuthContext} from '../../contexts/AuthContext';
import {getProduct} from '../../graphql/queries';
import {SendProductForm} from '../../graphql/mutations';

const validationSchema = Yup.object().shape({
  nif: Yup.string()
    .matches(/^[0-9]{9}$/, locale.validation.digits9)
    .required(locale.required),
  name: Yup.string().required(locale.required),
  address1: Yup.string().required(locale.required),
  postal_code: Yup.string().required(locale.required),
  content: Yup.string().min(10, locale.min10Chars).required(locale.required),
});

const ProductBroker = props => {
  const [notifications, notify] = useNotification();
  const [showModal, setShowModal] = useState(false);
  const {user} = useContext(AuthContext);

  const {data, loading} = useQuery(getProduct, {
    fetchPolicy: 'no-cache',
    skip: !(user && user.username),
    variables: {
      slug: props.slug,
      sub: user.username,
    },
    onCompleted: () => {},
    onError: () => notify(),
  });

  const [sendProductForm] = useMutation(SendProductForm, {
    onCompleted: data => {
      if (!data.sendProductForm.error) {
        notify('Pedido enviado com sucesso', 'success');
      } else {
        notify('Ocorreu um erro. Por favor tente novamente.', 'error');
      }
      setShowModal(false);
    },
    onError: () => notify(),
  });

  const formik = useFormik({
    initialValues: {
      nif: '',
      name: '',
      address1: '',
      postal_code: '',
      content: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      sendProductForm({
        variables: {
          productId: product.id,
          content: JSON.stringify({
            'Nif Tomador': values.nif,
            'Nome Cliente': values.name,
            'Morada 1': values.address1,
            'Morada 2': values.address2,
            'Código Postal': values.postal_code,
            Resposta: values.content,
          }),
        },
      });
    },
  });

  if (loading) {
    return <Loader />;
  }

  const product = data.product[0];
  const userData = data.user[0];

  const productBody = product?.body?.length > 0 ? JSON.parse(product.body) : [];
  const columns = [];
  let index = 0;

  productBody.forEach(item => {
    if (item.type === 'delimiter') {
      index++;
      columns[index] = [];
      columns[index].push(item);
      index++;
    }
    if (item.type !== 'delimiter') {
      if (!columns[index]) {
        columns[index] = [];
      }
      columns[index].push(item);
    }
  });

  const handleGetCotation = () => {
    setShowModal(true);
  };

  const attachments = product.attachments;

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      {showModal && (
        <ModelWithOverlay top="50%" onClose={closeModal} hideClose={false}>
          <Heading
            level="2"
            font={theme.fonts.mont}
            weight="500"
            size="2rem"
            padding="0 0 1rem"
          >
            Pedido de Cotação
          </Heading>
          <form onSubmit={formik.handleSubmit}>
            <Grid2>
              <Box>
                <Text size="0.75rem" weight="700">
                  Broker
                </Text>
                <Text size="0.75rem" padding="0 0 .5rem">
                  {userData.broker.name}
                </Text>
                <Text size="0.75rem" weight="700">
                  Produto
                </Text>
                <Text size="0.75rem" padding="0 0 .5rem">
                  {product.title}
                </Text>
              </Box>
              <Box>
                <Text size="0.75rem" weight="700">
                  Utilizador
                </Text>
                <Text size="0.75rem" padding="0 0 .5rem">
                  {userData.forename}
                </Text>
                <Text size="0.75rem" weight="700">
                  Email
                </Text>
                <Text size="0.75rem" padding="0 0 .5rem">
                  {userData.email}
                </Text>
              </Box>
            </Grid2>
            <Grid2>
              <Box>
                <FormInput label="NIF Tomador" formik={formik} name="nif" />
                <FormInput label="Nome Cliente" formik={formik} name="name" />
              </Box>
              <Box>
                <FormInput label="Morada" formik={formik} name="address1" />
                <FormInput formik={formik} name="address2" />
              </Box>
            </Grid2>
            <Grid2>
              <Box />
              <Box>
                <FormInput
                  label="Código Postal"
                  formik={formik}
                  name="postal_code"
                />
              </Box>
            </Grid2>
            <FormTextarea
              name="content"
              label="Dados para o pedido de cotação"
              formik={formik}
              required
            ></FormTextarea>
            <Flex justify="flex-end">
              <Button type="submit" elevated caps filled>
                Enviar
              </Button>
            </Flex>
          </form>
        </ModelWithOverlay>
      )}

      <Wrapper>
        <Heading
          level="2"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          padding="0 0 .5rem"
          caps
        >
          {locale[product.type]}
        </Heading>
        <Heading
          level="1"
          font={theme.fonts.mont}
          weight="500"
          size="2rem"
          color={theme.colors.brand}
          letter=".24px"
          padding="0 0 2.5rem"
        >
          {product.title}
        </Heading>
        <Columns align="flex-start" justify="space-between">
          {columns.map((column, index) => {
            return (
              <Box key={`column${index}`} height="100%">
                {column.map((item, index) => {
                  switch (item.type) {
                    case 'header':
                      return (
                        <Heading
                          key={`product${index}`}
                          level={item.data.level}
                          color={theme.colors.darkBlue}
                          size="1.5rem"
                          weight="500"
                          font={theme.fonts.mont}
                          padding="0 0 1rem"
                        >
                          {item.data.text}
                        </Heading>
                      );

                    case 'list':
                      return (
                        <List
                          key={`product${index}`}
                          type={item.data.style}
                          items={item.data.items}
                          color={theme.colors.black}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          line="1.75"
                          listIndex={index}
                        />
                      );

                    case 'delimiter':
                      return (
                        <Box
                          key={`product${index}`}
                          width="1px"
                          height="100%"
                          backgroundColor={theme.colors.grey}
                        />
                      );

                    case 'fileList':
                      return (
                        <Box
                          key={`product${index}`}
                          align="center"
                          justify="space-between"
                          width="100%"
                        >
                          <Text
                            font={theme.fonts.lato}
                            size="1rem"
                            line="1.75"
                            letter="2.25px"
                            color={theme.colors.darkBlue}
                            nowrap
                          >
                            {item.data.title}
                          </Text>
                          {item.data.items.map((item, index) => {
                            const attachment = attachments.filter(
                              attach =>
                                attach.file_name ===
                                item.name
                                  .replace('amp;', '')
                                  .replace('nbsp;', ' ')
                            );
                            if (attachment[0]) {
                              return (
                                <Button
                                  key={`download_${index}`}
                                  type="link"
                                  href={attachment[0].url}
                                  target="_blank"
                                >
                                  <Flex justify="flex-start" padding=".5rem 0">
                                    <Download color={theme.colors.brand} />
                                    <Text
                                      font={theme.fonts.mont}
                                      size=".875rem"
                                      weight="500"
                                      color={theme.colors.brand}
                                      transform="uppercase"
                                      padding="0 1rem"
                                    >
                                      {item.label}
                                    </Text>
                                  </Flex>
                                </Button>
                              );
                            } else {
                              return false;
                            }
                          })}
                        </Box>
                      );

                    default:
                    case 'paragraph':
                      return (
                        <Text
                          key={`product${index}`}
                          html={item.data.text}
                          color={theme.colors.black}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          padding="0 0 1rem"
                          line="1.75rem"
                        />
                      );
                  }
                })}
                {index === 2 && (
                  <Box margin="2rem 0 0">
                    {product.has_simulator ? (
                      <Button filled href={`/simuladores/${product.slug}`}>
                        Simular
                      </Button>
                    ) : (
                      <Button filled onClick={() => handleGetCotation()}>
                        Pedir Cotação
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            );
          })}
        </Columns>
      </Wrapper>
    </Box>
  );
};

export default ProductBroker;
