import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const Text1 = styled.p`
  font-family: ${props => (props.font ? props.font : 'Montserrat')};
  font-size: ${props => (props.size ? props.size : '1rem')};
  font-weight: ${props => (props.weight ? props.weight : 400)};
  line-height: ${props => (props.line ? props.line : '28px')};
  letter-spacing: ${props => (props.letter ? props.letter : 0)};
  color: ${props => (props.color ? props.color : theme.colors.black)};
  padding: ${props => (props.padding ? props.padding : 0)};
  text-transform: ${props => (props.transform ? props.transform : 'none')};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  text-align: ${props => (props.align ? props.align : 'left')};
  display: ${props => (props.display ? props.display : 'block')};
  margin: 0;

  a {
    color: ${theme.colors.brand};
  }
`;

export const Text = props => {
  if (props.html) {
    return (
      <Text1 {...props} dangerouslySetInnerHTML={{__html: props.html}}></Text1>
    );
  } else {
    return <Text1 {...props}></Text1>;
  }
};
