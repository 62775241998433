import React from 'react';
import {useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {requestPasswordReset as requestPasswordResetMutation} from '../../graphql/mutations';
import {clientRequestPasswordReset as clientRequestPasswordResetMutation} from '../../graphql/mutations';

import {
  Box,
  Flex,
  WrapperSmall,
  Text,
  Heading,
  FormInput,
  ModelWithOverlay,
  NotificationContainer,
  useNotification,
  Button,
  theme,
  locale,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Obrigatório'),
});
const clientValidationSchema = Yup.object().shape({
  nif: Yup.string().matches(/^[0-9]{9}$/, locale.validation.digits9).required('Obrigatório'),
});

const RecoverPassword = props => {
  const {onClose, loginType} = props;
  const [notifications, notify] = useNotification();

  const [requestPassword] = useMutation(requestPasswordResetMutation, {
    onCompleted: () =>
      notify(
        'Se o email existir na nossa base de dados, ser-lhe-à enviado um e-mail com um link para que possa fazer reset à sua password',
        'success'
      ),
    onError: () => notify(),
  });
  const [clientRequestPassword] = useMutation(clientRequestPasswordResetMutation, {
    onCompleted: (data) => {
      const via = data.clientRequestPasswordReset === 'sms' ? 'uma sms' : 'um e-mail';

      notify(
        `Se o registo existir na nossa base de dados, ser-lhe-à enviado ${via} com um link para que possa fazer reset à sua password`,
        'success'
      )
    },
    onError: () => notify(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      nif: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginType === 'broker' ? validationSchema : clientValidationSchema,
    onSubmit: async values => {
      if (loginType === 'broker') {
        requestPassword({
          variables: {
            email: values.email.toLowerCase(),
          },
        });
      }
      else {
        clientRequestPassword({
          variables: {
            nif: values.nif,
          },
        });
      }
    },
  });

  return (
    <ModelWithOverlay top="50%" onClose={onClose}>
      <NotificationContainer {...notifications} />
      <Box padding="2rem">
        <WrapperSmall>
          <form onSubmit={formik.handleSubmit}>
            <Heading level="1" size="1.5rem" weight="500" padding="0 0 1rem">
              Recuperar palavra-passe
            </Heading>
            <Text font="Lato" size="1.125rem" padding="0 0 2rem">
            {loginType === 'broker' ?
              'Insira o seu email para que lhe enviarmos o link de recuperação da palavra-passe.' :
              'Insira o seu nif para que lhe enviarmos o link de recuperação da palavra-passe.'}
            </Text>
            {loginType === 'broker' ?
              <FormInput
                required
                name="email"
                label="E-mail"
                placeholder="Insira o email que usa para aceder ao Portal"
                formik={formik}
              /> :
              <FormInput
                required
                name="nif"
                label="NIF"
                placeholder="Insira o nif que usa para aceder ao Portal"
                formik={formik}
              />}
            <Flex justify="flex-end">
              <Button
                type="submit"
                elevated
                caps
                background={theme.colors.brand}
                width="33.33%"
              >
                submeter
              </Button>
            </Flex>
          </form>
        </WrapperSmall>
      </Box>
    </ModelWithOverlay>
  );
};

export default RecoverPassword;
