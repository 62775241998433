import React from 'react';
import {useMutation} from '@apollo/react-hooks';
import styled from 'styled-components';

import {
  Box,
  Flex,
  Grid2,
  BgImage,
  Wrapper,
  Title,
  Subtitle,
  Text,
  Button,
  theme,
  locale,
  NotificationContainer,
  useNotification,
} from '@innovago/ui';

import {InsertPresence as insertPresenceMutation} from '../../graphql/mutations';

const TrainingBgImage = styled(BgImage)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    background-position: center top;
    background-size: auto;
    padding: 20% 0 0;
  }
`;

const BoxTraining = styled(Box)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    padding: 0;
  }
`;

const TableCell = styled(Grid2)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Trainings = ({content, image, userID}) => {
  const {id, name, city, hour, date, presences, link} = content;
  const [notifications, notify] = useNotification();

  const handleRegistration = trainingID => {
    insertPresence({
      variables: {
        training_id: String(trainingID),
        user_id: String(userID),
      },
    });
  };

  const [insertPresence] = useMutation(insertPresenceMutation, {
    onCompleted: data => {
      notify('O registo foi feito com sucesso', 'success');
    },
    onError: error => {
      console.log(error);
      notify();
    },
    refetchQueries: ['getTrainings'],
  });

  return (
    <Box backgroundColor="rgba(194,216,219,.2)" padding="3rem 0 3.5rem 0">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <TrainingBgImage
          size="auto"
          bgPosition="right top"
          height="auto"
          image={image.url}
        >
          <Grid2 align="flex-start">
            <BoxTraining padding="3.5rem 0 0">
              <Subtitle
                level="3"
                font={theme.fonts.mont}
                color={theme.colors.darkBlue}
                padding="0 0 .25rem"
              >
                Formação
              </Subtitle>
              <Title
                level="2"
                font={theme.fonts.mont}
                color={theme.colors.brand}
                padding="0 0 1.5rem"
              >
                {name}
              </Title>
              <Text font={theme.fonts.lato} padding="0 0 1rem 0">
                Sabemos que os nossos seguros são especializados e, muitas
                vezes, soluções diferentes daquelas que habitualmente gere no
                seu dia-a-dia. Por isso compreendemos que por vezes é complicado
                ter um conhecimento aprofundado de todos os produtos
                disponibilizados pelo mercado segurador.
              </Text>
              <Text font={theme.fonts.lato} padding="0 0 1rem 0">
                Sendo que um dos nossos pilares principais é proporcionar aos
                nossos parceiros ferramentas e apoio para o seu negócio,
                queremos facilitar o seu trabalho, convidando-o a participar nas
                nossas formações ao longo deste ano.
              </Text>
              <Button
                padding={'0 2rem'}
                size={'.875rem'}
                color={theme.colors.brand}
                href="/trainings"
                filled
              >
                {locale.seeAllTrainings}
              </Button>
            </BoxTraining>
            <BoxTraining padding="10rem 0 0 0">
              <Box backgroundColor={theme.colors.cloudyBlue2}>
                <Text font={theme.fonts.lato} align="center" line="44px">
                  {locale.openRegistrations}
                </Text>
              </Box>
              <Box
                backgroundColor={theme.colors.white}
                borderBottom={`2px dashed ${theme.colors.cloudyBlue}`}
                padding="0 .5rem"
              >
                <TableCell>
                  <Text
                    font={theme.fonts.lato}
                    weight="500"
                    size=".875rem"
                    letter="2.25px"
                    transform="uppercase"
                    color={theme.colors.grey}
                    line="44px"
                  >
                    {locale.training.singular}
                  </Text>
                  <Text font={theme.fonts.lato} line="44px">
                    {name}
                  </Text>
                </TableCell>
              </Box>
              <Box
                backgroundColor={theme.colors.white}
                borderBottom={`2px dashed ${theme.colors.cloudyBlue}`}
                padding="0 .5rem"
              >
                <TableCell>
                  <Text
                    font={theme.fonts.lato}
                    weight="500"
                    size=".875rem"
                    letter="2.25px"
                    transform="uppercase"
                    color={theme.colors.grey}
                    line="44px"
                  >
                    {locale.date}
                  </Text>
                  <Text font={theme.fonts.lato} line="44px">
                    {date}
                  </Text>
                </TableCell>
              </Box>
              <Box
                backgroundColor={theme.colors.white}
                borderBottom={`2px dashed ${theme.colors.cloudyBlue}`}
                padding="0 .5rem"
              >
                <TableCell>
                  <Text
                    font={theme.fonts.lato}
                    weight="500"
                    size=".875rem"
                    letter="2.25px"
                    transform="uppercase"
                    color={theme.colors.grey}
                    line="44px"
                  >
                    {locale.timeSchedule}
                  </Text>
                  <Text font={theme.fonts.lato} line="44px">
                    {hour}
                  </Text>
                </TableCell>
              </Box>
              <Box
                backgroundColor={theme.colors.white}
                borderBottom={`2px solid ${theme.colors.cloudyBlue2}`}
                padding="0 .5rem"
              >
                <TableCell>
                  <Text
                    font={theme.fonts.lato}
                    weight="500"
                    size=".875rem"
                    letter="2.25px"
                    transform="uppercase"
                    color={theme.colors.grey}
                    line="44px"
                  >
                    {locale.place}
                  </Text>
                  <Text font={theme.fonts.lato} line="44px">
                    {city}
                  </Text>
                </TableCell>
              </Box>
              <Flex justify="flex-end" padding="2rem 0 0">
                {presences.length === 0 ? (
                  <Button
                    size={'.875rem'}
                    color={theme.colors.brand}
                    filled
                    caps
                    onClick={() => handleRegistration(id)}
                  >
                    INSCREVER
                  </Button>
                ) : (
                  link && (
                    <Button filled href={link} target="_blank">
                      Ir para a conferência
                    </Button>
                  )
                )}
              </Flex>
            </BoxTraining>
          </Grid2>
        </TrainingBgImage>
      </Wrapper>
    </Box>
  );
};
