import React from 'react';
import {useMutation} from '@apollo/react-hooks';

import {
  Box,
  Flex,
  Button,
  Heading,
  Text,
  locale,
  theme,
  NotificationContainer,
  useNotification,
} from '@innovago/ui';

import {sendPolicyEmail as sendPolicyEmailMutation} from '../../graphql/mutations';

export const SimulatorsStep3 = ({policyID, pdf}) => {
  const [notifications, notify] = useNotification();

  const sendByEmailHandler = () => {
    sendPolicyEmail({
      variables: {
        policyId: policyID,
      },
    });
  };

  const [sendPolicyEmail] = useMutation(sendPolicyEmailMutation, {
    onCompleted: data => {
      if (data.sendPolicyEmail) {
        notify('O email foi enviado com sucesso', 'success');
      } else {
        notify();
      }
    },
    onError: error => {
      console.log(error);
      notify();
    },
  });

  return (
    <Box>
      <NotificationContainer {...notifications} />
      <Heading
        level="3"
        background={theme.colors.lightGrey}
        color={theme.colors.darkBlue}
        size="1.5rem"
        line="3.5rem"
        order="3"
        alignItem="center"
        justify="flex-start"
        padding="0 1.5rem 0 1rem"
        margin="0 0 1.5rem"
        display="grid"
        align="left"
        gridColumns="60px 1fr 20px"
        backgroundBefore={theme.colors.brand}
        colorBefore={theme.colors.white}
        sizeBefore="1.125rem"
        widthBefore="2rem"
        heightBefore="2rem"
        lineBefore="2rem"
      >
        Finalizar Processo
      </Heading>
      <Heading
        level="3"
        color={theme.colors.darkBlue}
        padding="1.5rem 0 1rem"
        weight="500"
        size="1.125rem"
        font={theme.fonts.mont}
      >
        {locale.simulationSaved}
      </Heading>
      <Text padding="0 0 3rem" size=".875rem">
        Pode consultar a simulação na página de consultas, onde está guardada
        com o estado GRAVADO.
      </Text>
      <Text padding="0 0 3rem" size=".875rem">
        Pode fazer download da cotação para ser assinada pelo Tomador (a mesma
        inclui a proposta de seguro já pré-preenchida) ou enviá-la por email.
      </Text>
      <Flex justify="flex-end">
        <Button margin="0 0 0 1rem" filled onClick={() => sendByEmailHandler()}>
          Enviar por email
        </Button>
        <Button margin="0 0 0 1rem" link filled target="_blank" href={pdf}>
          Download
        </Button>
      </Flex>
    </Box>
  );
};
