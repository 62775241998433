import React, {useState, useContext} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import {
  Box,
  Flex,
  Grid3,
  Wrapper,
  Heading,
  Loader,
  Table,
  Badge,
  Button,
  Search,
  Pagination,
  FormInput,
  FormSelect,
  NotificationContainer,
  useNotification,
  locale,
  theme,
  formatDate,
} from '@innovago/ui';

import {getPolicies} from '../../graphql/queries';
import {AuthContext} from '../../contexts/AuthContext';

const validationSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
});

const FilterGrid = styled(Grid3)`
  align-items: flex-start;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

const ActionButton = styled(Button)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 0.85rem;
    line-height: 1.75;
  }
`;

export const Cotations = () => {
  const [notifications, notify] = useNotification();
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({updated_at: 'desc'});
  const {user} = useContext(AuthContext);

  const [policy_status, setPolicyStatus] = useState('0');
  const [product_id, setProductId] = useState('0');
  const [userId, setUserId] = useState('0');
  const [search, setSearch] = useState(null);

  let where = {};

  if (product_id !== '0') {
    where.product_id = {_eq: product_id};
  }

  if (userId !== '0') {
    where.user_id = {_eq: parseInt(userId, 10)};
  }

  const allStates = ['draft', 'pending', 'expired', 'submitted', 'accepted'];

  if (policy_status === '0') {
    where.policy_status = {
      _in: allStates,
      _neq: 'deleted',
    };
  } else {
    where.policy_status = {
      _in: policy_status?.split(',') || allStates,
      _neq: 'deleted',
    };
  }

  if (search) {
    where._or = [
      {policy_holder: {_ilike: `%${search || ''}%`}},
      {policy_no: {_eq: parseInt(search, 10) || -1}},
    ];
  }

  const queryVars = {
    order,
    offset,
    where,
    sub: user.username,
  };

  const {data: policyData, loading} = useQuery(getPolicies, {
    fetchPolicy: 'no-cache',
    skip: !(user && user.username),
    variables: queryVars,
    onError: error => {
      notify();
    },
  });

  const formik = useFormik({
    initialValues: {
      search: '',
      status: 0,
      product_id: 0,
      policy_status: 0,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      setOffset(0);
      values.search.trim().length === 0
        ? setSearch(null)
        : setSearch(values.search);
    },
  });

  const handleExport = () => {
    if (policyData.policies_view.length === 0) return false;

    let csvContent = 'data:text/csv;charset=utf-8,';
    policyData.policies_view.forEach((item, index) => {
      let status;
      switch (item.policy_status) {
        case 'accepted':
          status = locale.cotationStatus[item.policy_status];
          break;
        case 'submitted':
          status = locale.cotationStatus[item.policy_status];
          break;
        case 'expired':
          status = locale.cotationStatus[item.policy_status];
          break;
        case 'pending':
        case 'draft':
        default:
          status = locale.cotationStatus[item.policy_status];
          break;
      }
      let dataString =
        item.policy_no +
        ',' +
        item.user.forename +
        ',' +
        item.policy_holder +
        ',' +
        item.product.title +
        ',' +
        formatDate(item.updated_at) +
        ',' +
        status;
      csvContent +=
        index < policyData.policies_view.length
          ? dataString + '\n'
          : dataString;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      'upload_data' + new Date().getTime() + '.csv'
    );
    link.click();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          weight="500"
          caps
          padding="0 0 .25rem"
        >
          Cotações
        </Heading>
        <Heading
          level={1}
          color={theme.colors.brand}
          size="2rem"
          font={theme.fonts.mont}
          padding="0 0 2rem"
        >
          Consulte as suas propostas
        </Heading>
        <Box
          backgroundColor="rgba(134,177,184,.1)"
          margin="0 0 1rem"
          padding="1.125rem 1rem"
        >
          <form onSubmit={formik.handleSubmit}>
            <FilterGrid>
              <Box>
                <FormSelect
                  formik={formik}
                  label={locale.product.singular}
                  name="product_id"
                  options={[
                    {label: 'Todos', value: 0},
                    ...policyData.product.map(p => {
                      return {label: p.title, value: p.id};
                    }),
                  ]}
                  width="100%"
                  allowEmpty={false}
                  onChange={e => {
                    setProductId(e.target.value);
                    setOffset(0);
                    formik.setFieldValue('product_id', e.target.value);
                    formik.handleSubmit();
                  }}
                />
              </Box>
              <Box>
                <FormSelect
                  formik={formik}
                  label={locale.status}
                  name="policy_status"
                  options={[
                    {label: 'Todos', value: '0'},
                    {
                      label: 'Por gravar',
                      value: 'draft,pending',
                    },
                    {label: 'Expirada', value: 'expired'},
                    {label: 'Gravada', value: 'submitted'},
                    {label: 'Colocada', value: 'accepted'},
                  ]}
                  allowEmpty={false}
                  width="100%"
                  onChange={e => {
                    setPolicyStatus(e.target.value);
                    setOffset(0);
                    formik.setFieldValue('policy_status', e.target.value);
                    formik.handleSubmit();
                  }}
                />
              </Box>
              <Box>
                <FormSelect
                  formik={formik}
                  label={locale.users}
                  name="user_id"
                  options={[
                    {label: 'Todos', value: '0'},
                    ...policyData.user.map(u => {
                      return {label: u.forename, value: u.id};
                    }),
                  ]}
                  allowEmpty={false}
                  width="100%"
                  onChange={e => {
                    setUserId(e.target.value);
                    setOffset(0);
                    formik.setFieldValue('user_id', e.target.value);
                    formik.handleSubmit();
                  }}
                />
              </Box>
              <Flex align="center" nowrap>
                <FormInput
                  type="text"
                  name="search"
                  label={locale.search}
                  placeholder="Nome/Número apólice"
                  border={true}
                  width="100%"
                  formik={formik}
                  margin="0"
                />
                <Box>
                  <Button type="submit" color={theme.colors.darkBlue}>
                    <Search />
                  </Button>
                </Box>
              </Flex>
            </FilterGrid>
            {policyData.policies_view.length > 0 && (
              <Flex justify="flex-end" margin="0 0 1rem 0">
                <Button
                  filled
                  onClick={() => {
                    handleExport();
                  }}
                >
                  Exportar para CSV
                </Button>
              </Flex>
            )}
          </form>
        </Box>
        <Table
          size=".875rem"
          loading={loading}
          columns={[
            {
              label: locale.policiesTable.ref,
              property: 'policy_no',
              sortable: true,
              format: item => item.policy_no,
            },
            {
              label: locale.policiesTable.user,
              property: 'user_id',
              sortable: true,
              format: item => item.user?.forename,
            },
            {
              label: locale.policiesTable.holder,
              property: 'policy_holder',
              sortable: true,
              format: item => item.policy_holder,
            },
            {
              label: locale.policiesTable.product,
              property: 'product.title',
              sortable: true,
              format: item => item.product?.title || 'Produto indisponível',
            },
            {
              label: locale.policiesTable.date,
              property: 'updated_at',
              sortable: true,
              format: item => formatDate(item.updated_at),
              whitespace: 'nowrap',
            },
            {
              label: locale.policiesTable.status,
              property: 'policy_status',
              sortable: true,
              format: item => {
                switch (item.policy_status) {
                  case 'accepted':
                    return (
                      <Badge
                        whitespace="nowrap"
                        label={locale.cotationStatus[item.policy_status]}
                        type="active"
                      />
                    );
                  case 'submitted':
                    return (
                      <Badge
                        whitespace="nowrap"
                        label={locale.cotationStatus[item.policy_status]}
                        type="submitted"
                      />
                    );
                  case 'expired':
                    return (
                      <Badge
                        whitespace="nowrap"
                        label={locale.cotationStatus[item.policy_status]}
                        type="pending"
                      />
                    );
                  case 'pending':
                  case 'draft':
                  default:
                    return (
                      <Badge
                        whitespace="nowrap"
                        label={locale.cotationStatus[item.policy_status]}
                        type="draft"
                      />
                    );
                }
              },
            },
            {
              label: locale.actions,
              format: item => (
                <ActionButton filled href={`/quotations/${item.policy_id}`}>
                  {locale.seeDetails}
                </ActionButton>
              ),
            },
          ]}
          data={policyData.policies_view}
          sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
          onClickSort={sort => {
            if (sort.column.split('.').length > 1) {
            } else {
              setOrder({
                [sort.column]: sort.order,
              });
            }
          }}
        />
        {policyData && policyData.policies_view_aggregate.aggregate && (
          <Pagination
            pageSize={20}
            current={offset / 20 + 1}
            total={policyData.policies_view_aggregate.aggregate.count}
            onChange={page => setOffset(20 * (page - 1))}
          />
        )}
      </Wrapper>
    </Box>
  );
};
