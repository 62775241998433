import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/react-hooks';

import {
  Box,
  Flex,
  Heading,
  FormInput,
  Button,
  theme,
  locale,
  NotificationContainer,
  useNotification,
} from '@innovago/ui';

import {CheckNIF as CheckNIFMutation} from '../../graphql/mutations';

const validationSchemaStep1 = Yup.object().shape({
  POLICYHOLDERID: Yup.string()
    .required(locale.required)
    .matches(/^[0-9]{9}$/, locale.validation.digits9),
});

export const SimulatorsStep1 = ({
  setCurrentStep,
  brokerNo,
  setNif,
  setHolder,
  notifyAfter
}) => {
  const [notifications, notify] = useNotification();
  const formikStep1 = useFormik({
    initialValues: {POLICYHOLDERID: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchemaStep1,
    onSubmit: async values => {
      const args = {
        brokerNo: String(brokerNo),
        nif: String(values.POLICYHOLDERID),
      };
      handleSubmit(args);
    },
  });

  const [checkNif] = useMutation(CheckNIFMutation, {
    onCompleted: data => {
      if (data.checkNif.success) {
        if (data.checkNif.valid) {
          if (data.checkNif?.warning) {
            notifyAfter(locale.fetch(data.checkNif.warning), 'warning');
          }

          setHolder(JSON.parse(data.checkNif.policyholder));
          setCurrentStep(2);
        } else {
          notify(locale.fetch(data.checkNif.error));
        }
      } else {
        notify();
      }
    },
    onError: () => notify(),
  });

  const handleSubmit = args => {
    setNif(args.nif);
    checkNif({
      variables: {
        args: args,
      },
    });
  };

  return (
    <Box>
      <NotificationContainer {...notifications} />
      <Heading
        level="3"
        background={theme.colors.lightGrey}
        color={theme.colors.darkBlue}
        size="1.5rem"
        line="3.5rem"
        order="1"
        alignItem="center"
        justify="flex-start"
        padding="0 1.5rem 0 1rem"
        margin="0 0 1.5rem"
        display="grid"
        align="left"
        gridColumns="60px 1fr 20px"
        backgroundBefore={theme.colors.brand}
        colorBefore={theme.colors.white}
        sizeBefore="1.125rem"
        widthBefore="2rem"
        heightBefore="2rem"
        lineBefore="2rem"
      >
        Validação do cliente
      </Heading>
      <Box>
        <form onSubmit={formikStep1.handleSubmit}>
          {}
          <FormInput
            labelFont={theme.fonts.mont}
            labelMedium
            margin="0 0 1.5rem"
            name="POLICYHOLDERID"
            label={locale.vat}
            formik={formikStep1}
            placeholder="123456789"
          />
          <Flex align="center" justify="flex-end">
            <Button outline type="submit">
              Continuar
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};
