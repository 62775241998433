import React, {useState} from 'react';

import {
  Box,
  Grid2,
  Wrapper,
  Modal,
  Heading,
  Text,
  Loader,
  NotificationContainer,
  useNotification,
  theme,
} from '@innovago/ui';

import {useQuery} from '@apollo/react-hooks';

import {ReviewIntro} from './reviewIntro';
import {ReviewForm} from './reviewForm';

import {getBrokerByToken} from '../../graphql/queries';

export const ReviewBroker = ({id, reviewToken}) => {
  const [reviewData, setReviewData] = useState(0);
  const [notifications, notify] = useNotification();

  const {data, loading} = useQuery(getBrokerByToken, {
    skip: !reviewToken,
    variables: {
      review_token: reviewToken,
    },
    context: {
      headers: {
        'x-hasura-broker-review-token': reviewToken,
      },
    },
    onError: () => notify(),
  });

  function showLightbox() {
    setReviewData(true);
  }

  if (loading) {
    return <Loader />;
  }

  const observations = data.broker[0].observations;

  if (reviewData) {
    return (
      <Wrapper>
        <Modal>
          <Heading
            level={2}
            color={theme.colors.brand}
            weight={500}
            padding="0 0 1rem"
          >
            Revisão de dados
          </Heading>
          <Text padding="0 0 1rem">
            Os seus dados corrigidos foram enviados para a Innovarisk e em breve
            irá obter uma resposta.
          </Text>
          <Text font="Lato" size="1.125rem" weight="700" padding="0 0 2rem">
            Obrigado.
          </Text>
        </Modal>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <NotificationContainer {...notifications} />
        <Grid2 align="flex-start" padding="3rem 0 0 0">
          <Box>
            <ReviewIntro info={observations} />
          </Box>
          <Box elevated padding="1.5rem 1.5rem 2rem 1rem" margin="0 0 3rem">
            <ReviewForm
              showLightbox={showLightbox}
              id={id}
              reviewToken={reviewToken}
              broker={data.broker[0]}
            />
          </Box>
        </Grid2>
      </Wrapper>
    );
  }
};
