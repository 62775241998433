/* global Rollbar */
import React, {useContext} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {usePath} from 'hookrouter';

import {
  Navbar,
  Loader,
  locale,
  Home,
  Products,
  Processes,
  Trainings,
  Proposals,
  Communications,
} from '@innovago/ui';

import {AuthContext} from '../../contexts/AuthContext';

import {getHeader} from '../../graphql/queries';

export const HeaderBroker = () => {
  const path = usePath();
  const {logout, user} = useContext(AuthContext);
  const paymentsSubMenu = {};
  const productSubMenu = {};
  let simulatorsSubMenu = {};

  const {data, loading} = useQuery(getHeader, {
    skip: !(user && user.username),
    variables: {
      sub: user && user.username,
    },
    onError: err => console.log(err),
    onCompleted: data => {
      if (!data?.user[0]) {
        logout();
      }
    }
  });

  if (loading || !data?.user[0]) {
    return <Loader />;
  }

  if (data) {
    paymentsSubMenu.type = 'pagamentos';
    paymentsSubMenu.label =
      'Toda a informação sobre a regularização dos seguros.';
    paymentsSubMenu.columns = [];
    paymentsSubMenu.columns['process'] = {};
    paymentsSubMenu.columns['process'].label =
      locale.process.plural + ' ' + locale.administrative;
    paymentsSubMenu.columns['process'].items = data.processes.filter(
      process => process.slug === 'pagamentos'
    );

    const registeredTypes = [];
    let index = 0;
    productSubMenu.type = 'produto';
    productSubMenu.label = 'Conheça a nossa oferta de produtos';
    productSubMenu.columns = [];
    data.products.forEach(product => {
      const typeIsPresent = registeredTypes.filter(type => {
        return type.type === product.type;
      });

      if (typeIsPresent.length === 0) {
        registeredTypes.push({type: product.type, index: index});
        productSubMenu.columns[index] = {};
        productSubMenu.columns[index].label = locale[product.type];
        productSubMenu.columns[index].items = [];
        productSubMenu.columns[index].items.push({
          label: product.short_title ? product.short_title : product.title,
          slug: product.slug,
        });
        index++;
      } else {
        productSubMenu.columns[typeIsPresent[0].index].items.push({
          label: product.short_title ? product.short_title : product.title,
          slug: product.slug,
        });
      }
    });

    simulatorsSubMenu = Object.assign(simulatorsSubMenu, productSubMenu);
    simulatorsSubMenu.type = 'simuladores';
    simulatorsSubMenu.label =
      'Simule qualquer um dos nossos produtos em apenas 3 simples passos.';
    const registeredTypes2 = [];
    simulatorsSubMenu.columns = [];
    data.products.forEach(product => {
      if (product.has_simulator) {
        const typeIsPresent = registeredTypes2.filter(type => {
          return type.type === product.type;
        });

        if (typeIsPresent.length === 0) {
          registeredTypes2.push({type: product.type, index: index});
          simulatorsSubMenu.columns[index] = {};
          simulatorsSubMenu.columns[index].label = locale[product.type];
          simulatorsSubMenu.columns[index].items = [];
          simulatorsSubMenu.columns[index].items.push({
            label: product.short_title ? product.short_title : product.title,
            slug: product.slug,
          });
          index++;
        } else {
          simulatorsSubMenu.columns[typeIsPresent[0].index].items.push({
            label: product.short_title ? product.short_title : product.title,
            slug: product.slug,
          });
        }
      }
    });
  }

  if (loading && !paymentsSubMenu && !productSubMenu && !simulatorsSubMenu) {
    return <Loader />;
  }

  let brokerName;

  try {
    brokerName = data.user[0].broker.name;
  } catch (e) {
    const debugObj = {
      cognitoUser: user,
      response: data,
    };
    Rollbar.error('Something went wrong', JSON.stringify(debugObj));
  }

  return (
    <Navbar
      activeItem={path}
      topBar={{
        items: [
          {
            label: brokerName,
            url: data.user[0].role === 'team_admin' ? '/profile-broker' : '#',
          },
          {
            label: data.user[0].forename,
            url: '/profile',
          },
        ],

        onLogout: logout,
      }}
      bottomBar={[
        {
          label: 'Produtos',
          url: '#',
          hasSubMenu: true,
          height: '38px',
          grid: 3,
          submenu: productSubMenu,
          icon: Products,
        },
        {
          label: 'Simuladores',
          url: '#',
          hasSubMenu: true,
          height: '38px',
          grid: 3,
          submenu: simulatorsSubMenu,
          icon: Proposals,
        },
        {
          label: 'Consultas',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Consultas',
                items: [
                  {
                    label: 'Apólices',
                    slug: 'policies',
                  },
                  {
                    label: 'Cotações',
                    slug: 'quotations',
                  },
                  {
                    label: 'Recibos',
                    slug: 'invoices',
                  },
                ],
              },
            ],
          },
          icon: Home,
        },
        {
          label: 'Informações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'informations',
            label: 'Toda a informação sobre a regularização dos seguros.',
            columns: [
              {
                label: 'Pagamentos',
                items: [
                  {
                    label: 'Pagamentos à Innovarisk',
                    slug: 'pagamentos',
                  },
                  {
                    label: 'Documentos',
                    slug: 'documentos',
                  },
                ],
              },
            ],
          },
          grid: false,
          icon: Processes,
        },
        {
          label: 'Comunicações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'communications',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Comunicações',
                items: [
                  {
                    label: 'Campanhas',
                    slug: 'campaign',
                  },
                  {
                    label: 'Newsletters',
                    slug: 'newsletter',
                  },
                  {
                    label: 'Flash',
                    slug: 'information',
                  },
                ],
              },
            ],
          },
          icon: Communications,
        },
        {
          label: 'Sinistros',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'sinistros',
            label: 'Toda a informação sobre sinistros.',
            columns: [
              {
                label: 'Participação de sinistro',
                items: [
                  {
                    label: 'Participação de sinistro',
                    slug: 'sinistros',
                  },
                ],
              },
            ],
          },
          icon: Processes,
        },
        {
          label: 'Formações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Todas as formações',
            columns: [
              {
                label: 'Formações',
                items: [
                  {
                    label: 'Em curso ou futuras',
                    slug: 'trainings',
                  },
                  {
                    label: 'Passadas',
                    slug: 'past-trainings',
                  },
                  {
                    label: 'Tutoriais',
                    slug: 'tutorials',
                  },
                  {
                    label: 'Certificados',
                    slug: 'certificates',
                  },
                ],
              },
            ],
          },
          icon: Trainings,
        },
      ]}
    />
  );
};

export default HeaderBroker;
