import React, {useState} from 'react';
import {useQuery} from '@apollo/react-hooks';

import {
  Box,
  Flex,
  Table,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Text,
  Button,
  Image,
  Modal,
  theme,
  locale,
  Loader,
  formatDate,
  Video,
} from '@innovago/ui';

import {getTutorials} from '../../graphql/queries';

export const Tutorials = () => {
  const [notifications, notify] = useNotification();
  const [modal, setModal] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);

  const queryVars = {
    where: {
      status: {_eq: 'published'},
      tutorial: {_eq: true},
    },
  };

  const {data, loading} = useQuery(getTutorials, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: () => notify(),
  });

  const handleModalClose = () => {
    setModal(false);
    setVideoId(null);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      <Wrapper>
        <Heading
          level="3"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          weight="500"
          caps
          padding="0 0 .25rem"
        >
          Formação
        </Heading>

        <Heading
          level="3"
          font={theme.fonts.mont}
          size="1.5rem"
          color={theme.colors.darkBlue}
          weight="500"
          padding="2.5rem 0 1.5rem"
        >
          Tutoriais
        </Heading>
        <Table
          loading={loading}
          columns={[
            {
              label: locale.title,
              property: 'name',
              sortable: false,
              format: item => item.name,
            },
            {
              label: locale.date,
              property: 'date',
              sortable: false,
              format: item => formatDate(item.date),
            },
            {
              label: 'Vídeo',
              property: 'conference_video',
              sortable: false,
              format: item =>
                item.conference_video ? (
                  <Button
                    size={'.875rem'}
                    color={theme.colors.brand}
                    underline={true}
                    onClick={() => {
                      setModal(true);
                      setVideoId(item.conference_video);
                      setVideoTitle(item.name);
                    }}
                    transparent
                    padding="0"
                    caps
                  >
                    <Image
                      src={`http://img.youtube.com/vi/${item.conference_video}/sddefault.jpg`}
                      width="100px"
                    />
                  </Button>
                ) : (
                  <Text>Não disponível</Text>
                ),
            },
          ]}
          data={data && data.tutorials}
        />
      </Wrapper>
      {modal && videoId && (
        <Modal onClose={() => handleModalClose()}>
          <Heading
            level={2}
            color={theme.colors.brand}
            weight={500}
            padding="0 0 2rem"
          >
            Tutorial - {videoTitle}
          </Heading>
          <Video id={videoId} margin="0 0 2rem" />
          <Flex justify="flex-end">
            <Button filled onClick={() => handleModalClose()}>
              {locale.close}
            </Button>
          </Flex>
        </Modal>
      )}
    </Box>
  );
};
