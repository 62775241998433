import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const Li1 = styled.li`
  font-family: ${props => (props.font ? props.font : 'Montserrat')};
  font-size: ${props => (props.size ? props.size : '1rem')};
  font-weight: ${props => (props.weight ? props.weight : 400)};
  line-height: ${props => (props.line ? props.line : '28px')};
  letter-spacing: ${props => (props.letter ? props.letter : 0)};
  color: ${props => (props.color ? props.color : theme.colors.black)};
  padding: ${props => (props.padding ? props.padding : 0)};
  text-transform: ${props => (props.transform ? props.transform : 'none')};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  margin: 0;

  a {
    color: ${theme.colors.brand};
  }
`;

export const List = props => {
  if (props.type === 'ordered') {
    return (
      <ol style={{margin: '0 0 1rem'}}>
        {props.items.map((item, index) => {
          return (
            <Li1
              key={`lista_${index}`}
              {...props}
              dangerouslySetInnerHTML={{__html: item}}
            ></Li1>
          );
        })}
      </ol>
    );
  } else {
    return (
      <ul style={{margin: '0 0 1rem'}}>
        {props.items.map((item, index) => {
          return (
            <Li1
              key={`lista_${index}`}
              {...props}
              dangerouslySetInnerHTML={{__html: item}}
            ></Li1>
          );
        })}
      </ul>
    );
  }
};
