import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const StyledHeading = styled.div`
  font-family: ${props => (props.font ? props.font : theme.fonts.mont)};
  font-size: ${props => (props.size ? props.size : '2rem')};
  font-weight: ${props => (props.weight ? props.weight : 500)};
  line-height: ${props => (props.line ? props.line : 1)};
  color: ${props => (props.color ? props.color : theme.colors.brand)};
  background: ${props => (props.background ? props.background : 'transparent')};
  padding: ${props => (props.padding ? props.padding : 0)};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-align: ${props => (props.align ? props.align : 'left')};
  text-transform: ${props => (props.caps ? 'uppercase' : 'none')};
  letter-spacing: ${props => (props.letter ? props.letter : '0')};
  border-bottom: ${props =>
    props.underline ? '2px solid ' + props.color : 'none'};
  display: ${props => (props.display ? props.display : 'block')};
  white-space: ${props => (props.wrap ? props.wrap : 'initial')};
  height: ${props => (props.height ? props.height : 'auto')};
  overflow: hidden;
  align-items: ${props => (props.alignItem ? props.alignItem : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : 'normal')};
  grid-template-columns: ${props =>
    props.gridColumns ? props.gridColumns : '1fr'};

  &:before {
    display: ${props => (props.order ? 'inline-block' : 'none')};
    vertical-align: middle;
    text-align: center;
    content: '${props => props.order}';
    font-size: ${props => (props.sizeBefore ? props.sizeBefore : props.size)};
    font-family: ${props => (props.fontBefore ? props.fontBefore : props.font)};
    color: ${props => (props.colorBefore ? props.colorBefore : props.color)};
    background: ${props =>
      props.backgroundBefore ? props.backgroundBefore : props.background};
    align-items: center;
    justify-content: center;
    width: ${props => (props.widthBefore ? props.widthBefore : '40px')};
    height: ${props => (props.heightBefore ? props.heightBefore : '40px')};
    line-height: ${props => (props.lineBefore ? props.lineBefore : '40px')};
    border: ${props =>
      props.backgroundBefore ? 'none' : '2px solid' + props.color};
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

export const Heading = props => {
  return <StyledHeading as={`h${props.level}`} {...props} />;
};

export const Title = styled(Heading)`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.24px;
`;

export const Subtitle = styled(Heading)`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
`;
