import React, {useContext} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {usePath} from 'hookrouter';

import {Navbar, Loader, locale, Home, Products, Processes} from '@innovago/ui';

import {AuthContext} from '../../contexts/AuthContext';

import {getHeader2} from '../../graphql/queries';

const HeaderClient = () => {
  const path = usePath();
  const {logout, user} = useContext(AuthContext);
  const paymentsSubMenu = {};
  const productSubMenu = {};

  const {data, loading} = useQuery(getHeader2, {
    skip: !(user && user.username),
    variables: {
      sub: user && user.username,
    },
    onError: err => console.log(err),
  });

  if (loading) {
    return <Loader />;
  }

  if (data) {
    paymentsSubMenu.type = 'pagamentos';
    paymentsSubMenu.label =
      'Toda a informação sobre a regularização dos seguros.';
    paymentsSubMenu.columns = [];
    paymentsSubMenu.columns['process'] = {};
    paymentsSubMenu.columns['process'].label =
      locale.process.plural + ' ' + locale.administrative;
    paymentsSubMenu.columns['process'].items = data.processes.filter(
      process => process.slug === 'pagamentos'
    );

    const registeredTypes = [];
    let index = 0;
    productSubMenu.type = 'produto';
    productSubMenu.label = 'Conheça a nossa oferta de produtos';
    productSubMenu.columns = [];
    data.products.forEach(product => {
      const typeIsPresent = registeredTypes.filter(type => {
        return type.type === product.type;
      });

      if (typeIsPresent.length === 0) {
        registeredTypes.push({type: product.type, index: index});
        productSubMenu.columns[index] = {};
        productSubMenu.columns[index].label = locale[product.type];
        productSubMenu.columns[index].items = [];
        productSubMenu.columns[index].items.push({
          label: product.short_title ? product.short_title : product.title,
          slug: product.slug,
        });
        index++;
      } else {
        productSubMenu.columns[typeIsPresent[0].index].items.push({
          label: product.short_title ? product.short_title : product.title,
          slug: product.slug,
        });
      }
    });
  }

  if (loading && !paymentsSubMenu && !productSubMenu && !user) {
    return <Loader />;
  }

  return (
    <Navbar
      activeItem={path}
      topBar={{
        items: [
          {
            label: [data.client[0].name, data.client[0].nif].filter(n => n).join(' - '),
            url: '/profile',
          },
        ],

        onLogout: logout,
      }}
      bottomBar={[
        {
          label: 'Produtos',
          url: '#',
          hasSubMenu: true,
          height: '38px',
          grid: 3,
          submenu: productSubMenu,
          icon: Products,
        },
        {
          label: 'Consultas',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Consultas',
                items: [
                  {
                    label: 'Apólices',
                    slug: 'policies',
                  },
                  {
                    label: 'Recibos',
                    slug: 'invoices',
                  },
                ],
              },
            ],
          },
          icon: Home,
        },
        {
          label: 'Sinistros',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'sinistros',
            label: 'Toda a informação sobre sinistros.',
            columns: [
              {
                label: 'Participação de sinistro',
                items: [
                  {
                    label: 'Participação de sinistro',
                    slug: 'sinistros',
                  },
                ],
              },
            ],
          },
          icon: Processes,
        }
      ]}
    />
  );
};

export default HeaderClient;
