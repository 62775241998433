/* global Rollbar */
import React, {useState, useContext} from 'react';
import {useQuery, useLazyQuery} from '@apollo/react-hooks';

import {
  Box,
  Wrapper,
  Subtitle,
  Title,
  Loader,
  formatDate,
  Grid2,
  DataTable,
  NotificationContainer,
  useNotification,
  theme,
  Button,
  currency,
} from '@innovago/ui';

import {listReceipts, getUser, getReceiptDocument} from '../../graphql/queries';
import {AuthContext} from '../../contexts/AuthContext';

const InvoiceBroker = ({props}) => {
  const [notifications, notify] = useNotification();
  const [brokerNo, setBrokerNo] = useState(false);
  const [invoiceData, setInvoiceData] = useState(false);
  const {user} = useContext(AuthContext);
  const {policyNo, receiptNo} = props;

  const {loading: loading1} = useQuery(getUser, {
    fetchPolicy: 'no-cache',
    skip: !(user && user.username),
    variables: {
      sub: user.username,
    },
    onCompleted: data => {
      const brokerNo = data.user[0].broker.broker_no ?? '621';
      setBrokerNo(brokerNo.toString());
    },
    onError: error => {
      notify();
    },
  });

  const [handleRequestPDF, {loading: loadingPdf}] = useLazyQuery(
    getReceiptDocument,
    {
      fetchPolicy: 'no-cache',
      skip: !policyNo,
      variables: {
        brokerNo: brokerNo,
        policyNo: policyNo?.toString(),
        receiptNo: receiptNo?.toString(),
        docType: 'R',
      },
      onCompleted: data => {
        if (data.getReceiptDocument.warning) {
          notify(data.getReceiptDocument.warning);
        } else {
          window.open(data.getReceiptDocument.url, '_blank');
        }
      },
      onError: error => {
        notify();
      },
    },
  );

  const {loading: loading2} = useQuery(listReceipts, {
    fetchPolicy: 'no-cache',
    skip: !brokerNo,
    variables: {
      data: {
        brokerNo: brokerNo,
        policyNo: policyNo?.toString(),
        receiptNo: receiptNo?.toString(),
      },
    },
    onCompleted: data => {
      const invoices = JSON.parse(data.listReceipts.invoices);
      const invoice = invoices.filter(
        i => i['Recibo nº'].toString() === receiptNo.toString(),
      )[0];

      if (!invoice) {
        Rollbar.error(
          `No invoice found for receipt ${receiptNo}:`,
          invoices.map(i => i['Recibo nº']),
        );
        return setInvoiceData([]);
      }

      invoice['Comissão'] = currency.format(invoice['Comissão']);
      invoice['Prémio Comercial'] = currency.format(
        invoice['Prémio Comercial'],
      );
      invoice['Prémio Total'] = currency.format(invoice['Prémio Total']);
      invoice['Data em que é devido'] = formatDate(invoice['Data de Cobrança']);
      invoice['Data de Emissão'] = formatDate(invoice['Data de Emissão']);
      invoice['Data de Início'] = formatDate(
        invoice['Periodo'].split(' a ')[0],
      );
      invoice['Data de Fim'] = formatDate(invoice['Periodo'].split(' a ')[1]);
      invoice['A pagar por'] = invoice['Pago por'];
      delete invoice['Moeda'];
      delete invoice['Pago por'];
      delete invoice['Data de Cobrança'];
      const invoiceKeys = Object.keys(invoice);
      const formatedInvoice = invoiceKeys.map(key => {
        return {
          label: key,
          value: invoice[key],
        };
      });

      if (data.listReceipts?.warning) {
        notify(data.listReceipts.warning, 'warning');
      }

      setInvoiceData(formatedInvoice);
    },
    onError: error => {
      notify();
    },
  });

  if (loading1 || loading2 || !invoiceData) {
    return <Loader />;
  }

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Box>
          <Subtitle
            level="2"
            font={theme.fonts.mont}
            color={theme.colors.darkBlue}
            padding="0 0 .25rem"
          >
            Recibo
          </Subtitle>
          <Title
            level={1}
            color={theme.colors.brand}
            font={theme.fonts.mont}
            padding="0 0 2.5rem"
          >
            {}
          </Title>
        </Box>
        <Grid2 align="start">
          <Box padding="0 100px 30px 0">
            <DataTable data={invoiceData} policy="true"></DataTable>
          </Box>
          <Box>
            <Box
              margin="0 0 2.5rem 0"
              padding="1.5rem"
              backgroundColor={theme.colors.lightGrey}
            >
              <Subtitle
                level="2"
                font={theme.fonts.mont}
                color={theme.colors.darkBlue}
                padding="0 0 2.25rem"
              >
                Recibo
              </Subtitle>
              <Button
                filled
                disabled={loadingPdf}
                onClick={() => (loadingPdf ? () => {} : handleRequestPDF())}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Grid2>
      </Wrapper>
    </Box>
  );
};

export default InvoiceBroker;
