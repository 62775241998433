import React from 'react';

import {Box, Text, Heading, theme} from '@innovago/ui';

export const ReviewIntro = props => {
  const {info} = props;

  return (
    <div>
      <Heading
        level={1}
        color={theme.colors.brand}
        font={theme.fonts.mont}
        weight="500"
        size="2rem"
        padding="0 0 3rem"
      >
        Corrigir nomeação
      </Heading>
      <Heading
        level={2}
        color={theme.colors.darkBlue}
        font={theme.fonts.mont}
        size="1.5rem"
        padding="0 0 1.5rem"
      >
        A Innovarisk informa que:
      </Heading>
      <Text padding="0 0 2rem 0">{info}</Text>
      <Box padding="2rem" backgroundColor={theme.colors.lightGrey} width="80%">
        <Text font={theme.fonts.lato} color={theme.colors.black} weight="600">
          Tem alguma dúvida?
        </Text>
        <Text font={theme.fonts.lato} color={theme.colors.black}>
          Caso tenha alguma dúvida ou questão, por favor contate: 211 351 290
        </Text>
      </Box>
    </div>
  );
};
