import React, {useState, useContext} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {navigate} from 'hookrouter';

import {
  Box,
  Wrapper,
  Grid2,
  NotificationContainer,
  useNotification,
  Heading,
  List,
  Text,
  Button,
  theme,
  locale,
  Loader,
  Flex,
  Download,
} from '@innovago/ui';

import {AuthContext} from '../../contexts/AuthContext';

import {SimulatorsStep1} from './step1';
import {SimulatorsStep2} from './step2';
import {SimulatorsStep3} from './step3';

import {getPreviousValues} from './helper';

import {getSimulator, getUser, getPolicy} from '../../graphql/queries';

export const Simulators = props => {
  const {user} = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [nif, setNif] = useState(null);
  const [holder, setHolder] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [policyID, setPolicyID] = useState(false);
  const [previousValues, setPreviousValues] = useState(false);
  const [notifications, notify] = useNotification();

  const {data, loading} = useQuery(getSimulator, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slug: props.slug,
    },
    onError: () => notify(),
  });

  const {data: userData, loading: loading2} = useQuery(getUser, {
    skip: !(user && user.username),
    fetchPolicy: 'no-cache',
    variables: {
      sub: user.username,
    },
    onError: () => notify(),
  });

  const {data: policyData, loading: loading3} = useQuery(getPolicy, {
    skip: !props.id,
    fetchPolicy: 'no-cache',
    variables: {
      id: props.id,
    },
    onError: () => notify(),
  });

  if (loading || loading2 || loading3) {
    return <Loader />;
  }

  if (props.id && currentStep < 2) {
    setCurrentStep(2);
    setPolicyID(props.id);
  }

  if (policyData) {
    const answers = policyData.policies_view[0].answers;
    if (!nif) {
      const nifFilter = answers.Policy.Policyholder.filter(
        items => items.Question === 'POLICYHOLDERID',
      );
      setNif(nifFilter[0].Answer);

      setPreviousValues(getPreviousValues(answers.Policy));
    }
  }

  const product = data.product[0];
  let conditions = [],
    helper = [];
  try {
    conditions =
      product.conditions === '' ? [] : JSON.parse(product.conditions);
  } catch (e) {
    console.log(e);
  }

  try {
    helper = product.helper === '' ? [] : JSON.parse(product.helper);
  } catch (e) {
    console.log(e);
  }

  if (!userData) {
    navigate('/', true);
  }

  const simulatorData = data.product[0].questions;
  const productID = data.product[0].id;
  const brokerNo = userData.user[0].broker.broker_no ?? '621';
  const attachments = data.product[0].attachments;

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      <Wrapper>
        <Heading
          level={3}
          color={theme.colors.darkBlue}
          size=".875rem"
          font={theme.fonts.mont}
          padding="0 0 .25rem"
          caps
        >
          {locale.simulator.singular}
        </Heading>
        <Grid2>
          <Heading
            level={1}
            color={theme.colors.brand}
            size="2rem"
            weight="500"
            font={theme.fonts.mont}
            padding="0 0 2.5rem"
          >
            {product.title}
          </Heading>
        </Grid2>

        <Grid2 align="flex-start">
          <Box overflow="hidden">
            {currentStep === 1 && (
              <SimulatorsStep1
                setCurrentStep={setCurrentStep}
                setNif={setNif}
                brokerNo={brokerNo}
                setHolder={setHolder}
                notifyAfter={notify}
              />
            )}
            {currentStep === 2 && (
              <SimulatorsStep2
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                simulatorData={simulatorData}
                conditions={conditions}
                productID={productID}
                nif={nif}
                userData={userData.user[0].broker}
                policyID={policyID}
                setPolicyID={setPolicyID}
                setPdf={setPdf}
                holder={holder}
                previousValues={previousValues ? previousValues : false}
                policyStatus={
                  policyData ? policyData.policies_view[0].policy_status : false
                }
              />
            )}
            {currentStep === 3 && (
              <SimulatorsStep3 policyID={policyID} pdf={pdf} />
            )}
          </Box>
          <Box overflow="hidden">
            <Heading
              level="3"
              color={theme.colors.darkBlue}
              size="1rem"
              weight="500"
              line="1.75"
              letter="2.25px"
              caps
              font={theme.fonts.lato}
              padding="0 0 0.5rem"
            >
              assistente de preenchimento
            </Heading>
            {currentStep === 1 && (
              <Text padding="0 0 1.5rem" size=".875rem">
                Por favor insira o número de contribuinte do cliente para
                verificação prévia
              </Text>
            )}
            {currentStep === 2 &&
              helper &&
              helper.map((item, index) => {
                switch (item.type) {
                  case 'header':
                    return (
                      <Heading
                        key={`helper${index}`}
                        level={item.data.level}
                        color={theme.colors.black}
                        size="1.125rem"
                        weight="700"
                        line="1.75"
                        font={theme.fonts.lato}
                        padding="1rem 0 0"
                      >
                        {item.data.text}
                      </Heading>
                    );
                  case 'list':
                    return (
                      <List
                        key={`helper${index}`}
                        type={item.data.style}
                        items={item.data.items}
                        color={theme.colors.black}
                        size="1rem"
                        weight="400"
                        font={theme.fonts.lato}
                        line="1.75"
                      />
                    );
                  case 'fileList':
                    return (
                      <Box
                        key={`product${index}`}
                        align="center"
                        justify="space-between"
                        width="100%"
                      >
                        <Text
                          font={theme.fonts.lato}
                          size="1rem"
                          line="1.75"
                          letter="2.25px"
                          color={theme.colors.darkBlue}
                          nowrap
                        >
                          {item.data.title}
                        </Text>
                        {item.data.items.map((item, index) => {
                          const attachment = attachments.filter(
                            attach =>
                              attach.file_name ===
                              item.name
                                .replace('amp;', '')
                                .replace('nbsp;', ' '),
                          );
                          if (attachment[0]) {
                            return (
                              <Button
                                key={`download_${index}`}
                                type="link"
                                href={attachment[0].url}
                                target="_blank"
                              >
                                <Flex justify="flex-start" padding=".5rem 0">
                                  <Download color={theme.colors.brand} />
                                  <Text
                                    font={theme.fonts.mont}
                                    size=".875rem"
                                    weight="500"
                                    color={theme.colors.brand}
                                    transform="uppercase"
                                    padding="0 1rem"
                                  >
                                    {item.label}
                                  </Text>
                                </Flex>
                              </Button>
                            );
                          } else {
                            return false;
                          }
                        })}
                      </Box>
                    );
                  default:
                  case 'paragraph':
                    return (
                      <Text
                        key={`helper${index}`}
                        color={theme.colors.black}
                        size="1rem"
                        weight="400"
                        font={theme.fonts.lato}
                        line="1.75"
                        html={item.data.text}
                      ></Text>
                    );
                }
              })}
            {currentStep === 3 && (
              <Box>
                <Text padding="0 0 1.5rem" size=".875rem">
                  Se desejar fazer o pedido de contratação de seguro desta
                  simulação, deve enviar a proposta contida no documento
                  cotação, assinada pelo Tomador.
                </Text>
                <Text padding="0 0 1.5rem" size=".875rem">
                  Para tal deve ir ao menu{' '}
                  <Button display="inline" href="/quotations" link={true}>
                    Consulta
                  </Button>
                  e anexar a cotação assinada (assinatura é pedida na pagina 4
                  da cotação) pelo tomador e enviar para a Innovarisk.
                </Text>
              </Box>
            )}
          </Box>
        </Grid2>
      </Wrapper>
    </Box>
  );
};
