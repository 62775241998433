import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {SignUpUser as SignUpUserMutation} from '../../graphql/mutations';

import {
  Box,
  Flex,
  WrapperSmall,
  Text,
  Heading,
  FormPassword,
  Button,
  theme,
  Loader,
  Modal,
  NotificationContainer,
  useNotification,
  PasswordHelper,
  locale,
} from '@innovago/ui';

import {getInvite} from '../../graphql/queries';

const validationSchema = Yup.object().shape({
  password: Yup.string().min(6, locale.min6Chars).required(locale.required),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], locale.passwordsMustMatch)
    .required(locale.required),
});

export const RegisterTeamAdmin = props => {
  const id = props.id.id;
  const [accountCreated, setAccountCreated] = useState(false);
  const [registryButtonDisabled, setRegistryButtonDisabled] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [notifications, notify] = useNotification();

  const [signUpUser] = useMutation(SignUpUserMutation, {
    onCompleted: data => {
      if (data.signUpUser.error) {
        notify('Ocorreu um erro', 'error');
      } else {
        setAccountCreated(true);
      }
    },
    onError: error => {
      if (error.message.match(/An account with the given email already exists/)) {
        notify(
          'Já existe um utilizador com esse email. Contacte-nos para resolver o problema',
          'error'
        );
      } else {
        notify('Ocorreu um erro', 'error');
      }
    },
  });

  const {data: inviteData, loading} = useQuery(getInvite, {
    variables: {
      id,
    },
    onError: () => notify(),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      password2: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: validationSchema,
    validate: (values, props) => {
      setNewPassword(values.password);
    },
    onSubmit: async values => {
      try {
        setRegistryButtonDisabled(true);
        signUpUser({
          variables: {
            data: {
              inviteId: inviteData.invite.id,
              password: values.password
            }
          }
        });
      } catch (err) {
        notify();
      }
      setRegistryButtonDisabled(false);
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (accountCreated) {
    return (
      <Modal>
        <Heading
          level={2}
          color={theme.colors.brand}
          weight={500}
          padding="0 0 2rem"
        >
          {locale.accountCreationSuccessfull}
        </Heading>
        <Text padding="0 0 2rem">
          Pode fazer login com o email e a password que definiu.
        </Text>
        <Flex justify="flex-end">
          <Button href="/" filled>
            {locale.goToLogin}
          </Button>
        </Flex>
      </Modal>
    );
  }

  if (inviteData?.invite) {
    return (
      <WrapperSmall>
        <NotificationContainer {...notifications} />
        <Box padding="5rem 0 ">
          <Heading level="1" weight="500" padding="0 0 .5rem">
            {locale.accountCreationRequired}
          </Heading>
          <Text
            font={theme.fonts.lato}
            padding="0 0 .5rem"
            color={theme.colors.black}
          >
            {locale.accountCreationRequiredIntro}
          </Text>
          <Text font="Lato" weight="600" size="1.25rem" padding="0 0 2rem">
            {inviteData.invite.metadata?.brokerName}
          </Text>

          <Box padding="0 0 1.5rem">
            <Text
              font={theme.fonts.lato}
              size=".875rem"
              color={theme.colors.darkBlue}
            >
              {locale.fullname}
            </Text>
            <Text
              font={theme.fonts.lato}
              size="1rem"
              color={theme.colors.darkBlue}
            >
              {inviteData.invite.name}
            </Text>
          </Box>
          <Box padding="0 0 2rem">
            <Text
              font={theme.fonts.lato}
              size=".875rem"
              color={theme.colors.darkBlue}
            >
              {locale.accessEmail}
            </Text>
            <Text
              font={theme.fonts.lato}
              size="1rem"
              color={theme.colors.darkBlue}
            >
              {inviteData.invite.email.toLowerCase()}
            </Text>
          </Box>
          <Box
            height="4px"
            backgroundColor={theme.colors.silver}
            margin="0 0 2rem"
          ></Box>
          <Box padding="0 0 2rem">
            <Text
              font={theme.fonts.lato}
              size=".875rem"
              color={theme.colors.darkBlue}
            >
              {locale.role}
            </Text>
            <Text
              font={theme.fonts.lato}
              size="1rem"
              color={theme.colors.darkBlue}
            >
              {inviteData.invite.role === 'team_admin'
                ? locale.team_admin
                : locale.user}
            </Text>
          </Box>
          <Box
            height="4px"
            backgroundColor={theme.colors.silver}
            margin="0 0 2rem"
          ></Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <FormPassword
                required={true}
                type="password"
                name="password"
                label={locale.password}
                formik={formik}
                width="100%"
              />
              <FormPassword
                required={true}
                type="password"
                name="password2"
                label={locale.confirmPassword}
                formik={formik}
                width="100%"
              />
              <PasswordHelper password={newPassword}></PasswordHelper>
            </Box>
            <Flex justify="flex-end">
              <Button
                type="submit"
                elevated
                caps
                background={theme.colors.brand}
                width="33.33%"
                disabled={registryButtonDisabled}
              >
                {locale.submit}
              </Button>
            </Flex>
          </form>
        </Box>
      </WrapperSmall>
    );
  } else {
    return (
      <WrapperSmall>
        <Box padding="5rem 0 ">
          <Heading level="1" weight="500" padding="0 0 .5rem">
            {locale.accountCreationRequired}
          </Heading>
          <Text
            font={theme.fonts.lato}
            padding="0 0 .5rem"
            color={theme.colors.black}
          >
            Ocorreu um erro, ou o seu convite já não é válido ou já fez o
            registo (neste caso pode prosseguir para o login).
          </Text>
          <Text
            font={theme.fonts.lato}
            padding="0 0 .5rem"
            color={theme.colors.black}
          >
            Em caso de persistência do erro por favor contacte-nos.
          </Text>
        </Box>
      </WrapperSmall>
    );
  }
};
