import React from 'react';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import {setContext} from 'apollo-link-context';
import {ApolloProvider} from '@apollo/react-hooks';
import {useRoutes} from 'hookrouter';

import './App.css';

import {AuthProvider} from './contexts/AuthContext';
import {Header} from './components/Header';
import {HeaderPublic} from './components/HeaderPublic';
import Footer from './components/Footer';
import {Flex} from '@innovago/ui';
import routes from './routes';
import {navigate} from 'hookrouter';
import {CookieBanner} from './components/CookieBanner';

function createClient(routeResult) {
  const authLink = setContext((_, {headers}) => {
    const routeName = routeResult.props.routeName;
    const token = localStorage.getItem('token');
    let reviewToken, inviteToken;

    if (routeName === 'registerTeamUser' || routeName === 'registerTeamAdmin') {
      inviteToken = routeResult.props.id.id;
      reviewToken = inviteToken;
    }

    return {
      headers: {
        ...headers,
        ...(token && {authorization: `Bearer ${token}`}),
        ...(reviewToken && {'X-Hasura-Broker-Review-Token': reviewToken}),
        ...(inviteToken && {'X-Hasura-Invite-Id': inviteToken}),
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(
      createUploadLink({
        uri: process.env.REACT_APP_API_URL,
      }),
    ),
  });

  return client;
}

function App() {
  const routeResult = useRoutes(routes);

  const client = createClient(routeResult);

  if (!routeResult) {
    navigate('/', true);
  }

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Flex direction="column" minHeight={'100vh'}>
          {routeResult.props.public ? <HeaderPublic /> : <Header />}
          <div
            style={{
              flex: '1 1 0',
              width: '100%',
              position: 'relative',
              zIndex: 10,
            }}
          >
            {routeResult}
          </div>
          <Footer />
          <CookieBanner />
        </Flex>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
