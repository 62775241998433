import React, {useState, useContext, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  Box,
  Grid3,
  Wrapper,
  Heading,
  Loader,
  Table,
  Button,
  Flex,
  FormInput,
  FormSelect,
  NotificationContainer,
  useNotification,
  formatDate,
  locale,
  theme,
  currency
} from '@innovago/ui';

import {clientReceipts, getClient, getProducts} from '../../graphql/queries';
import {AuthContext} from '../../contexts/AuthContext';
import {useQueryParams} from 'hookrouter';

const ActionButton = styled(Button)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 0.85rem;
    line-height: 1.75;
  }
`;

const FilterGrid = styled(Grid3)`
  align-items: flex-end;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 1rem;
  overflow: visible;

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

const InvoicesClient = () => {
  const [notifications, notify] = useNotification();
  const [queryVars, setQueryVars] = useState({});
  const [nif, setNif] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [invoiceData, setInvoiceData] = useState(false);
  const [requestDownload, setRequestDownload] = useState(false);
  const [products, setProducts] = useState(false);
  const {user} = useContext(AuthContext);
  const [order, setOrder] = useState({'Data de Emissão': 'desc'});

  const [queryParams] = useQueryParams();

  const {loading: loading3} = useQuery(getProducts, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setProducts(
        data.product.map(p => {
          return {
            label: p.title,
            value: p.integration_code,
          };
        })
      );
    },
    onError: error => {
      notify();
    },
  });

  const {loading: loading1} = useQuery(getClient, {
    fetchPolicy: 'no-cache',
    skip: !(user && user.username),
    variables: {
      sub: user.username,
    },
    onCompleted: data => {
      setNif(data.client[0].nif);
      setQueryVars({...queryVars, nif: data.client[0].nif.toString()});
    },
    onError: error => {
      notify();
    },
  });

  const {loading: loading2} = useQuery(clientReceipts, {
    fetchPolicy: 'no-cache',
    skip: !nif,
    variables: {data: {...queryVars, nif}},
    onCompleted: data => {
      const invoices = JSON.parse(data.clientReceipts.invoices);

      if (data.clientReceipts?.warning) {
        notify(data.clientReceipts.warning, 'warning');
      }

      setInvoiceData(invoices);
    },
    onError: error => {
      notify();
    },
  });

  const {loading: loading4} = useQuery(clientReceipts, {
    fetchPolicy: 'no-cache',
    skip: !requestDownload,
    variables: {data: {...queryVars, output: 'Excel'}},
    onCompleted: data => {
      window.open(data.clientReceipts.invoices, '_blank');
      setRequestDownload(false);
    },
    onError: error => {
      setRequestDownload(false);
      notify();
    },
  });

  const handleDownload = () => {
    setRequestDownload(true);
  };

  const validationSchema = Yup.object().shape({
    policyno: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers),
  });

  const formik = useFormik({
    initialValues: {
      productCode: 0,
      policyno: queryParams.policyNo ? queryParams.policyNo.toString() : '',
      nif: '',
      invoicedFrom: '',
      invoicedTo: '',
      stage: '',
      valueFrom: '',
      receiptNo: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      let newQueryVars = Object.assign({}, queryVars);

      if (values.nif !== '') {
        newQueryVars = {...newQueryVars, nif: values.nif};
      } else {
        delete newQueryVars['nif'];
      }

      if (values.policyno !== '') {
        newQueryVars = {...newQueryVars, policyNo: values.policyno};
      } else {
        delete newQueryVars['policyNo'];
      }

      if (values.productCode && values.productCode.toString() !== '0') {
        newQueryVars = {...newQueryVars, productCode: values.productCode};
      } else {
        delete newQueryVars['productCode'];
      }

      if (values.invoicedFrom !== '') {
        newQueryVars = {...newQueryVars, invoicedFrom: values.invoicedFrom};
      } else {
        delete newQueryVars['invoicedFrom'];
      }

      if (values.invoicedTo !== '') {
        newQueryVars = {...newQueryVars, invoicedTo: values.invoicedTo};
      } else {
        delete newQueryVars['invoicedTo'];
      }

      if (values.stage && values.stage.toString() !== '0') {
        newQueryVars = {...newQueryVars, stage: values.stage};
      } else {
        delete newQueryVars['stage'];
      }

      if (values.valueFrom !== '') {
        newQueryVars = {...newQueryVars, valueFrom: Number(values.valueFrom)};
      } else {
        delete newQueryVars['valueFrom'];
      }

      if (values.receiptNo !== '') {
        newQueryVars = {...newQueryVars, receiptNo: values.receiptNo};
      } else {
        delete newQueryVars['receiptNo'];
      }

      setQueryVars(newQueryVars);
    },
  });

  useEffect(() => {
    if (!firstLoad) {
      if (queryParams.policyNo) {
        setQueryVars({policyNo: queryParams.policyNo});
      }
      setFirstLoad(true);
    }
  }, [queryVars, setQueryVars, firstLoad, setFirstLoad, queryParams]);

  if (loading1 || loading2 || !invoiceData) {
    return <Loader />;
  }

  const sortedInvoiceData = invoiceData.sort((a, b) => {
    let result = 0;

    if (a[Object.keys(order)[0]] < b[Object.keys(order)[0]]) {
      result = -1;
    }
    if (a[Object.keys(order)[0]] > b[Object.keys(order)[0]]) {
      result = 1;
    }

    return Object.values(order)[0] === 'asc' ? result : result * -1;
  });

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Heading
          level={1}
          color={theme.colors.brand}
          size="2rem"
          font={theme.fonts.mont}
          padding="0 0 2rem"
        >
          Consulte os seus recibos
        </Heading>
        {!loading3 && products && products.length > 0 && (
          <Box
            backgroundColor="rgba(134,177,184,.1)"
            margin="0 0 1rem"
            padding="1.125rem 1rem"
            overflow="visible"
          >
            <form onSubmit={formik.handleSubmit}>
              <FilterGrid>
                <FormSelect
                  margin="0"
                  formik={formik}
                  label={locale.product.singular}
                  name="productCode"
                  options={[{label: 'Todos', value: 0}, ...products]}
                  width="100%"
                  allowEmpty={false}
                />
                <FormInput
                  name="policyno"
                  label="Nº Apólice"
                  placeholder="Nº Apólice"
                  border={true}
                  width="100%"
                  formik={formik}
                  margin="0"
                />
                <FormSelect
                  width="100%"
                  formik={formik}
                  margin="0"
                  name="stage"
                  options={[
                    {label: 'Todos', value: 0},
                    {label: 'Pago', value: 'P'},
                    {label: 'Pendente', value: 'O'},
                  ]}
                  label={locale.stage}
                />
                <Box></Box>
                <Box>
                  <Button type="submit" filled>
                    Pesquisar
                  </Button>
                </Box>
                <Box>
                  {loading4 ? (
                    <Loader />
                  ) : (
                    <Button filled onClick={() => handleDownload()}>
                      Download Excel
                      <img
                        style={{
                          width: "20px",
                          display: "inline-block",
                          margin: "0 0 -5px 5px"
                        }}
                        src="./images/excel.svg"
                        alt="Excel"
                      />
                    </Button>
                  )}
                </Box>
              </FilterGrid>
            </form>
          </Box>
        )}

        {
          <Table
            size=".875rem"
            loading={loading2}
            slimPadding={true}
            columns={[
              {
                label: 'Recibo nº',
                property: 'Recibo nº',
                sortable: true,
                whitespace: 'nowrap',
                format: item => item['Recibo nº'],
              },
              {
                label: 'Apólice',
                property: 'Apólice',
                sortable: true,
                whitespace: 'nowrap',
                format: item => item['Apólice'],
              },
              {
                label: 'Tomador',
                property: 'Tomador',
                sortable: true,
                format: item => item['Tomador'],
              },
              {
                label: 'Produto',
                property: 'Produto',
                sortable: true,
                format: item => item['Produto'],
              },
              {
                label: 'Data de Emissão',
                property: 'Data de Emissão',
                whitespace: 'nowrap',
                sortable: true,
                format: item => formatDate(item['Data de Emissão']),
              },
              {
                label: 'Data Limite',
                property: 'Data de Cobrança',
                whitespace: 'nowrap',
                sortable: true,
                format: item => formatDate(item['Data de Cobrança']),
              },
              {
                label: 'Período',
                property: 'Periodo',
                whitespace: 'nowrap',
                sortable: true,
                format: item => item['Periodo'],
              },
              {
                label: 'Prémio Total',
                property: 'Prémio Total',
                sortable: true,
                whitespace: 'nowrap',
                format: item => currency.format(item['Prémio Total']),
              },
              {
                label: locale.actions,
                format: item => (
                  <Flex nowrap="true">
                    <ActionButton
                      filled
                      href={`/invoices/${item['Apólice']}/${item['Recibo nº']}`}
                      margin="0 0.5rem 0 0"
                    >
                      Detalhes
                    </ActionButton>
                  </Flex>
                ),
              },
            ]}
            data={sortedInvoiceData}
            sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
            onClickSort={sort => {
              setOrder({
                [sort.column]: sort.order,
              });
            }}
          />
        }
      </Wrapper>
    </Box>
  );
};

export default InvoicesClient;
