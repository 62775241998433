import React, {useState} from 'react';
import {useQuery} from '@apollo/react-hooks';

import {
  Box,
  Columns,
  Accordion,
  Wrapper,
  NotificationContainer,
  useNotification,
  Heading,
  Flex,
  Button,
  Download,
  Text,
  theme,
  locale,
  Loader,
} from '@innovago/ui';

import {ClausesForm} from './form';

import {getProcess} from '../../graphql/queries';

export const Process = props => {
  const [notifications, notify] = useNotification();
  const [formLightbox, toggleFormLightbox] = useState(false);
  const {data, loading} = useQuery(getProcess, {
    variables: {
      slug: props.slug.slug,
    },
    onError: () => notify(),
  });

  if (loading) {
    return <Loader />;
  }

  const processBody = JSON.parse(data.content[0]?.body || '[]');
  const columns = [];
  let index = 0;

  processBody.forEach(item => {
    if (item.type === 'delimiter') {
      index++;
      columns[index] = [];
      columns[index].push(item);
      index++;
    } else {
      if (!columns[index]) {
        columns[index] = [];
      }
      columns[index].push(item);
    }
  });

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <NotificationContainer {...notifications} />

      {formLightbox && (
        <ClausesForm toggleFormLightbox={toggleFormLightbox} notify={notify} />
      )}

      <Wrapper>
        <Heading
          level="2"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          padding="0 0 .25rem"
          caps
        >
          {`${locale.process.plural} ${locale.administrative}`}
        </Heading>
        {!props.hideTile && <Heading
          level="1"
          font={theme.fonts.mont}
          weight="500"
          size="2rem"
          color={theme.colors.brand}
          letter=".24px"
          padding="0 0 2.5rem"
        >
          {data.content[0]?.title}
        </Heading>}
        <Columns align="flex-start" justify="space-between">
          {columns.map((column, index) => {
            return (
              <Box key={`column${index}`} height="100%">
                {column.map((item, index) => {
                  switch (item.type) {
                    case 'header':
                      return (
                        <Heading
                          key={`process${index}`}
                          level={item.data.level}
                          color={theme.colors.darkBlue}
                          size="1.5rem"
                          weight="500"
                          font={theme.fonts.mont}
                          padding="0 0 1rem"
                        >
                          {item.data.text}
                        </Heading>
                      );

                    case 'delimiter':
                      return (
                        <Box
                          key={`process${index}`}
                          width="1px"
                          height="100%"
                          backgroundColor={theme.colors.grey}
                        />
                      );

                    case 'formPlaceholder':
                      return (
                        <Box
                          key={`process${index}`}
                          backgroundColor={theme.colors.lightGrey}
                          padding="1.5rem"
                          margin="3rem 0"
                        >
                          <Heading
                            level="3"
                            font={theme.fonts.lato}
                            size=".875rem"
                            weight="500"
                            line="1.14"
                            letter="2.25px"
                            color={theme.colors.darkBlue}
                            padding="0 0 1rem 0"
                            caps
                          >
                            {item.data.title}
                          </Heading>
                          <Text
                            font={theme.fonts.lato}
                            line="1.75"
                            color={theme.colors.black}
                            padding="0 0 1rem"
                          >
                            {item.data.text}
                          </Text>
                          <Flex justify="flex-end">
                            <Button
                              color={theme.colors.brand}
                              borderColor={theme.colors.brand}
                              display="inline-block"
                              width="auto"
                              onClick={() => toggleFormLightbox(true)}
                            >
                              FORMULÁRIO
                            </Button>
                          </Flex>
                        </Box>
                      );

                    case 'fileList':
                      return (
                        <Box
                          key={`campaign${index}`}
                          align="center"
                          justify="space-between"
                          width="100%"
                        >
                          <Text
                            font={theme.fonts.lato}
                            size=".875rem"
                            weight="500"
                            line="1.14"
                            letter="2.25px"
                            color={theme.colors.darkBlue}
                            padding="0 0 1rem 0"
                            nowrap
                          >
                            {item.data.title}
                          </Text>
                          <Button
                            type="link"
                            href={item.data.items[0].file}
                            target="_blank"
                          >
                            <Flex justify="flex-start">
                              <Download color={theme.colors.brand} />
                              <Text
                                font={theme.fonts.mont}
                                size=".875rem"
                                weight="500"
                                color={theme.colors.brand}
                                transform="uppercase"
                                padding="0 1rem"
                                target="_blank"
                              >
                                {item.data.items[0].label}
                              </Text>
                            </Flex>
                          </Button>
                        </Box>
                      );

                    case 'accordion':
                      return (
                        <Accordion key={`accordion${index}`} data={item.data} />
                      );

                    default:
                    case 'paragraph':
                      return (
                        <Text
                          key={`process${index}`}
                          html={item.data.text}
                          color={theme.colors.black}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          padding="0 0 1rem"
                          line="1.75rem"
                        />
                      );
                  }
                })}
              </Box>
            );
          })}
        </Columns>
      </Wrapper>
    </Box>
  );
};
