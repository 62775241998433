export const getPolicyCount = data => {};

export const setChartData = (
  data,
  setPolicies,
  setPatrimonyPieData,
  setPatrimonyColumnData,
  setResponsabilityPieData,
  setResponsabilityColumnData,
  setTeamData,
  portalUser
) => {
  let counter = [
    {status: 'draft', label: 'Por gravar', value: 0},
    {status: 'submitted', label: 'Gravadas', value: 0},
    {status: 'accepted', label: 'Colocadas', value: 0},
  ];

  let charts = {
    patrimony: {},
    responsability: {},
  };

  const uniqueUsers = [];

  const teamAdminChart = {
    labels: [],
    datasets: [],
  };

  if (data.group.length > 0) {
    data.group.map(g => {
      let obj = {};
      obj.title = g.title;
      obj.waiting = 0;
      obj.active = 0;
      obj.products = [];
      g.products.map(product => {
        obj.products.push(product.id);
        return false;
      });
      if (g.products[0]) {
        charts[
          g.products[0].type === 'patrimony' ? 'patrimony' : 'responsability'
        ]['g' + g.id] = obj;
      }
      return false;
    });
  }

  if (data.product.length > 0) {
    data.product.map(p => {
      let type = p.type === 'professional' ? 'responsability' : p.type;
      let obj = {
        title: p.short_title ? p.short_title : p.title,
        waiting: 0,
        active: 0,
        products: [p.id],
      };
      charts[type][p.id] = obj;
      return false;
    });
  }

  if (data.policies_view.length > 0) {
    data.policies_view.map(cotation => {
      counter.map(item => {
        if (
          item.status === cotation.policy_status &&
          cotation.user_id === portalUser.id
        ) {
          item.value += 1;
        }
        return false;
      });
      setPolicies(counter);

      let type =
        cotation.product.type === 'professional'
          ? 'responsability'
          : cotation.product.type;
      let key = cotation.product.group_id
        ? 'g' + cotation.product.group_id
        : cotation.product.id;

      if (cotation.user_id === portalUser.id) {
        if (cotation.policy_status === 'accepted') {
          if (cotation.annual_premium != null) {
            charts[type][key].active += cotation.annual_premium * 1;
          }
        } else {
          if (cotation.annual_premium != null) {
            charts[type][key].waiting += cotation.annual_premium * 1;
          }
        }
      }

      if (uniqueUsers.indexOf(cotation.user_id) === -1) {
        uniqueUsers.push(cotation.user_id);
      }
      return false;
    });
  }

  let c;

  const patrimonyKeys = Object.keys(charts.patrimony);
  let patrimonyLabels = [];
  let patrimonyPieData = [];
  let patrimonyPieColors = [];
  let patrimonyColumnValues1 = [];
  let patrimonyColumnValues2 = [];
  c = 0;
  patrimonyKeys.map(key => {
    patrimonyLabels.push(charts.patrimony[key].title);
    patrimonyPieData.push(parseFloat(charts.patrimony[key].active).toFixed(2));
    patrimonyPieColors.push(reds[c]);
    patrimonyColumnValues1.push(
      parseFloat(charts.patrimony[key].active).toFixed(2)
    );
    patrimonyColumnValues2.push(
      parseFloat(charts.patrimony[key].waiting).toFixed(2)
    );
    c++;
    return false;
  });

  setPatrimonyPieData({
    labels: patrimonyLabels,
    datasets: [
      {
        data: patrimonyPieData,
        backgroundColor: patrimonyPieColors,
      },
    ],
  });

  setPatrimonyColumnData({
    labels: patrimonyLabels,
    datasets: [
      {
        label: 'Apólices submetidas',
        data: patrimonyColumnValues2,
        backgroundColor: reds[1],
      },
      {
        label: 'Apólices efetuadas',
        data: patrimonyColumnValues1,
        backgroundColor: reds[2],
      },
    ],
  });

  const responsabilityKeys = Object.keys(charts.responsability);
  let responsabilityLabels = [];
  let responsabilityPieData = [];
  let responsabilityPieColors = [];
  let responsabilityColumnValues1 = [];
  let responsabilityColumnValues2 = [];
  c = 0;
  responsabilityKeys.map(key => {
    responsabilityLabels.push(charts.responsability[key].title);
    responsabilityPieData.push(
      parseFloat(charts.responsability[key].active).toFixed(2)
    );
    responsabilityPieColors.push(blues[c]);
    responsabilityColumnValues1.push(
      parseFloat(charts.responsability[key].active).toFixed(2)
    );
    responsabilityColumnValues2.push(
      parseFloat(charts.responsability[key].waiting).toFixed(2)
    );
    c++;
    return false;
  });

  setResponsabilityPieData({
    labels: responsabilityLabels,
    datasets: [
      {
        data: responsabilityPieData,
        backgroundColor: responsabilityPieColors,
      },
    ],
  });

  setResponsabilityColumnData({
    labels: responsabilityLabels,
    datasets: [
      {
        label: 'Apólices submetidas',
        data: responsabilityColumnValues2,
        backgroundColor: blues[1],
      },
      {
        label: 'Apólices efetuadas',
        data: responsabilityColumnValues1,
        backgroundColor: blues[2],
      },
    ],
  });

  uniqueUsers.map(user => {
    const policies = data.policies_view.filter(
      cotation => cotation.user_id === user
    );

    teamAdminChart.labels.push(policies[0].user?.forename || 'N/A');
    return false;
  });

  patrimonyKeys.map((key, index) => {
    const obj = {};
    obj.label = charts['patrimony'][key].title;
    obj.data = [];
    obj.backgroundColor = reds[index];

    uniqueUsers.map(user => {
      let value = 0;
      data.policies_view.map(policy => {
        if (
          policy.user_id === user &&
          policy.policy_status === 'accepted' &&
          charts['patrimony'][key].products.indexOf(policy.product.id) > -1
        ) {
          value += policy.annual_premium * 1;
        }
        return false;
      });

      obj.data.push(value.toFixed(2));
      return false;
    });

    teamAdminChart.datasets.push(obj);
    return false;
  });

  responsabilityKeys.map((key, index) => {
    const obj = {};
    obj.label = charts['responsability'][key].title;
    obj.data = [];
    obj.backgroundColor = blues[index];

    uniqueUsers.map(user => {
      let value = 0;
      data.policies_view.map(policy => {
        if (
          policy.user_id === user &&
          policy.policy_status === 'accepted' &&
          charts['responsability'][key].products.indexOf(policy.product.id) > -1
        ) {
          value += policy.annual_premium * 1;
        }
        return false;
      });

      obj.data.push(value.toFixed(2));
      return false;
    });

    teamAdminChart.datasets.push(obj);
    return false;
  });

  setTeamData(teamAdminChart);
};

export const reds = [
  '#af300a',
  '#e86137',
  '#ee8868',
  '#f5a62a',
  '#fad395',
  '#683257',
  '#634870',
  '#8d6a9f',
  '#a30aaf',
  '#d254dc',
  '#5f3d0e',
  '#87602a',
  '#bf90d7',
  '#fdbaff',
  '#d0b2df',
  '#cdbba2',
  '#f8e5c8',
];

export const blues = [
  '#20365f',
  '#415477',
  '#909baf',
  '#3787a1',
  '#86b1b8',
  '#65b8ec',
  '#9dd1f1',
  '#2c774b',
  '#75a689',
  '#6ac891',
  '#769112',
  '#a9c735',
  '#cfed6e',
  '#91efb8',
  '#c2d8db',
  '#c7cdd7',
  '#dbf1fa',
];
