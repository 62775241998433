import styled from 'styled-components';
import {Box, Grid3, theme} from '@innovago/ui';

export const FirstRowGrid = styled(Box)`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2rem;
  margin: 0 0 3rem;

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
`;

export const SecondRowBox = styled(Box)`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  height: 100%;
  margin: 0 0 3rem;
`;

export const SecondRowGrid = styled(Box)`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  margin: 0 0 2rem;

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
`;

export const PieBox = styled(Box)`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  height: 100%;
`;

export const PieGrid = styled(Box)`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
`;

export const CalendarGrid = styled(Grid3)`
  overflow: initial;
`;
