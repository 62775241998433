import React, {useCallback, useEffect, useState, memo} from 'react';
import styled from 'styled-components';

import {Box, Flex} from '../Box';
import {Button} from '../Button';
import {Text} from '../Text';
import locale from '../../locale';
import theme from '../../theme';

const Wrapper = styled.div`
  position: fixed;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 400;
`;

function notificationSettings(type) {
  switch (type) {
    case 'error':
      return {
        background: `${theme.colors.error}`,
        color: '#fff',
      };
    case 'warning':
      return {
        background: `${theme.colors.yellow}`,
        color: '#000',
      };
    case 'success':
    default:
      return {
        background: `${theme.colors.success}`,
        color: '#fff',
      };
  }
}

export const useNotification = () => {
  const [notifications, setNotifications] = useState({
    items: [],
  });

  function notify(
    message = locale.genericError,
    type = 'error',
    autoClose = true
  ) {
    setNotifications(prev => ({
      items: [
        ...prev.items,
        {
          message,
          type,
          autoClose,
        },
      ],
    }));

    setTimeout(() => {
      setNotifications(prev => {
        prev.items.shift();

        return {
          items: prev.items,
        };
      });
    }, 5000);
  }

  return [notifications, notify];
};

export const Notification = ({message, type, autoClose}) => {
  const {background, color} = notificationSettings(type);
  const [show, setShow] = useState(true);

  const onClose = () => {
    setShow(false);
  };

  const closeNotification = useCallback(() => {
    setShow(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    let timer;

    if (autoClose) {
      timer = setTimeout(closeNotification, 5000);
    }

    return () => clearTimeout(timer);
  }, [autoClose, closeNotification]);

  return show ? (
    <Box margin="0 0 1rem 0">
      <Flex
        backgroundColor={background}
        elevated={true}
        padding=".5rem"
        width="100%"
        rounded
      >
        <Text
          size=".85rem"
          line="1.4"
          width="80%"
          padding="0 1rem 0 0"
          color={color}
        >
          {message}
        </Text>
        <Button color={color} outline onClick={closeNotification}>
          {locale.close}
        </Button>
      </Flex>
    </Box>
  ) : null;
};

export const NotificationContainer = memo(({items}) => {
  const [notifications, setNotifications] = useState(items);
  useEffect(() => {
    setNotifications(items);
  }, [items]);

  return (
    <Wrapper>
      {notifications.map((notification, i) => (
        <Notification key={i} {...notification} />
      ))}
    </Wrapper>
  );
});
