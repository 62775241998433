import React from 'react';
import styled from 'styled-components';

import {
  Box,
  Flex,
  Download,
  Grid2,
  Image,
  BgGradient,
  Wrapper,
  Heading,
  Title,
  Subtitle,
  Text,
  Button,
  theme,
} from '@innovago/ui';


export const ClientBanner = ({data, title}) => {
  const BannerGrid = styled(Grid2)`
    grid-template-columns: repeat(1, 1fr);
    margin-left: 30px;

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
    }
  `;


  if (!data) {
    return null;
  }

  const bannerImage = data && data?.filter(c => c.type === "fileList")[0];
  const bannerImageUrl = bannerImage?.data?.items[0]?.file;
  const bannerData = (data && data?.filter(c => c.type !== "fileList")) || [];

  return (
    <BgGradient
      padding="2rem 0"
      gradient="linear-gradient(-44deg, #1F2B3A 0%, #424362 48%, #27325B 100%)"
    >
      <Wrapper>
        <Box>
          <Grid2 bgColor='transparent'>
            <Box>
              <Subtitle
                level="3"
                font={theme.fonts.mont}
                color={theme.colors.brand}
                padding="0 0 .25rem"
              >
                Protocolos
              </Subtitle>
              <Title
                level={1}
                color={theme.colors.mango}
                font={theme.fonts.mont}
                padding="0 0 2rem"
              >
                {title}
              </Title>
              {bannerData.map((item, index) => {
                switch (item.type) {
                  case 'header':
                    return (
                      <Heading
                        key={`campaign${index}`}
                        level={item.data.level}
                        color={theme.colors.white}
                        size="1.125rem"
                        weight="500"
                        font={theme.fonts.mont}
                        padding="0 0 1rem"
                      >
                        {item.data.text}
                      </Heading>
                    );

                  case 'fileList':
                    return (
                      <Flex
                        key={`campaign${index}`}
                        align="center"
                        justify="space-between"
                        width="100%"
                      >
                        <Text
                          font={theme.fonts.lato}
                          size="1rem"
                          line="1.75"
                          color={theme.colors.white}
                          nowrap
                        >
                          {item.data.title}
                        </Text>
                        <Button
                          type="link"
                          href={item.data.items[0].file}
                          target="_blank"
                          display={'inline-block'}
                        >
                          <Flex justify="flex-start">
                            <Download color={theme.colors.brand} />
                            <Text
                              font={theme.fonts.mont}
                              size=".875rem"
                              color={theme.colors.brand}
                              transform="uppercase"
                              padding="0 1rem"
                            >
                              {item.data.items[0].label}
                            </Text>
                          </Flex>
                        </Button>
                      </Flex>
                    );

                  default:
                  case 'paragraph':
                    return (
                      <Text
                        key={`campaign${index}`}
                        color={theme.colors.white}
                        size="1rem"
                        weight="400"
                        font={theme.fonts.lato}
                        line="1.75"
                        padding="0 0 1rem"
                        html={item.data.text}
                      ></Text>
                    );
                }
              })}
            </Box>
            <Box>
              <BannerGrid >
                <Image src={bannerImageUrl}></Image>
              </BannerGrid>
            </Box>
          </Grid2>
        </Box>
      </Wrapper>
    </BgGradient>
  );
};
