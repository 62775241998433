import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Text} from '@innovago/ui';

const StyledCookieContainer = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: ${props => (props.show ? 'block' : 'none')};
`;

const StyledCookieButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: ${props => props.color};
  color: white;ß
`;

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const CookieBanner = () => {
  const [isVisible, setIsVisible] = React.useState(true);

  useEffect(() => {
    const consentAccepted = document.cookie.includes('userConsent=accepted');
    const consentRejected = document.cookie.includes('userConsent=rejected');

    if (consentAccepted) return handleAcceptConsent();
    if (consentRejected) return handleRejectConsent();
  }, []);

  const handleAcceptConsent = type => {
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
    setCookie('userConsent', 'accepted', 365);
    setIsVisible(false);
  };

  const handleRejectConsent = () => {
    window.gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
    setCookie('userConsent', 'rejected', 365);
    setIsVisible(false);
  };

  return (
    <StyledCookieContainer show={isVisible}>
      <Text align="center" padding="0px 0px 10px 0px">
        We use cookies to enhance your experience. By clicking "Accept All", you
        consent to the use of all cookies. By clicking "Reject All", you decline
        the use of all cookies.
      </Text>
      <StyledCookieButton onClick={() => handleAcceptConsent()} color="#28a745">
        Accept All
      </StyledCookieButton>
      <StyledCookieButton onClick={() => handleRejectConsent()} color="#dc3545">
        Reject All
      </StyledCookieButton>
    </StyledCookieContainer>
  );
};
