import React, {useContext, useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import {Dropzone} from './dropzone';

import {AuthContext} from '../../contexts/AuthContext';

import {getProfile} from '../../graphql/queries';

import {InsertClaim as InsertClaimMutation} from '../../graphql/mutations';

import {
  Box,
  ModelWithOverlay,
  Grid2,
  FormInput,
  FormFile,
  FormDate,
  FormTextarea,
  Flex,
  Button,
  Heading,
  Loader,
  theme,
  locale,
} from '@innovago/ui';

const {REACT_APP_UPLOAD_URL} = process.env;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
  email: Yup.string().email().required(locale.required),
  policyNo: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers).required(locale.required),
  occurrenceDate: Yup.string().required(locale.required),
  occurenceLocal: Yup.string().required(locale.required),
  occurenceDescription: Yup.string().required(locale.required),
  measuresTaken: Yup.string().required(locale.required),
});

const initialFiles = [];

const FormGrid = styled(Grid2)`
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

export const ClausesForm = props => {
  const {toggleFormLightbox, notify} = props;
  const {user} = useContext(AuthContext);
  const [files, setFiles] = useState(initialFiles);

  const {data, loading} = useQuery(getProfile, {
    skip: !(user && user.username),
    variables: {
      sub: user && user.username,
    },
    onError: err => console.log(err),
  });

  const [InsertClaimStatus] = useMutation(InsertClaimMutation, {
    onCompleted: () => {
      notify('Os dados foram enviados', 'success');
      toggleFormLightbox(false);
    },
    onError: () => {
      notify();
    },
  });

  const onFileAdded = async acceptedFiles => {
    const x = await Promise.all(
      acceptedFiles.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const binaryStr = reader.result;
            resolve({
              file: binaryStr,
              label: file.name,
              size: file.size,
              type: file.type,
            });
          };

          reader.onerror = () => {
            reject();
          };

          reader.readAsDataURL(file);
        });
      })
    );

    setFiles(prevState => [...prevState, ...x]);
  };

  const uploadFiles = async id => {
    const d = await Promise.all(
      files.map(async (file, index) => {
        const media = {
          fieldName: 'file' + index,
          fileName: file.label,
          type: file.type,
          file: file.file,
        };

        return await fetch(REACT_APP_UPLOAD_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(media),
        })
          .then(response => response.json())
          .then(response => {
            return {[file.label]: response.secureUrl};
          });
      })
    );
    return d;
  };

  const onRemoveFile = item => {
    const newFiles = files.filter(file => file.label !== item.label);
    setFiles(newFiles);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      policyNo: '',
      occurrenceDate: '',
      occurenceLocal: '',
      occurenceDescription: '',
      measuresTaken: '',
      userId: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const claimData = values;
      claimData.userId = '' + data.user[0].id;

      const d = await uploadFiles();
      d.map(upload =>
        Object.entries(upload).map(up => {
          const fileToBeUpdated = files.find(file => {
            if (file.label === up[0]) {
              file.url = up[1];
              return file;
            } else {
              return false;
            }
          });
          setFiles(prevState => [...prevState, fileToBeUpdated]);
          return false;
        })
      );

      const attachments = [];
      files.forEach(file => {
        attachments.push({
          url: file.url,
          fileName: file.label,
          fileSize: file.size,
        });
      });

      claimData.attachments = attachments;
      claimData.policyNo = parseFloat(claimData.policyNo);

      InsertClaimStatus({
        variables: {
          data: claimData,
        },
      });
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <ModelWithOverlay top="50%" onClose={() => toggleFormLightbox(false)}>
      <Box height="calc(100vh - 200px)">
        <form onSubmit={formik.handleSubmit}>
          <Heading
            level="3"
            font={theme.fonts.lato}
            size="1.5rem"
            weight="500"
            color={theme.colors.brand}
            padding="0 0 2rem 0"
          >
            Participação de Sinistro/Circunstância
          </Heading>
          <FormInput
            type="text"
            name="name"
            label="Nome completo do Segurado"
            formik={formik}
            width="100%"
            required
          />
          <FormInput
            type="email"
            name="email"
            label="Email de contacto"
            width="100%"
            formik={formik}
            required
          />
          <FormGrid>
            <FormInput
              name="policyNo"
              label="N.º de apólice"
              width="100%"
              formik={formik}
              required
            />
            <FormDate
              name="occurrenceDate"
              label="Data da ocorrência"
              formik={formik}
              width="100%"
              required
            />
          </FormGrid>
          <FormInput
            type="text"
            name="occurenceLocal"
            label="Local da ocorrência"
            formik={formik}
            width="100%"
            required
          />
          <FormTextarea
            name="occurenceDescription"
            label="Breve descrição do sinistro/circunstância"
            formik={formik}
            required
          />
          <FormTextarea
            name="measuresTaken"
            label="Medidas já adotadas"
            formik={formik}
            required
          />

          <Dropzone onFileAdded={onFileAdded}></Dropzone>

          {files.map((item, index) => {
            return (
              <FormFile
                key={`#uploadedFiles${index}`}
                formik={formik}
                name={`#uploadedFiles${index}`}
                data={item}
                size={item.size}
                type="file"
                required
                index={index}
                onRemove={() => onRemoveFile(item)}
              />
            );
          })}

          <Flex justify="flex-end" padding="0 0 2rem">
            <Button filled type="submit">
              Enviar
            </Button>
          </Flex>
        </form>
      </Box>
    </ModelWithOverlay>
  );
};
