import React, {memo, useState} from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const DataTableContainer = styled.div`
  background-color: ${theme.colors.white};
  height: ${props => (props.height ? props.height : 'auto')};
  box-shadow: ${props =>
    props.policy
      ? 'none'
      : '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)'};
`;

const DataTable1 = styled.table`
  background-color: ${theme.colors.white};
  border: none;
  border-collapse: collapse;
  width: 100%;
`;

const TableRow = styled.tr`
  cursor: default;
  background-color: ${theme.colors.white};
  height: ${props => (props.policy ? '2rem' : 'auto')};
  padding: ${props => (props.policy ? '1rem 0' : '0')};

  &:hover {
    background-color: ${theme.colors.silver};
  }
`;

const TableCell = styled.td`
  color: ${theme.colors.black};
  padding: 1rem;
  font-weight: 400;
`;

const TableHeading = styled(TableCell)`
  background-color: ${theme.colors.lightGrey};
  color: ${theme.colors.darkBlue};
  font-weight: 500;
  font-family: ${theme.fonts.mont};
  font-size: 1.5rem;
  line-height: 4.6rem;
  padding: 0 2rem;
  text-align: left;
`;

const TableLabel = styled(TableCell)`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts.lato};
  font-size: 0.875rem;
  letter-spacing: ${props => (props.policy ? '0' : '2.25px')};
  font-weight: ${props => (props.policy ? '400' : '500')};
  line-height: ${props => (props.policy ? '1.4' : '4.6rem')};
  padding: ${props => (props.policy ? '0 0 0 1rem' : '0 0 0 2rem')};
  text-transform: ${props => (props.policy ? 'none' : 'uppercase')};
  border-top: ${props =>
    props.policy ? 'none' : '1px solid ${theme.colors.lightGrey'}};
`;

const TableValue = styled(TableCell)`
  color: ${props =>
    props.policy ? '${theme.colors.black}' : '${theme.colors.dirtyBlue}'};
  font-family: ${theme.fonts.lato};
  font-size: ${props => (props.policy ? '0.875rem' : '2rem')};
  letter-spacing: 0.25px;
  font-weight: ${props => (props.policy ? '700' : '500')};
  line-height: ${props => (props.policy ? '1.4' : '4.6rem')};
  padding: ${props => (props.policy ? '0 1rem 0 0' : '0 2rem 0 0')};
  padding: ;
  text-align: right;
  border-top: 1px solid ${theme.colors.lightGrey};
`;

export const DataTable = memo(
  ({title, data, emptyLabel = 'Não existem dados', loading, ...rest}) => {
    return (
      <DataTableContainer {...rest}>
        <DataTable1>
          {title && (
            <thead>
              <TableRow>
                <TableHeading colSpan="2" as="th">
                  {title}
                </TableHeading>
              </TableRow>
            </thead>
          )}
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, rowindex) => (
                <TableRow {...rest} key={`row_${rowindex}`}>
                  <TableLabel {...rest}>{item.label}</TableLabel>
                  <TableValue {...rest}>{item.value}</TableValue>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableLabel colSpan="2">
                  {loading ? 'A carregar' : emptyLabel}
                </TableLabel>
              </TableRow>
            )}
          </tbody>
        </DataTable1>
      </DataTableContainer>
    );
  }
);
