import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const sContacts = styled.div`
  position: fixed;
  right: 0;
  bottom: 230px;
  z-index: 10;

  .cta__label {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    padding: 1rem 0.5rem 0.5rem;
    background-color: #e32618;
    width: 80px;
    height: 100px;
    position: relative;
  }

  .cta__phone {
    color: #fff;
    padding: 0.5rem;
    background-color: #676767;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(20px,-30px);

    svg {
      width: 32px;
      height: 25px;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      height: 0;
      width: 0;
      border-top: 12px solid #e32618;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      transform: translateX(-50%);
    }
  }

  .cta__email {
    font-size: .875rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    background-color: #b4b4b4;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(20px,-30px);

    svg {
      width: 32px;
      height: 25px;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    width: ${props =>
      props.columns ? '40%' : props.width ? props.width : 'auto'};
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    width: ${props =>
      props.columns ? '100%' : props.width ? props.width : 'auto'};
  }
`;


const StyledButtonTransparent = styled(sContacts)`
  .cta__label {
    background-color: ${props => props.titleColor || '#e32618'};
  }
  .cta__phone {
    background-color: ${props => props.phoneColor || '#676767'};

    &:before {
      border-top: 12px solid ${props => props.titleColor || '#e32618'};
    }
  }
  .cta__email {
    background-color: ${props => props.mailColor || '#b4b4b4'};
  }
`;

export const Contacts = props => {
  if (!props.setting) {
    return null;
  }

  const settings = props.setting.reduce((result, key) => ({ [key['name']]: key, ...result }), {});
  const text = settings['widgetText']?.value?.trim() || 'Fale connosco';
  const colors = settings['widgetColors']?.value?.trim();
  const content = settings['widgetContent']?.value?.trim();
  const [titleColor, phoneColor, mailColor] = [...(colors?.split(';') || []), ...['#e32618', '#676767', '#b4b4b4']].filter(a => a);
  const [phone, mail] = [...content.split(';'), ...['00351215918370', 'https://innovarisk.pt/contactos']].filter(a => a);

  return <StyledButtonTransparent titleColor={titleColor} phoneColor={phoneColor} mailColor={mailColor} >
    <div className="cta__label">
      <span>
        {text}
      </span>
    </div>
    <a className="cta__phone js-event-phone" href={`tel:${phone}`}>
      <svg className="contacts-icon" aria-hidden="true" data-prefix="fas" data-icon="phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#ffffff" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path>
      </svg>
    </a>
    <a className="cta__email" href={mail.startsWith('http') ? mail : `mailto:${mail}`}>
      <svg className="contacts-icon" aria-hidden="true" data-prefix="fas" data-icon="expand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="#ffffff" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
      </svg>
    </a>
  </StyledButtonTransparent>;
};
