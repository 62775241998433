import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/react-hooks';

import {
  UpdateBroker as UpdateBrokerMutation,
  InsertBrokerAttachment as InsertBrokerAttachmentMutation,
} from '../../graphql/mutations';

import {
  Text,
  Heading,
  FormInput,
  FormFile,
  Flex,
  Button,
  NotificationContainer,
  useNotification,
  Loader,
  LoaderInline,
  theme,
} from '@innovago/ui';

const filesArr = {
  f1: {
    file: null,
    label: 'Ficha de nomeação',
    size: false,
    type: 'application/pdf',
  },
  f2: {
    file: null,
    label: 'Apólice de responsabilidade Civil',
    size: false,
    type: 'application/pdf',
  },
  f3: {
    file: null,
    label: 'Relatório de Contas',
    size: false,
    type: 'application/pdf',
  },
  f4: {
    file: null,
    label: 'Certificado de ASF de mediador',
    size: false,
    type: 'application/pdf',
  },
  f5: {
    file: null,
    label: 'IBAN',
    filesize: false,
    type: 'application/pdf',
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  vat: Yup.number().required('Obrigatório'),
  phone: Yup.string().required('Obrigatório'),
  f1: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f2: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f3: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f4: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
  f5: Yup.mixed()
    .required('Obrigatório')
    .test('fileFormat', 'PDF only', value => {
      return value && ['application/pdf'].includes(value.type);
    }),
});

export const ReviewForm = props => {
  const {showLightbox, reviewToken, broker} = props;
  const [notifications, notify] = useNotification();
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState(filesArr);
  const [render, setRender] = useState(false);

  useEffect(() => {
    broker.attachments.map(file => {
      let urlParts = file.url.split('/');
      let filename = urlParts[urlParts.length - 1].split('_');

      setFiles(prevState => ({
        ...prevState,
        [file.field_name]: {
          ...prevState[file.field_name],
          url: file.url,
          filename: filename[0],
          file: null,
          size: file.file_size,
        },
      }));
      formik.setFieldValue(file.field_name, {
        file: file.file_name,
        type: 'application/pdf',
      });
      return true;
    });
    setRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [insertBrokerAttachment] = useMutation(InsertBrokerAttachmentMutation, {
    onCompleted: () => {
      setIsUploading(false);
      notify('O ficheiro foi actualizado.', 'success');
    },
    onError: error => {
      setIsUploading(false);
      notify('Ocorreu um erro a carregar o ficheiro.', 'error');
    },
  });

  const [update_broker] = useMutation(UpdateBrokerMutation, {
    onCompleted: () => {
      showLightbox();
      setIsUploading(false);
    },
    onError: error => {
      notify();
      setIsUploading(false);
    },
  });

  const onFileChange = async e => {
    const name = e.currentTarget.id;
    const uploadedFile = e.target.files[0];
    setIsUploading(true);

    setFiles(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        filename: uploadedFile.name,
        size: uploadedFile.size,
      },
    }));

    formik.setFieldValue(name, {
      file: uploadedFile.name,
      type: uploadedFile.type,
    });

    const data = [
      {
        filename: uploadedFile.name,
        fieldname: name,
        filesize: uploadedFile.size,
        file: await fileToDataUrl(uploadedFile),
      },
    ];

    insertBrokerAttachment({
      variables: {
        attachments: data,
        brokerId: broker.id,
      },
      context: {
        headers: {
          'x-hasura-broker-review-token': reviewToken,
        },
      },
    });
  };

  const fileToDataUrl = file => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = () => {
        reject();
      };
      fr.readAsDataURL(file);
    });
  };

  const onFileRemove = name => {
    setFiles(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        url: null,
        file: null,
        size: false,
      },
    }));
    formik.setFieldValue(name, {
      file: null,
      type: null,
    });
  };

  function handleSubmit(brokerData) {
    setIsUploading(true);
    update_broker({
      variables: {
        set: brokerData,
        review_token: reviewToken,
      },
      context: {
        headers: {
          'x-hasura-broker-review-token': reviewToken,
        },
      },
    });
  }

  const formik = useFormik({
    initialValues: {
      name: broker.name,
      vat: broker.vat,
      phone: broker.phone,
      f1: '',
      f2: '',
      f3: '',
      f4: '',
      f5: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const brokerData = {
        name: values.name,
        vat: values.vat,
        phone: values.phone,
      };

      handleSubmit(brokerData);
    },
  });

  if (!render) {
    return <Loader />;
  }

  return (
    <div>
      <NotificationContainer {...notifications} />
      <form onSubmit={formik.handleSubmit}>
        <Heading
          level={2}
          order="3"
          color={theme.colors.darkBlue}
          font={theme.fonts.mont}
          size="1.5rem"
          padding="0 0 2rem 0"
        >
          Identificação da empresa
        </Heading>
        <FormInput
          type="text"
          name="name"
          label="Empresa"
          placeholder="Insira o nome da empresa"
          border={true}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="vat"
          label="NIF da empresa"
          placeholder="NIF"
          border={true}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="phone"
          label="Telefone / Telemóvel"
          placeholder="+351 20 000 00 00"
          border={true}
          formik={formik}
          width="100%"
        />

        <Text font={theme.fonts.lato} padding="0 0 1.5rem" weight="500">
          Anexe os 5 documentos para nomeação:
        </Text>
        {Object.entries(files).map(item => {
          const [name, file] = item;
          return (
            <FormFile
              key={name}
              formik={formik}
              name={name}
              type="file"
              onChange={onFileChange}
              onRemove={() => onFileRemove(name)}
              data={file}
            />
          );
        })}
        <Flex justify="flex-end">
          {!isUploading && (
            <Button
              caps
              background={theme.colors.brand}
              width="33.33%"
              type="submit"
            >
              submeter
            </Button>
          )}
          {isUploading && <LoaderInline />}
        </Flex>
      </form>
    </div>
  );
};
