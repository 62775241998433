import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: ${props => (props.margin ? props.margin : 0)};
  width: ${props => (props.width ? props.width : 'auto')};
`;

export const Image = props => {
  return <StyledImage src={props.url} {...props} />;
};
