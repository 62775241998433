import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const Iframe = styled.iframe`
  width: 560px;
  height: 315px;
  border: none;
  margin: ${props => (props.margin ? props.margin : '0')};
`;

export const Video = props => {
  return (
    <Iframe
      {...props}
      src={`https://www.youtube.com/embed/${props.id}?rel=0&controls=0&modestbranding&autoplay=0`}
    ></Iframe>
  );
};
