import React from 'react';
import {useQuery} from '@apollo/react-hooks';

import {
  Box,
  Flex,
  Columns,
  Wrapper,
  Heading,
  Text,
  Button,
  Download,
  List,
  theme,
  locale,
  Loader,
} from '@innovago/ui';

import {getProductClient} from '../../graphql/queries';

const ProductClient = props => {
  const {data, loading} = useQuery(getProductClient, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: props.slug,
    },
    onCompleted: () => {},
    onError: () => console.log('error'),
  });

  if (loading) {
    return <Loader />;
  }

  const product = data.product[0];

  const productBody = product.body.length > 0 ? JSON.parse(product.body) : [];
  const columns = [];
  let index = 0;

  productBody.forEach(item => {
    if (item.type === 'delimiter') {
      index++;
      columns[index] = [];
      columns[index].push(item);
      index++;
    }
    if (item.type !== 'delimiter') {
      if (!columns[index]) {
        columns[index] = [];
      }
      columns[index].push(item);
    }
  });

  const attachments = product.attachments;

  return (
    <Box width="100%" padding="3rem 0 8.5rem">
      <Wrapper>
        <Heading
          level="2"
          font={theme.fonts.mont}
          size=".875rem"
          color={theme.colors.darkBlue}
          padding="0 0 .5rem"
          caps
        >
          {locale[product.type]}
        </Heading>
        <Heading
          level="1"
          font={theme.fonts.mont}
          weight="500"
          size="2rem"
          color={theme.colors.brand}
          letter=".24px"
          padding="0 0 2.5rem"
        >
          {product.title}
        </Heading>
        <Columns align="flex-start" justify="space-between">
          {columns.map((column, index) => {
            return (
              <Box key={`column${index}`} height="100%">
                {column.map((item, index) => {
                  switch (item.type) {
                    case 'header':
                      return (
                        <Heading
                          key={`product${index}`}
                          level={item.data.level}
                          color={theme.colors.darkBlue}
                          size="1.5rem"
                          weight="500"
                          font={theme.fonts.mont}
                          padding="0 0 1rem"
                        >
                          {item.data.text}
                        </Heading>
                      );

                    case 'list':
                      return (
                        <List
                          key={`product${index}`}
                          type={item.data.style}
                          items={item.data.items}
                          color={theme.colors.black}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          line="1.75"
                          listIndex={index}
                        />
                      );

                    case 'delimiter':
                      return (
                        <Box
                          key={`product${index}`}
                          width="1px"
                          height="100%"
                          backgroundColor={theme.colors.grey}
                        />
                      );

                    case 'fileList':
                      return (
                        <Box
                          key={`product${index}`}
                          align="center"
                          justify="space-between"
                          width="100%"
                        >
                          <Text
                            font={theme.fonts.lato}
                            size="1rem"
                            line="1.75"
                            letter="2.25px"
                            color={theme.colors.darkBlue}
                            nowrap
                          >
                            {item.data.title}
                          </Text>
                          {item.data.items.map((item, index) => {
                            const attachment = attachments.filter(
                              attach =>
                                attach.file_name ===
                                item.name
                                  .replace('amp;', '')
                                  .replace('nbsp;', ' ')
                            );
                            if (attachment[0]) {
                              return (
                                <Button
                                  key={`download_${index}`}
                                  type="link"
                                  href={attachment[0].url}
                                  target="_blank"
                                >
                                  <Flex justify="flex-start" padding=".5rem 0">
                                    <Download color={theme.colors.brand} />
                                    <Text
                                      font={theme.fonts.mont}
                                      size=".875rem"
                                      weight="500"
                                      color={theme.colors.brand}
                                      transform="uppercase"
                                      padding="0 1rem"
                                    >
                                      {item.label}
                                    </Text>
                                  </Flex>
                                </Button>
                              );
                            } else {
                              return false;
                            }
                          })}
                        </Box>
                      );

                    default:
                    case 'paragraph':
                      return (
                        <Text
                          key={`product${index}`}
                          html={item.data.text}
                          color={theme.colors.black}
                          size="1rem"
                          weight="400"
                          font={theme.fonts.lato}
                          padding="0 0 1rem"
                          line="1.75rem"
                        />
                      );
                  }
                })}
              </Box>
            );
          })}
        </Columns>
      </Wrapper>
    </Box>
  );
};

export default ProductClient;
