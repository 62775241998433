import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Flex,
  Button,
  Heading,
  Loader,
  locale,
  theme,
  FormInput,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
  phone: Yup.string().required(locale.required),
  vat: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers).required(locale.required),
  correspondance_address_line_1: Yup.string().nullable(),
  correspondance_address_line_2: Yup.string().nullable(),
  correspondance_address_line_3: Yup.string().nullable(),
  correspondance_address_line_4: Yup.string().nullable(),
});

export const EditBrokerProfile = props => {
  const {broker, updateBrokerProfileStatus} = props;

  const formik = useFormik({
    initialValues: {
      name: broker.name,
      email: broker.email?.toLowerCase(),
      phone: broker.phone,
      vat: broker.vat,
      correspondance_address_line_1: broker.correspondance_address_line_1,
      correspondance_address_line_2: broker.correspondance_address_line_2,
      correspondance_address_line_3: broker.correspondance_address_line_3,
      correspondance_address_line_4: broker.correspondance_address_line_4,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      updateBrokerProfileStatus({
        variables: {
          set: values,
          id: broker.id,
        },
      });
    },
  });

  if (!broker) {
    return <Loader />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading
        level="2"
        font={theme.fonts.mont}
        weight="500"
        size="2rem"
        padding="0 0 2rem"
      >
        {locale.profile}
      </Heading>

      <FormInput
        type="text"
        name="name"
        label={locale.name}
        defaultValue={broker.name}
        value={formik.values.name}
        width="100%"
        formik={formik}
      ></FormInput>
      <FormInput
        name="vat"
        label={locale.vat}
        width="100%"
        formik={formik}
      ></FormInput>
      <FormInput
        type="text"
        name="phone"
        label={locale.phone}
        width="100%"
        formik={formik}
      ></FormInput>
      <FormInput
        type="text"
        name="correspondance_address_line_1"
        label={locale.address}
        width="100%"
        formik={formik}
      ></FormInput>
      <FormInput
        type="text"
        name="correspondance_address_line_2"
        width="100%"
        formik={formik}
      ></FormInput>
      <FormInput
        type="text"
        name="correspondance_address_line_3"
        width="100%"
        formik={formik}
      ></FormInput>
      <FormInput
        type="text"
        name="correspondance_address_line_4"
        width="100%"
        formik={formik}
      ></FormInput>

      <Flex justify="flex-end">
        <Button type="submit" filled>
          {locale.submit}
        </Button>
      </Flex>
    </form>
  );
};
